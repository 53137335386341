import { confirmI18n, trans, transChoice } from '../common';
import { updateStatsValue } from './content-analytics';
import { groupsLozenge } from './invitations';
import { Quitch } from '../quitch';
import { getMessage, getSpinnerHtml, getUpdateMessage, loadingMessage } from '../../system';
import { userAchievedContBadges } from './badges';

$(document).ready(function()
{
    initialiseUserList();
});

$(document).on('click', '#students-tab', function()
{
    getUserList('none');
});

$(document).on('click', '.user-details-btn', function()
{
    let user_id = $(this).attr('data-user-id');
    let activeRow = $(this);
    if (user_id.length === 0)
    {
        return false;
    }
    let classId = $('.js_class_details').data('class-id');
    getStudentDetails(classId, user_id, activeRow);
});

/**
 * Setup edit profile modal
 */
$(document).on('click', '.edit-profile-btn', function()
{
    let user_id = $(this).val();
    if (user_id.length === 0)
    {
        return false;
    }

    $('.student-list.suspended')
        .find('.student-name')
        .removeClass('student-name');
    $(this)
        .parent('td')
        .prev('td')
        .addClass('student-name');
    $('.std-loader').html('');
    $('.validateErrors').hide();
    $('.validateErrorLists').empty();
    const class_id = $('.js_class-manager').data('class-id');

    $.ajax(
    {
        url: '/get-user-details/' + user_id + '/' + class_id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                let profile = response.profiles;
                $('.user_profile_id').val(profile.id);
                $('.pfirst_name').val('');
                $('.pfirst_name').val(profile.first_name);
                $('.plast_name').val('');
                $('.plast_name').val(profile.last_name);
                $('.pexternal_reference').val(profile.external_reference);
                if (profile.status == 'active')
                {
                    $('.active_status').empty();
                    $('.active_status').append(
                        '<option selected="selected" value="' + profile.status + '">' + profile.status.toUpperCase() + '</option><option value="suspended"> SUSPENDED </option>'
                    );
                    $('.active_status').show();
                    $('.chosen-container-single-nosearch').hide();
                }
                else if (profile.status == 'suspended')
                {
                    $('.active_status').empty();
                    $('.active_status').append(
                        '<option value="active"> ACTIVE </option><option selected="selected" value="' + profile.status + '">' + profile.status.toUpperCase() + '</option>'
                    );
                    $('.active_status').show();
                }
                else
                {
                    $('.sstatus').empty();
                }

                $('.pemail').val(profile.email);

                $('#edit-profile-modal').modal('show');
            }
            else
            {
                Quitch.showMessage('danger', 'Unable to Load User Profile');
            }
        },
    });
});

/**
 * Submit edit profile form
 */
$(document).on('click', '.update-profile-btn', function()
{
    let updateProfileData = $('#profileUpdateForm').serialize();
    $('.std-loader').html(getSpinnerHtml());

    $.ajax(
    {
        url: '/update-user-profile',
        type: 'POST',
        dataType: 'json',
        data: updateProfileData,
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                let profile = response.profile;
                let name = profile.first_name + ' ' + profile.last_name;
                $('.sname').html(name);
                $('.semail').html(profile.email);
                $('.sexternal_reference').html('-');
                if (profile.external_reference && profile.external_reference.trim() != '' && $('.sexternal_reference').length)
                {
                    $('.sexternal_reference').html(profile.external_reference);
                }
                $('.semail').html(profile.email);
                $('.student-list.active')
                    .find('.active')
                    .find('td:nth-child(2)')
                    .html(name);

                location.reload();
            }
            else
            {
                if (response.status === '422' || response.status === 422)
                {
                    $('.validateErrorLists').empty();
                    $.each(response.errors, function(key, value)
                    {
                        $('.validateErrorLists').append('<li>' + value + '</li>');
                    });

                    if (response.message)
                    {
                        $('.validateErrorLists').append('<li>' + response.message + '</li>');
                    }
                    $('.validateErrors').show();
                }
                else
                {
                    $('.profileError').html(getUpdateMessage('Profile Not updated', 'danger'));
                }
                $('.std-loader').html('');
            }
        },
        error: function()
        {
            $('#edit-profile-modal').modal('hide');
            Quitch.showMessage('danger', 'Profile Not updated');
        },
    });
});

$(document).on('click', '.edit-user-profile-close', function()
{
    $('.displayError').hide();
    $('.validateError').empty();
    $('.newEmail').val('');
    $('#editUserEmail').modal('hide');
});

$(document).on('click', '.update-user-email-btn', function()
{
    $('.validateError').empty();
    $('.displayError').hide();
    let currentEmail = $('.currentEmail').val();
    let newEmail = $('.newEmail').val();
    let token = $('meta[name="csrf-token"]').attr('content');
    let userId = $(this).val();

    $('.std-loader').html('');
    $.ajax(
    {
        url: `/manage/users/${userId}/update-email`,
        type: 'POST',
        dataType: 'json',
        data: { currentEmail: currentEmail, newEmail: newEmail, _token: token },
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                $('#editUserEmail').modal('hide');
                $('.user-email').val(currentEmail);
                Quitch.showMessage('success', response.message);
                window.location.reload();
            }
        },
        error: function(response)
        {
            if (response.status === '422' || response.status === 422)
            {
                $('.displayError').show();
                $('.validateError').append(response.responseJSON.message);
            }
            else if (response.responseJSON && response.responseJSON.errors)
            {
                $.each(response.responseJSON.errors, function(key, value)
                {
                    $('.displayError').show();
                    $('.validateError').append(value[0]);
                });
            }
            else
            {
                $('#editUserEmail').modal('hide');
                Quitch.showErrors('An error occurred, please try later.');
            }
        },
    });
});

$(document).on('click', '.js-update-user-status', function(e)
{
    e.preventDefault();
    let profileId = $('.edit-profile-btn').prop('value');
    let classId = $('.js_class_details').data('class-id');
    let activeRow = $('.user-details-btn[data-user-id="' + profileId + '"]');
    let email = $('.student-detailss .semail').html();
    let href = $(this).attr('href');
    Quitch.modal(
        {
            title: $(this).data('title'),
            message: `<p class="wrap">${$(this).data('message')}</p>
                      <p>${email}</p>
                      <p>${$(this).data('message-post') || ''} ${trans('userDetails.suspendContinue')}</p>`,
            type: 'confirm',
        },
        function(response)
        {
            if (response === true)
            {
                $.ajax(
                {
                    url: href + '/' + profileId + '/' + classId,
                    type: 'GET',
                    dataType: 'json',
                    success: function(response)
                    {
                        getStudentDetails(classId, profileId, activeRow);
                        $(activeRow)
                            .find('.col-lg-2.text-right')
                            .html('-%'); // update the %
                        $(activeRow)
                            .find('.col-lg-2.text-right[data-point]')
                            .html('-')
                            .prop('data-point', '0'); // update the %
                        location.reload();
                    },
                });
            }
        }
    );
});

/** switch between active / suspended **/
$('#active-tab, #suspended-tab').click(function()
{
    if (!$('.student-loader').hasClass('error'))
    {
        $('.student-details-title').html('<div class="alert alert-info">' + trans('students.loaderSelectStudent') + '&nbsp;</div>');
        $('.js_user-details tbody tr').removeClass('active');
        $('.student-detailss').hide();
        $('.student-details-default-view').show();
    }
});

/**
 * Clear user results
 */
$(document).on('click', '.js-reset-user-result-btn', function(e)
{
    e.preventDefault();
    let profileId = $('.edit-profile-btn').prop('value');
    let classId = $('.js_class_details').data('class-id');
    if (confirmI18n('userDetails.confirmationAlert'))
    {
        $.ajax(
        {
            url: '/class-manager/reset-score/' + profileId + '/' + classId,
            type: 'GET',
            dataType: 'json',
            success: function(response)
            {
                monitorResetJob(response.jobId, classId, profileId);
            },
        });
    }
});

function initialiseUserList()
{
    let windowHash = window.location.hash;
    if (windowHash === '#learners')
    {
        getUserList('none');
    }
}

function filterStdActivityLineChart(res)
{
    let chart = document.getElementById('chart-std-stats').getContext('2d');
    const chartData = res;
    if (chart)
    {
        if (filterStdActivityLineChart.jsChart)
        {
            filterStdActivityLineChart.jsChart.destroy();
        }
        filterStdActivityLineChart.jsChart = new Chart(chart,
        {
            type: 'line',
            data: chartData,
        });
        if (res.participation)
        {
            $('.noData').remove();
        }
        else
        {
            $('.learner-chart-container').css({ position: 'relative' });
            $('.learner-chart-container').append(
                `<div class="noData" style="font-size: 25px;font-family:inherit;position: absolute;top: 48%;width: 100%;text-align:center;" >${trans('overview.noData')}</div>`
            );
        }
        $('.learner-chart-container').show();
    }
}

function userQuestionList(questions)
{
    if (questions.length > 0)
    {
        let data = questions.map(function(item)
        {
            let title = item.shortTitle && item.shortTitle.length > 0 ? item.shortTitle : item.question_title;
            let status = item.result;
            if (item.approval_status === 1)
            {
                status = 'Evaluated';
            }
            else
            {
                status =
                    status === 'pending' ?
                    `<span class="badge badge-info">${trans('userDetails.pending')}</span>` :
                    status === 'passed' ?
                    `<span class="badge badge-success">${trans('userDetails.correct')}</span>` :
                    `<span class="badge badge-danger">${trans('userDetails.incorrect')}</span>`;
            }
            return `
                        <tr>
                            <td>${title.length > 50 ? title.substr(0, 47) + '...' : title}</td>
                            <td>${item.sectionName.length > 20 ? item.sectionName.substr(0, 17) + '...' : item.sectionName}</td>
                            <td>${item.answer_at}</td>
                            <td>${status}</td>
                        </tr>
                      `;
        });
        $('.user-question-list tbody').html(data);
    }
    else
    {
        $('.user-question-list tbody').html(`<tr><td colspan="4" align="center">${trans('userDetails.noQuestions')}</td></tr>`);
    }
}

function getLeaderboardDatatableLanguageSettings()
{
    return {
        info: trans('students.tableEntries', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
        search: '',
        searchPlaceholder: trans('base.search'),
        lengthMenu: trans('reporting.lengthMenuLearners', { menu: '_MENU_' }),
        processing: trans('reporting.processing'),
        zeroRecords: trans('reporting.empty'),
        infoEmpty: trans('reporting.infoLearners', { start: 0, end: 0, total: 0 }),
        infoFiltered: '(filtered)',
        paginate:
        {
            first: '<i class="fa fa-angle-double-left"></i>',
            last: '<i class="fa fa-angle-double-right"></i>',
            next: '<i class="fa fa-angle-right"></i>',
            previous: '<i class="fa fa-angle-left"></i>',
        },
    };
}

function getUserDisplayName(user)
{
    let displayName = `${user.first_name} ${user.last_name}`;
    if (user.first_name === null || user.last_name === null)
    {
        displayName = `${user.email}`;
    }

    // truncate display name if too long
    if (displayName.length > 24)
    {
        displayName = displayName.substring(0, 24) + '...';
    }
    return displayName;
}

function getUserList(filterType = 'none')
{
    let initialLoad = false;
    if (!getUserList.hasInitialLoaded)
    {
        initialLoad = true;
        getUserList.hasInitialLoaded = true;
    }

    let class_id = $('.js_class-manager').data('class-id');
    if (!class_id)
    {
        throw 'class id not found';
    }
    $('.student-loader').html(loadingMessage('overview.loaderStudent'));
    $('.student-loader').removeClass('error');
    $.ajax(
    {
        url: '/get-user-list/' + class_id + '/' + filterType,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            $('.student-loader').html('');
            if (response && response.studentList)
            {
                // add position number
                const activeStudents = response.studentList.filter(user => user.status === 'active');
                let position = 1;
                $.each(activeStudents, function(key, user)
                {
                    user.pos = position++;
                });

                if (initialLoad)
                {
                    // init active students table
                    $('.js_leaderboard').DataTable(
                    {
                        retrieve: true,
                        data: activeStudents,
                        deferRender: true,
                        dom: '<"js_filter-active filter-button">frt<"col-lg-8"i><"col-lg-4"p>',
                        targets: 'no-sort',
                        order: [],
                        pagingType: 'simple',
                        bAutoWidth: false,
                        language: getLeaderboardDatatableLanguageSettings(),
                        columnDefs: [
                            { targets: [0], width: '0%', className: 'text-center', visible: true },
                            { targets: [1], visible: true },
                            { targets: [2], width: '102px', className: 'text-center', visible: !response.showExtras },
                            { targets: [3], width: '102px', className: 'text-center', visible: !!response.showExtras }, // Visible by Superuser
                            { targets: [4], width: '70px', className: 'text-center', visible: !!response.showExtras }, // Visible by Superuser
                            { targets: [5], width: '70px', className: 'text-center text-nowrap', visible: true },
                            { targets: [6], width: '90px', className: 'text-center text-nowrap', visible: response.classType === 'attainment' },
                            { targets: [7], width: '90px', className: 'text-center text-nowrap', visible: response.classType === 'growth' },
                            { targets: [8], width: '90px', className: 'text-center text-nowrap', visible: response.classType === 'competition' },
                            {
                                targets: '_all',
                                orderable: false,
                                visible: false,
                            },
                        ],
                        columns: [
                            { mData: 'pos', sTitle: trans('students.tablePos') },
                            {
                                sTitle: trans('students.tableName'),
                                render: (data, type, row) => getUserDisplayName(row),
                            },
                            {
                                mData: 'accuracy',
                                sTitle: trans('students.tableAccuracy') +
                                    `<span class="tooltip-icon" title="${trans('students.accuracyTooltip')}" data-placement="bottom" data-toggle="tooltip">
                                        <i class="fas fa-info-circle"></i>
                                     </span>`,
                            },
                            {
                                sTitle: trans('students.tableAccuracy') +
                                    `<span class="tooltip-icon" title="${trans('students.accuracyTooltip')}" data-placement="bottom" data-toggle="tooltip">
                                        <i class="fas fa-info-circle"></i>
                                     </span>`,
                                render: (data, type, row) =>
                                {
                                    return row.accuracy + ' (' + row.new_score + ')';
                                },
                            },
                            {
                                sTitle: trans('students.tableRecent'),
                                render: (data, type, row) =>
                                {
                                    return row.recent_accuracy + ' (' + row.growth_points + ')';
                                },
                            },
                            {
                                sTitle: trans('students.tableCorrect') +
                                    `<span class="tooltip-icon" title="${trans('students.correctTooltip')}" data-placement="bottom" data-toggle="tooltip">
                                        <i class="fas fa-info-circle"></i>
                                     </span>`,
                                render: (data, type, row) =>
                                {
                                    return row.questions_passed + ' / ' + row.questions_attempted;
                                },
                            },
                            {
                                sTitle: trans('students.tablePoints') +
                                    `<span class="tooltip-icon" title="${trans('students.pointsTooltip')}" data-placement="bottom" data-toggle="tooltip">
                                        <i class="fas fa-info-circle"></i>
                                     </span>`,
                                render: (data, type, row) =>
                                {
                                    return row.attainment_points + ' / ' + row.total_available_points;
                                },
                                mData: 'total_points',
                            },
                            {
                                sTitle: trans('students.tablePoints') +
                                    `<span class="tooltip-icon" title="${trans('students.pointsTooltip')}" data-placement="bottom" data-toggle="tooltip">
                                        <i class="fas fa-info-circle"></i>
                                     </span>`,
                                render: (data, type, row) =>
                                {
                                    return row.growth_points + ' / ' + row.questions_attempted * 10;
                                },
                                mData: 'newScore',
                            },
                            {
                                sTitle: trans('students.tablePoints') +
                                    `<span class="tooltip-icon" title="${trans('students.pointsTooltip')}" data-placement="bottom" data-toggle="tooltip">
                                        <i class="fas fa-info-circle"></i>
                                     </span>`,
                                render: (data, type, row) =>
                                {
                                    return row.competition_points + ' / ' + row.questions_attempted * 1000;
                                },
                                mData: 'competitionPoints',
                            },
                            { mData: 'orgProfileId' },
                            { mData: 'pivot_id' },
                            { mData: 'email' },
                            { mData: 'status' },
                            { mData: 'questions_answered', sTitle: 'Questions Attempted' },
                            { mData: 'total_resource_views', sTitle: 'Views of Resources' },
                            { mData: 'total_available_points' },
                            { mData: 'total_resources_viewed', sTitle: 'Resources Viewed' },
                            { mData: 'questions_attempted', sTitle: 'Answers Submitted' },
                            { mData: 'questions_failed' },
                            { mData: 'questions_passed' },
                        ],
                        fnInitComplete: (oSettings, json) =>
                        {
                            const dataTableWrapper = oSettings.nTableWrapper;
                            $(dataTableWrapper)
                                .find('div.js_filter-active')
                                .html(
                                    `
                                    <div class="dropdown float-left">
                                      <button class="btn btn-sm btn-light dropdown-toggle" type="button" id="filter-students-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="fa fa-filter"></span>
                                      </button>
                                      <ul class="dropdown-menu" aria-labelledby="filter-students-btn">
                                        <li><a class="dropdown-item js_filter-student" data-filter="at-risk" href="#">${trans('students.tableAtRisk', {threshold: response.atRiskThreshold})}</a></li>
                                        <li><a class="dropdown-item js_filter-student" data-filter="not-participated" href="#">${trans('students.tableNotParticipated')}</a></li>
                                        <li><a class="dropdown-item js_filter-student" data-filter="none" href="#">${trans('students.tableClear')}</a></li>` +
                                    (response.showExtras ?
                                        `<li disabled><a disabled>------------------------</a></li>
                                        <li><a class="dropdown-item js_filter-student" data-filter="points" href="#">${trans('students.tableByPoints')}</a></li>
                                        <li><a class="dropdown-item js_filter-student" data-filter="accuracy" href="#">${trans('students.tableByAccuracy')}</a></li>
                                        <li><a class="dropdown-item js_filter-student" data-filter="recent" href="#">${trans('students.tableByRecent')}</a></li>
                                        <li><a class="dropdown-item js_filter-student" data-filter="corrects" href="#">${trans('students.tableByCorrect')}</a></li>
                                        <li><a class="dropdown-item js_filter-student" data-filter="participation" href="#">${trans('students.tableByParticipation')}</a></li>` :
                                        '') +
                                    `</ul>
                                    </div>`
                                );

                            const dataTableId = oSettings.sTableId;
                            $(dataTableWrapper).on('click', '.js_filter-student', function(e)
                            {
                                e.preventDefault();
                                const type = $(e.target).data('filter');
                                getUserList(type);
                            });
                        },
                    });

                    // handle loading profile of user
                    $('.js_user-details tbody tr').addClass('js_load-user-details');

                    $(document).on('click', '.js_user-details tbody tr', function()
                    {
                        const row = $(this);
                        const table = row
                            .parent()
                            .parent()
                            .DataTable();
                        const rowData = table.row(this).data();
                        if (rowData.orgProfileId.length === 0)
                        {
                            return false;
                        }
                        const classId = $('.js_class_details').data('class-id');
                        getStudentDetails(classId, rowData.orgProfileId, row);
                    });

                    // iterate over suspended students
                    const suspendedStudents = response.studentList.filter(user => user.status === 'suspended');
                    $('.js_suspended').DataTable(
                    {
                        retrieve: true,
                        data: suspendedStudents,
                        deferRender: true,
                        dom: 'frt<"col-lg-8"i><"col-lg-4"p>',
                        targets: 'no-sort',
                        order: [],
                        pagingType: 'simple',
                        bAutoWidth: false,
                        language: getLeaderboardDatatableLanguageSettings(),
                        columnDefs: [
                            { targets: [0], visible: true },
                            { targets: [1], width: '90px', className: 'text-center text-nowrap', visible: response.classType === 'attainment' },
                            { targets: [2], width: '90px', className: 'text-center text-nowrap', visible: response.classType === 'growth' },
                            { targets: [3], width: '90px', className: 'text-center text-nowrap', visible: response.classType === 'competition' },
                            {
                                targets: '_all',
                                orderable: false,
                                visible: false,
                            },
                        ],
                        columns: [
                            {
                                sTitle: trans('students.tableName'),
                                render: (data, type, row) => getUserDisplayName(row),
                            },
                            {
                                sTitle: trans('students.tablePoints'),
                                mData: 'attainment_points',
                            },
                            {
                                sTitle: trans('students.tablePoints'),
                                mData: 'growth_points',
                            },
                            {
                                sTitle: trans('students.tablePoints'),
                                mData: 'competition_points',
                            },
                            { mData: 'email' },
                        ],
                    });
                }
                else
                {
                    let lbDataTable = $('.js_leaderboard').DataTable();
                    lbDataTable.clear();
                    lbDataTable.rows.add(activeStudents);
                    lbDataTable.draw();
                }
            }
        },
        error: function()
        {
            $('.student-loader').addClass('error').html(getMessage('overview.leaderboardUnavailable', 'info'));
            $('.student-details-title').empty();
            $('.student-details-default-view').hide();
            $('.leaderboard-user-search').hide();
            $('.sidebar-search').hide();
            $('.table.custom-table thead').hide();
            $('.table.custom-table tbody').hide();
        },
    });
}

function getStudentDetails(classId, user_id, activeRowEl)
{
    let activeRow = $(activeRowEl);

    // remove active class and current shown student
    $('.student-detailss').hide();
    $('.student-details-title').html(`<div class="alert alert-info">${trans('overview.loaderStudent')}<i class="fa fa-pulse fa-spinner"></i></div>`);
    $('.student-details-default-view').show();
    activeRow.siblings('tr').removeClass('active');
    activeRow.addClass('active');

    $.ajax(
    {
        url: '/get-user-details/' + user_id + '/' + classId,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            //Call Std Line Chart Function
            filterStdActivityLineChart(response.activity);
            userQuestionList(response.questionLists);
            userAchievedContBadges(response.badges, response.allActiveBadges);
            if (response.status === '200' || response.status === 200)
            {
                updateStatsValue('.user-completion', false);
                updateStatsValue('.accuracy-completed', false);
                //Call Std Line Chart Function
                filterStdActivityLineChart(response.activity);
                $('.user-badges').html(transChoice('userDetails.badges', response.user_badges));
                $('.resource_viewed').html(transChoice('userDetails.resources', response.resource_viewed));
                $('.questions_attempted').html(transChoice('userDetails.questions', response.stats.questions_attempted));
                $('.user-details-btn').removeClass('active');

                let profile = response.profiles;
                let displayName = profile.first_name ? `${profile.first_name} ${profile.last_name}` : profile.email;

                $('.edit-profile-btn-actve').val(profile.id);
                $('.student-detailss .spoints').html(transChoice('userDetails.points', Math.round(response.stats.total_points * 10)));
                $('.student-detailss .sname').html(displayName);
                $('.student-detailss .sscreen_name').html(profile.screenName);
                $('.student-detailss .sexternal_reference').html('-');
                if (profile.external_reference && profile.external_reference.trim().length > 0)
                {
                    $('.student-detailss .sexternal_reference').html(profile.external_reference);
                }

                $('.student-detailss .semail').html(profile.email);
                if (profile.status === 'active')
                {
                    $('.student-detailss .js-activate-user-btn').hide();
                    $('.student-detailss .js-suspend-user-btn').show();
                }
                else
                {
                    $('.student-detailss .js-activate-user-btn').show();
                    $('.student-detailss .js-suspend-user-btn').hide();
                }

                $('.student-detailss .groups').html(groupsLozenge(profile.groupNames) || '-');

                activeRow.siblings('tr').removeClass('active');
                activeRow.addClass('active');
                $('.student-details-default-view').hide();
                $('.student-details-title').html('<div class="alert alert-info">' + trans('students.loaderSelectStudent') + '&nbsp;</div>');
                $('.student-detailss').show();
                updateStatsValue('.user-completion', response.user_completion);
                updateStatsValue('.accuracy-completed', response.user_accuracy);
            }
        },
    });
}

function monitorResetJob(jobId, classId, profileId, timeout = 500)
{
    $.ajax(
    {
        url: '/jobs/get-status/' + jobId,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            let selectedProfileId = $('.edit-profile-btn').prop('value');
            if (selectedProfileId !== profileId)
            {
                //no longer have that student selected, don't update anything.
                return;
            }

            if (response.job.status === 'created' || response.job.status === 'running')
            {
                window.setTimeout(function()
                {
                    monitorResetJob(jobId, classId, profileId, timeout * 1.5);
                }, timeout);
            }
            else
            {
                if (response.job.status !== 'completed')
                {
                    //error, what to do here?
                }

                let activeRow = $('.user-details-btn[data-user-id="' + profileId + '"]');
                getStudentDetails(classId, profileId, activeRow);
                $(activeRow)
                    .find('.col-lg-2.text-right')
                    .html('-%'); // update the %
                $(activeRow)
                    .find('.col-lg-2.text-right[data-point]')
                    .html('-')
                    .prop('data-point', '0'); // update the %

                getUserList('none');
            }
        },
        error: function(error)
        {
            let selectedProfileId = $('.edit-profile-btn').prop('value');
            if (selectedProfileId !== profileId)
            {
                //no longer have that student selected, don't update anything.
                return;
            }

            if (timeout >= 1000)
            {
                window.setTimeout(function()
                {
                    monitorResetJob(jobId, classId, profileId, timeout * 2);
                }, timeout);
            }
        },
    });
}