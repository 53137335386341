import { expandTopic, selectContent, validateAllContent, validateTitle } from './common';
import { createPlaceholders } from './question';
import { Quitch } from '../../quitch';
import { trans, transChoice } from '../../common';
import { updateTopicChevronStyling } from './index';
import { updatePublishBtn } from './schedule-publish';
import { addResource } from './resource';
import { loadPreview } from './preview';

$(document).on('click', '.js-create-new-topic', function(e)
{
    if ($(this).hasClass('disabled'))
    {
        return;
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classInstanceId: $('input[name=classInstanceId]').val()
    };

    $.ajax(
    {
        url: '/content/authoring/topic/modal',
        type: 'GET',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            const topicModal = $(response.view);
            topicModal.modal(
                {
                    cache: false,
                },
                'show'
            );

            topicModal.find('.js-schedule-publish').show();
        },
    });
});

$(document).on('input change', '.create-new-topic-body [name=topicName], .create-new-topic-body [name=sectionId], .create-new-topic-body [name=sectionName]', function(e)
{
    const form = $(this).parents('.create-new-topic-body');

    form.find('.js-create-topic-btn').prop('disabled', form.find('[name=topicName]').val().trim().length === 0 ||
        (form.find('[name=sectionName]').val().trim().length === 0));
});

$(document).on('click', '.js-create-topic-btn', function(e)
{
    const createTopicBtn = $(this);
    createTopicBtn.prop('disabled', true);

    const form = $(this).parents('.create-new-topic-body');
    const topicName = form.find('[name=topicName]').val().trim();
    const sectionId = form.find('[name=sectionId]').val();
    const sectionName = form.find('[name=sectionName]').val().trim();

    const classInstanceId = $('input[name=classInstanceId]').val();
    const token = $('meta[name="csrf-token"]').attr('content');

    createTopic(topicName, sectionId, sectionName, classInstanceId, token, createTopicBtn);
});

export function createTopic(topicName, sectionId, sectionName, classInstanceId, token, createTopicBtn = null, questionOrResourceType = 'multi-choice')
{
    let createResourceAfter = false;
    if (questionOrResourceType === 'image' || questionOrResourceType === 'video' || questionOrResourceType === 'document')
    {
        createResourceAfter = questionOrResourceType;
        questionOrResourceType = null;
    }

    let values = {
        _token: token,
        classInstanceId: classInstanceId,
        name: topicName,
        parentId: sectionId !== 'custom' ? sectionId : null,
        parentName: sectionName,
        questionOrResourceType: questionOrResourceType
    };

    $.ajax(
    {
        url: '/content/authoring/section/add',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            if ($('.content-authoring-wrapper').length > 0)
            {
                if (createTopicBtn)
                {
                    createTopicBtn.parents('.modal').modal('hide');
                }

                const newTopic = $(response.view);
                if (newTopic.hasClass('content-authoring-section'))
                {
                    $('.content-authoring-list').append(newTopic);
                }
                else
                {
                    const destSection = $(`.content-authoring-section[data-id="${sectionId}"]`);
                    destSection.find('.content-authoring-section-body').append(newTopic);
                }

                const newQuestion = newTopic.find('.content-authoring-content.question');
                if (newQuestion.length)
                {
                    newQuestion.addClass('newly-added');
                    newQuestion.find('.option-list').addClass('newly-added');
                    createPlaceholders(newQuestion);
                    selectContent(newQuestion);
                    newQuestion.find('.content-title .input-field').focus();
                    $('.empty-state-footer').hide();
                }
                else if (createResourceAfter)
                {
                    $('.empty-state-footer').hide();
                    addResource($('[name=classId]').val(), response.newId, createResourceAfter, null, false);
                }
                else
                {
                    const newResource = newTopic.find('.content-authoring-content.resource');
                    if (newResource.length)
                    {
                        newResource.addClass('newly-added');
                        newResource.find('.resource-description').addClass('newly-added');
                        selectContent(newResource);
                        newResource.find('.content-title .input-field').focus();
                    }
                    $('.empty-state-footer').hide();
                }
            }
            else
            {
                $('#add-content-first-time-modal').modal('hide');
                window.location.reload();
            }

            updateTopicChevronStyling();
            updatePublishBtn();
        },
    });
}

$(document).on('click', '.js-expand-topic', function(e)
{
    const topicEle = $(this).parents('.content-authoring-topic');
    if (topicEle.hasClass('expanded'))
    {
        if ($('.content-authoring-topic').length < 2)
        {
            return;
        }

        if (!$(this).hasClass('only-expand'))
        {
            const lastOpenTopicId = $('.content-authoring-list').data('last-open-topic-id');
            const sectionEle = $(this).parents('.content-authoring-section');
            const lastOpenTopic = sectionEle.find(`[data-id=${lastOpenTopicId}]`);
            if (lastOpenTopicId && lastOpenTopic.length)
            {
                if (lastOpenTopic.find('.content-authoring-content').length)
                {
                    selectContent(lastOpenTopic.find('.content-authoring-content').first());
                }
                else
                {
                    $('.content-authoring-content').addClass('collapsed').removeClass('selected edit');
                    expandTopic(lastOpenTopic);
                }
            }
            else
            {
                const firstTopic = sectionEle.find('.content-authoring-topic').first();
                if (!firstTopic.hasClass('expanded'))
                {
                    if (firstTopic.find('.content-authoring-content').length)
                    {
                        selectContent(firstTopic.find('.content-authoring-content').first());
                    }
                    else
                    {
                        $('.content-authoring-content').addClass('collapsed').removeClass('selected edit');
                        expandTopic(firstTopic);
                    }
                }
                else // Can't open the first one, open a sibling if there is one
                {
                    const topicSiblings = topicEle.siblings('.content-authoring-topic');
                    if (topicSiblings.length > 0)
                    {
                        const firstSibling = topicSiblings.first();
                        if (firstSibling.find('.content-authoring-content').length)
                        {
                            selectContent(firstSibling.find('.content-authoring-content').first());
                        }
                        else
                        {
                            $('.content-authoring-content').addClass('collapsed').removeClass('selected edit');
                            expandTopic(firstSibling);
                        }
                    }
                }
            }
        }
    }
    else
    {
        if (topicEle.find('.content-authoring-content').length)
        {
            selectContent(topicEle.find('.content-authoring-content').first());
        }
        else // Topic has no content
        {
            if (topicEle.hasClass('collapsed'))
            {
                $('.content-authoring-content').addClass('collapsed').removeClass('selected edit');
                expandTopic(topicEle, () => loadPreview());
            }
        }
    }
});

$(document).on('click', '.js-delete-section-topic', function(e)
{
    let sectionTopicEle = null;
    let isSection = false;
    if ($(this).parents('.content-authoring-topic').length > 0)
    {
        sectionTopicEle = $(this).parents('.content-authoring-topic').first();
    }
    else
    {
        sectionTopicEle = $(this).parents('.content-authoring-section').first();
        isSection = true;
    }

    Quitch.modal(
        {
            title: trans(isSection ? 'content.deleteSectionTitle' : 'content.deleteTopicTitle'),
            message: trans(isSection ? 'content.deleteSectionDesc' : 'content.deleteTopicDesc'),
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered modal-dialog-sm',
            okText: 'content.deleteBtn',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                let values = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    id: sectionTopicEle.attr('data-id'),
                    classId: $('input[name=classId]').val(),
                    isSection: isSection ? 1 : 0
                };

                $.ajax(
                {
                    url: '/content/authoring/section/delete',
                    type: 'POST',
                    dataType: 'json',
                    data: values,
                    success: function(response)
                    {
                        Quitch.showSnackbar(response.message);
                        sectionTopicEle.remove();
                        selectContent($('.content-authoring-list .content-authoring-topic .content-authoring-content').first());

                        updateTopicChevronStyling();
                        updatePublishBtn();

                        if ($('.content-authoring-section').length === 0)
                        {
                            $('.empty-state-footer').show();
                        }
                    },
                    error: function(response)
                    {
                        Quitch.showSnackbar(response.responseJSON.message);
                    }
                });
            }
        });
});

$(document).on('focusout', '.js-edit-section-topic-name', function(e)
{
    const sectionTopicEle = $(this).parents('.content-authoring-section, .content-authoring-topic').first();
    let title = $(this).text().trim();
    if ($(this).attr('data-current-val') === title)
    {
        if ($(this).parent().hasClass('had-error'))
        {
            validateSectionTopic(sectionTopicEle);
        }
        return;
    }

    if (!title)
    {
        // Replace empty title with placeholder text
        title = $(this).parents('.content-authoring-topic-heading').length > 0 ? trans('content.topicPlaceholder') : trans('content.sectionPlaceholder');
        $(this).text(title);
        if ($(this).attr('data-current-val') === title)
        {
            return;
        }
        $(this).attr('data-current-val', title);
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        id: sectionTopicEle.attr('data-id'),
        classId: $('input[name=classId]').val(),
        name: title
    };

    validateSectionTopic(sectionTopicEle);

    $.ajax(
    {
        url: '/content/authoring/section/edit',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            sectionTopicEle.find('.toolbar .content-title .input-field').attr('data-current-val', title);
        },
        error: function(response)
        {
            Quitch.showSnackbar(response.responseJSON.message);
        }
    });

    validateAllContent();
});

export function validateSectionTopic(sectionTopicEle)
{
    validateTitle(sectionTopicEle.find('> .toolbar .content-title'), 'content.titleRequired', 150);
}

export function updateTopicTally(topicEle)
{
    const questionCount = topicEle.find('.content-authoring-content.question').length;
    const resourceCount = topicEle.find('.content-authoring-content.resource').length;
    topicEle.find('.topic-tally').text(transChoice('content.questionCount', questionCount, { count: questionCount }) + ' • ' + transChoice('content.resourceCount', resourceCount, { count: resourceCount }));
}