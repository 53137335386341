import { trans } from '../common';
import { getMessage } from '../../system';
import { Quitch } from '../quitch';
import { telemetryTrackEvent } from '../superuser/mixpanel';

$(document).on('click', '.cat-badge', function()
{
    $('.badges-category-list')
        .find('.section-content')
        .removeClass('section-content');
    $(this).addClass('section-content');
    let category = $('.badges-category-list')
        .find('.section-content')
        .attr('cat-id');
    let class_id = $('.js_class-manager').data('class-id');

    if (class_id != '' && category != '')
    {
        getBadges(class_id, category);
    }
});

$(document).on('click', '.js_badge-toggle', function()
{
    const badgeToggled = $(this);
    const values = {
        id: $(this)
            .parent()
            .attr('badge-id'),
        _token: $('meta[name="csrf-token"]').attr('content'),
        class_id: $('.js_class-manager').data('class-id'),
    };

    $.ajax(
    {
        url: '/badge-assign-class',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                const isActive = response.content && response.content.badge === 'created';
                const $badgeImage = badgeToggled.parents('.single-badge-v2').first().find('.image-border');
                if (isActive)
                {
                    $badgeImage.removeClass('grey-out');

                    telemetryTrackEvent('ep-badge-enabled', { 'badgesCount': response.content.activeBadgesCount });
                    Quitch.showSnackbar(trans('badge.enabled'));
                }
                else
                {
                    $badgeImage.addClass('grey-out');

                    telemetryTrackEvent('ep-badge-disabled', { 'badgesCount': response.content.activeBadgesCount });
                    Quitch.showSnackbar(trans('badge.disabled'));
                }
            }
            else
            {
                Quitch.showSnackbar(trans('Badge Not Assigned'));
            }
        },
    });
});

export function toggleOffAllBadges()
{
    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classId: $('.js_class-manager').data('class-id'),
    };

    $.ajax(
    {
        url: '/class/badges/delete',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('.js_badge-toggle').each(function()
            {
                $(this).prop('checked', false).change();
                $(this).parents('.single-badge-v2').find('.image-border').addClass('grey-out');
            });
        }
    });
}

function getBadges(class_id, category)
{
    $.ajax(
    {
        url: '/get-category-wise-badge/' + class_id + '/' + category,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                $('.category-wise-badges-list').empty();
                let values = response.badges;
                if (values.length > 0)
                {
                    $.each(response.badges, function(index, badge)
                    {
                        let checked = '';
                        let imageActive = 'image';
                        if (badge.class_badge_id != null)
                        {
                            imageActive = '';
                            checked = 'checked="checked"';
                        }

                        // Repair relative img path (img/badge.png -> /img/badge.png)
                        let badgeImg = badge.image;
                        if (badge.image && badge.image.indexOf('img') === 0)
                        {
                            badgeImg = '/' + badge.image;
                        }

                        let badgeHtml = `
                                <div class="single-badge">
                                    <div class="badge-image ${imageActive}">
                                        <img src="${badgeImg}">
                                    </div>
                                    <h5 class="badge-title wrap">${trans('badge.seq' + badge.sequence)}</h5>
                                    <div class="toggle-button toggle-slide" badge-id="${badge.id}">
                                        <input type="checkbox" class="js_badge-toggle" id="badge-img-${index}" name="status" ${checked}>
                                        <label for="badge-img-${index}" data-on-text="On" data-off-text="Off"></label>
                                    </div>
                                </div>`;

                        $('.category-wise-badges-list').append(badgeHtml);
                    });
                }
                else
                {
                    $('.category-wise-badges-list').html('<h4 class="text-center" style="color: red">Not found</h4>');
                }
            }
            else
            {
                $('.category-wise-badges-list').html(getMessage('Badge Not Found', 'info'));
            }
        },
    });
}

export function userAchievedContBadges(badges, allActiveBadges) // TODO: Can this be moved to badges.js ?
{
    allActiveBadges.map(function(item)
    {
        // Repair relative img path (img/badge.png -> /img/badge.png)
        if (item.image && item.image.indexOf('img') === 0)
        {
            item.image = '/' + item.image;
        }
    });

    // Sort badges into categories with levels
    let badgeCategories = {};

    for (let i = 0; i < allActiveBadges.length; ++i)
    {
        badgeCategories[allActiveBadges[i].category] = {
            'name': allActiveBadges[i].name,
            'levels': [allActiveBadges[i]],
            'currLevel': 0,
            'maxLevel': allActiveBadges[i].maxLevel,
            'image': allActiveBadges[i].image
        };
    }

    for (let i = 0; i < badges.length; ++i)
    {
        if (badges[i].category in badgeCategories)
        {
            badgeCategories[badges[i].category]['levels'].push(badges[i]);
            if (badges[i].level > badgeCategories[badges[i].category]['currLevel'])
            {
                badgeCategories[badges[i].category]['currLevel'] = badges[i].level;
            }
        }
    }

    let badgeHtml = '';

    badgeHtml += `<table>`;
    badgeHtml += `<tbody>`;
    badgeHtml += `<tr>`;

    for (const [key, value] of Object.entries(badgeCategories))
    {
        badgeHtml += `
            <td>
                <div class="badge mouse-over-switch-static-lower ${value['currLevel'] > 0 ? '' : 'grey-out'}">
                    <div data-toggle="tooltip" title="${trans('badge.' + value['name'])}">
                    <img src="${value['image']}_static.png" alt="badge" class="achievement-image">
            `;

        if (value['maxLevel'] > 1)
        {
            badgeHtml += `
                    <div class="text-container">
                        <p class="level-text">${trans('badge.LEVEL')} ${value['currLevel']}</p>
                    </div>
                `;
        }

        badgeHtml += `
                </div>
            </td>
            `;
    }

    badgeHtml += `</tr>`;
    badgeHtml += `</tbody>`;
    badgeHtml += `</table>`;

    $('.user-achieved-cont-badge').html(badgeHtml);
}