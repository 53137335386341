import { trans } from '../common';
import { Quitch } from '../quitch';
import { selectDropdownItem } from '../form/dropdown';
import { toggleOffAllBadges } from './badges';
import { telemetryTrackEvent } from '../superuser/mixpanel';

$(document).ready(function()
{
    if (!$('#class-settings-outer').length)
    {
        return;
    }

    const previewImage = $('.preview-image').first();
    const previewImageBackground = $('.image-preview-background').first();
    const classImage = $('.class-img .rounded').first();

    previewImageBackground.css('background-color', classImage.css('background-color'));

    if (classImage.attr('src'))
    {
        previewImage.attr('src', classImage.attr('src'));

        $('#js-add-class-img-btn').text(trans('dashboard.changeImage'));
    }
    else
    {
        $('#remove-class-img-btn-holder').hide();
    }

    if ($('#classPresetSelector').first().val() !== 'competition')
    {
        $('#comp-end-date-section').first().hide();
    }

    if (!$('#class-in-marketplace-toggle').first().prop('checked'))
    {
        $('.hide-if-not-show-on-marketplace').hide();
    }
});

$(document).on('change', '#class-settings-outer #class-image-path', e =>
{
    if ($(e.target).val() == '')
    {
        $('#remove-class-img-btn-holder').hide();
    }
    else
    {
        $('#remove-class-img-btn-holder').show();
    }

    updateSettings({ 'classImage': $('#class-image-path').first().val() });
});

$(document).on('change', '.class-name-input', e =>
{
    $('.class-name-input').removeClass('has-error');
    $('.js-settings-class-name-error').hide();

    updateSettings({ 'className': $('.class-name-input').first().val() });
});

$(document).on('change', '#class-archive-date', function(e)
{
    const val = $('#class-archive-date').first().val();
    if (val == '')
    {
        updateSettings({ 'classArchiveDate': val });
        return;
    }

    const dt = new Date(val);
    dt.setDate(dt.getDate() + 1);
    const dateStr = dt.toISOString().split('T')[0];
    updateSettings({ 'classArchiveDate': dateStr });
});

$(document).on('change', '.settings-check-box, #classPresetSelector, #comp-end-date', e =>
{
    $('#save-class-config-btn').removeAttr('disabled');
});

$(document).on('change', '#classPresetSelector', e =>
{
    $('#comp-end-date-section').hide();

    //these are all the most common settings (which happens to be the same as attainment settings)
    $('#shuffleQuestionsCheckbox').prop('checked', false);
    $('#forceTimerCheckbox').prop('checked', true);
    $('#incorrectOnExitCheckbox').prop('checked', true);
    $('#allowSkipCheckbox').prop('checked', false);
    $('#allowPauseCheckbox').prop('checked', false);
    $('#allowSingleQnCheckbox').prop('checked', true);
    $('#allowShowAnsCheckbox').prop('checked', false);
    $('#showFeedbackCheckbox').prop('checked', true);

    switch ($(e.target).val())
    {
        case 'competition':
        {
            $('#comp-end-date-section').show();

            $('#shuffleQuestionsCheckbox').prop('checked', true);
            $('#allowSingleQnCheckbox').prop('checked', false);
            $('#classPointsType').val('competition');
            break;
        }
        case 'practice':
        {
            $('#forceTimerCheckbox').prop('checked', false);
            $('#incorrectOnExitCheckbox').prop('checked', false);
            $('#allowSkipCheckbox').prop('checked', true);
            $('#allowPauseCheckbox').prop('checked', true);
            $('#allowShowAnsCheckbox').prop('checked', true);
            $('#classPointsType').val('growth');
            break;
        }
        case 'assessment':
        {
            //the defaults are all the attainment ones
            $('#classPointsType').val('attainment');
            break;
        }
        case 'nasba':
        {
            $('#allowSingleQnCheckbox').prop('checked', false);
            $('#showFeedbackCheckbox').prop('checked', false);
            $('#classPointsType').val('growth');
            break;
        }
    }
});

$(document).on('change', '.class-config input[type="checkbox"]', function()
{
    $('.js-class-preset-dropdown [data-target=custom]').show();
    selectDropdownItem($('.js-class-preset-dropdown'), 'custom');
});

$(document).on('change', '#class-in-marketplace-toggle', e =>
{
    if ($(e.target).prop('checked')) // What it just became
    {
        $('.hide-if-not-show-on-marketplace').show();
        $('#save-marketplace-btn').removeAttr('disabled');
    }
    else
    {
        if ($('#price-tier-selector').prop('disabled')) // Not a super user
        {
            $(e.target).prop('checked', true); // Set it back to checked

            Quitch.modal(
                {
                    title: trans('settings.removeFromMarketplace'),
                    message: `<p class="wrap">${trans('settings.removeFromMarketplaceDescA')}</p>
                          <br>
                          <p class="wrap">${trans('settings.removeFromMarketplaceDescB')}</p>`,
                    type: 'confirm_custom',
                    cssClass: 'modal-dialog-sm modal-dialog-centered delete-modal',
                    okText: 'settings.yesRemove',
                    cancelText: 'base.cancel',
                    showClose: false,
                },
                function(modalResponse)
                {
                    if (modalResponse === true)
                    {
                        removeFromMarketplaceConfirm();
                    }
                }
            );

        }
        else
        {
            $('.hide-if-not-show-on-marketplace').hide();
            $('#save-marketplace-btn').removeAttr('disabled');
        }
    }
});

$(document).on('change', '#time-zone-selector', e =>
{
    saveTimezoneSettings();
});

$(document).on('change', '#price-tier-selector', e =>
{
    $('#save-marketplace-btn').removeAttr('disabled');
});

$(document).on('change keyup paste', '.class-desc-text-area', e =>
{
    if ($('.class-desc-text-area').data('initialised') == 'true') // Keyup event is called upon page load
    {
        $('.class-desc-text-area').removeClass('has-error');
        $('.js-settings-class-desc-error').hide();

        $('#save-marketplace-btn').removeAttr('disabled');
    }
    else
    {
        $('.class-desc-text-area').data('initialised', 'true');
    }
});

//TODO: Make this percentage input system generic in the future
$(document).on('focusin', '#at-risk-threshold-input-fake', function()
{
    const realInput = $('#at-risk-threshold-input').first();
    const fakeInput = $('#at-risk-threshold-input-fake').first();

    fakeInput.hide();
    realInput.show();
    realInput.focus();

    realInput.attr('type', 'text'); // 'number' type does not allow selection
    $('#at-risk-threshold-input')[0].selectionStart = realInput.val().length;
    $('#at-risk-threshold-input')[0].selectionEnd = realInput.val().length;
    realInput.attr('type', 'number');
});

$(document).on('focusout', '#at-risk-threshold-input', function(e)
{
    const realInput = $('#at-risk-threshold-input').first();
    const fakeInput = $('#at-risk-threshold-input-fake').first();

    realInput.hide();
    fakeInput.show();

    if (realInput.val() == '' || realInput.val() < 0)
    {
        realInput.val(50);
    }
    else if (realInput.val() >= 100)
    {
        realInput.val(99);
    }

    fakeInput.val(realInput.val() + '%');

    saveAtRiskLearnersSettings();
});

function removeFromMarketplaceConfirm()
{
    $('#class-in-marketplace-toggle').prop('checked', false);

    $('#save-marketplace-btn').attr('disabled', true);
    saveMarketplaceSettings();

    $('#marketplace-settings-section').remove();

    Quitch.showSnackbar(trans('settings.classRemovedFromMarketplace'));
}

$(document).on('click', '#js-remove-class-img-btn', function()
{
    // Restore the org logo's colour

    const previewImageBackground = $('.image-preview-background').first();

    if ($('#org-logo-hidden').length)
    {
        $('#org-logo-hidden').hide();
        $('#org-logo-colour').show();

        previewImageBackground.css('background-color', $('#org-logo-colour').first().css('background-color'));
    }
    else
    {
        $('#org-logo').hide();
        $('#org-logo-colour-hidden').show();

        previewImageBackground.css('background-color', $('#org-logo-colour-hidden').first().css('background-color'));
    }

    $('.preview-image').attr('src', '');
    $('#class-image-path').first().val('');
    $('#remove-class-img-btn-holder').hide();

    updateSettings({ 'classImage': '' });
});

$(document).on('click', '#save-class-config-btn', function()
{
    $(this).attr('disabled', true);
    saveClassConfigSettings();
});

function saveClassConfigSettings()
{
    const settings = {
        'preset': $('#classPresetSelector').val(),
        'classType': $('#classPointsType').val(),
        'compEndDateTime': $('#comp-end-date').first().val(),
        'shuffleQuestions': $('#shuffleQuestionsCheckbox').first().prop('checked'),
        'forceTimer': $('#forceTimerCheckbox').first().prop('checked'),
        'incorrectOnExit': $('#incorrectOnExitCheckbox').first().prop('checked'),
        'allowSkip': $('#allowSkipCheckbox').first().prop('checked'),
        'allowPause': $('#allowPauseCheckbox').first().prop('checked'),
        'allowSingleQuestion': $('#allowSingleQnCheckbox').first().prop('checked'),
        'allowShowAnswer': $('#allowShowAnsCheckbox').first().prop('checked'),
        'showFeedback': $('#showFeedbackCheckbox').prop('checked'),
    };

    if (settings.preset !== 'custom')
    {
        $('.js-class-preset-dropdown [data-target=custom]').hide();
    }

    if (settings.preset === 'nasba')
    {
        Quitch.modal(
            {
                title: trans('settings.changeTypeModalTitle'),
                message: `<p class="wrap">${trans('settings.changeTypeModalBody')}</p>`,
                type: 'confirm_custom',
                cssClass: 'modal-dialog-sm modal-dialog-centered',
                okText: 'settings.yesUpdate',
                cancelText: 'base.cancel',
                showClose: false,
            },
            function(modalResponse)
            {
                if (modalResponse === true)
                {
                    settings.timerDuration = 0;
                    settings.attemptLimit = 1;

                    selectDropdownItem($('#settings-timer-duration-selector').parents('.js_dropdown-select'), 0);
                    selectDropdownItem($('#settings-attempt-limit-selector').parents('.js_dropdown-select'), 1);

                    // toggle off gamification settings
                    $('#settings-leaderboard-toggle').prop('checked', false).change();
                    toggleOffAllBadges();

                    updateSettings(settings);
                }
            }
        );
    }
    else
    {
        updateSettings(settings);
    }
}

$(document).on('click', '#save-at-risk-learners-btn', function()
{
    $(this).attr('disabled', true);
    saveAtRiskLearnersSettings();
});

function saveAtRiskLearnersSettings()
{
    const settings = {
        'atRiskThreshold': $('#at-risk-threshold-input').first().val()
    };
    updateSettings(settings);
}

$(document).on('click', '#save-timezone-btn', function()
{
    $(this).attr('disabled', true);
    saveTimezoneSettings();
});

function saveTimezoneSettings()
{
    const settings = {
        'timeZone': $('#time-zone-selector').first().val()
    };
    updateSettings(settings);
}

$(document).on('click', '#save-marketplace-btn', function()
{
    $(this).attr('disabled', true);
    saveMarketplaceSettings();
});

function saveMarketplaceSettings()
{
    const showOnMarketplace = $('#class-in-marketplace-toggle').first().prop('checked');
    const privateTier = 5;

    const settings = {
        'showOnMarketplace': showOnMarketplace,
        'priceTier': showOnMarketplace ? $('#price-tier-selector').first().val() : privateTier,
        'classDescription': $('.class-desc-text-area').first().val()
    };
    updateSettings(settings);
}

$(document).on('change', '#settings-timer-duration-selector', function()
{
    const settings = {
        'timerDuration': $(this).first().val(),
    };
    updateSettings(settings);
});

$(document).on('change', '#settings-attempt-limit-selector', function()
{
    const settings = {
        'attemptLimit': $(this).first().val(),
    };
    updateSettings(settings);
});

$(document).on('change', '#settings-class-code-input', function()
{
    const settings = {
        'classCode': $(this).first().val(),
    };
    updateSettings(settings);
});

$(document).on('change', '#settings-leaderboard-toggle', function()
{
    const leaderboardToggle = $(this).first().prop('checked');
    if (leaderboardToggle)
    {
        $('.hide-if-leaderboard-disabled').show();
    }
    else
    {
        $('.hide-if-leaderboard-disabled').hide();
    }

    const settings = {
        'leaderboard': $(this).first().prop('checked'),
    };
    updateSettings(settings);
});

$(document).on('change', '.settings-radio-btn', function()
{
    $('.settings-radio-btn').prop('checked', false);
    $(this).prop('checked', true);

    const settings = {
        'showAllOnLeaderboard': $('#leaderboardFullClassRadioBtn').first().prop('checked'),
    };
    updateSettings(settings);
});

function updateSettings(settings, reloadAfter = false)
{
    Quitch.showSnackbar(trans('settings.updating'));

    const token = $('meta[name="csrf-token"]').attr('content');
    const classId = $('.js_class-manager').data('class-id');

    $.ajax(
    {
        url: '/class/update/settings',
        type: 'POST',
        dataType: 'json',
        data: { '_token': token, 'classId': classId, 'settings': settings },
        success: function(response)
        {
            Quitch.showSnackbar(trans('settings.updated'));

            if (settings.className)
            {
                $('.js-class-name').text($('.class-name-input').first().val());
            }

            const prevImgSrc = $('.preview-image').attr('src');
            if (prevImgSrc && $('#class-image-path').first().val() != '')
            {
                if ($('#org-logo-hidden').length)
                {
                    // We don't have an existing logo image - show/use the reserve version of the logo image
                    $('#org-logo-colour').hide();
                    $('#org-logo-hidden').show();
                    $('#org-logo-hidden').attr('src', prevImgSrc);
                }
                else
                {
                    // We already have a logo image
                    $('#org-logo-colour-hidden').hide();
                    $('#org-logo').show();
                    $('#org-logo').first().attr('src', prevImgSrc);
                }
            }

            if (reloadAfter)
            {
                window.location.reload();
            }

            if (response.timezones)
            {
                $('#announcement-tz').text('(' + response.timezones + ')');
            }
        },
        error: function(xhr, status, error)
        {
            if (xhr.responseJSON && xhr.responseJSON.errors)
            {
                for (const [key, value] of Object.entries(xhr.responseJSON.errors))
                {
                    if (key === 'className')
                    {
                        $('.js-settings-class-name-error').show().html(value);
                        $('.class-name-input').addClass('has-error');
                    }
                    else if (key === 'classDescription')
                    {
                        $('.js-settings-class-desc-error').show().html(value);
                        $('.class-desc-text-area').addClass('has-error');
                    }
                    else
                    {
                        Quitch.showErrors(xhr.responseJSON.errors);
                    }
                }
            }
        }
    });
}

$(document).on('click', '.delete-class-btn', function(e)
{
    e.stopPropagation();

    let target = $(e.target);
    let classId = $('.js_class-manager').data('class-id');
    if (!classId)
    {
        classId = target.data('target-id') ?
            target.data('target-id') :
            $(this)
            .closest('.accordion-parent')
            .data('class-id');
    }

    let className = $('.class-name-input').first().val();
    if (!className)
    {
        className = target.parents('.class-card').find('.class-card-desc').text().trim();
    }

    Quitch.modal(
        {
            title: trans('settings.deleteClassHeader'),
            message: `<p class="wrap">${trans('settings.deleteClassBodyA', {className: className})}</p>
                      <p class="wrap mt-2">${trans('settings.deleteClassBodyB')}</p>`,
            type: 'confirm_custom',
            cssClass: 'modal-dialog-sm modal-dialog-centered delete-modal',
            okText: 'settings.yesDelete',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                $.ajax(
                {
                    url: '/class/delete/' + classId,
                    type: 'POST',
                    dataType: 'json',
                    data: { _token: $('meta[name="csrf-token"]').attr('content') },
                    success: function(response)
                    {
                        if (window.location.pathname.trim() === '/home' || window.location.pathname.trim() === '/home/')
                        {
                            window.location.reload();
                        }
                        else
                        {
                            window.location.href = '/home';
                        }
                    },
                    error: function()
                    {
                        Quitch.showMessage('danger', trans('settings.deleteClassFailed'));
                    }
                });
            }
        }
    );
});

$(document).on('submit', '#class-export-form', function(e)
{

    e.preventDefault();

    const form = $(this);
    const actionUrl = form.attr('action');
    toggleExportBtn(false);

    $.ajax(
    {
        type: 'POST',
        url: actionUrl,
        data: form.serialize(),
        success: function(response)
        {
            refreshExportJob(response.jobId);
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(trans('settings.classExportFailed'));
            toggleExportBtn();
        }
    });
});

function refreshExportJob(jobId, retryDelay = 500)
{
    $.ajax(
    {
        url: `/jobs/get-status/${jobId}`,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.job.status === 'created' || response.job.status === 'running')
            {
                window.setTimeout(function()
                {
                    refreshExportJob(jobId, Math.ceil(retryDelay * 1.5));
                }, retryDelay);
            }
            else if (response.job.status === 'completed')
            {
                telemetryTrackEvent('classSettings-contentExport', { 'status': 'success', exportType: $('.js-class-content-export-input').val() });
                toggleExportBtn();
                const classId = $('.js_class-manager').data('class-id');
                window.location = `/class/${classId}/${jobId}/export/download`;
            }
            else if (response.job.status === 'failed')
            {
                Quitch.showSnackbar(trans('settings.classExportFailed'));
                toggleExportBtn();
                telemetryTrackEvent('classSettings-contentExport', { 'status': 'failed', exportType: $('.js-class-content-export-input').val() });
            }
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(trans('settings.classExportFailed'));
            toggleExportBtn();
            telemetryTrackEvent('classSettings-contentExport', { 'status': 'failed', exportType: $('.js-class-content-export-input').val(), 'debug': xhr.responseJSON.message, 'error': error });
        }
    });
}

function toggleExportBtn(enable = true)
{
    if (enable)
    {
        $('.js-class-content-export-dropdown-btn').removeClass('disabled');
        $('#export-download-btn').prop('disabled', false).html(trans('settings.download'));
    }
    else
    {
        $('.js-class-content-export-dropdown-btn').addClass('disabled');
        $('#export-download-btn').prop('disabled', true).html('<span class="mr-2"><i class="fa-solid fa-circle-notch fa-pulse"></i></span>' + trans('reporting.processing'));
    }
}