import { trans } from '../common';

/**
 * Generate reporting tables
 */
$(document).ready(function()
{
    let token = $('meta[name="csrf-token"]').attr('content');

    const options = $('select.js-filter-table.org option');
    options.detach().sort(function(a, b)
    {
        const at = $(a).text();
        const bt = $(b).text();
        return at > bt ? 1 : at < bt ? -1 : 0;
    });
    options.appendTo($('select.js-filter-table.org'));

    let defaultJsTableOptions = {
        paging: true,
        searching: false,
        processing: true,
        serverSide: true,
        language:
        {
            lengthMenu: trans('reporting.lengthMenuEop', { menu: '_MENU_' }),
            info: trans('reporting.infoResults2', { start: '_START_', end: '_END_', total: '_TOTAL_' }),
            processing: trans('reporting.processing'),
            zeroRecords: trans('reporting.noResults'),
            infoEmpty: trans('reporting.infoResults2', { start: 0, end: 0, total: 0 }),
            infoFiltered: '',
            paginate:
            {
                first: false,
                last: false,
                next: '<i class="fa fa-angle-right"></i>',
                previous: '<i class="fa fa-angle-left"></i>',
            },
        },
        beforeSend: function(request)
        {
            request.setRequestHeader('_token', token);
        },
        aaSorting: [],
        pagingType: 'simple',
        pageLength: 10,
        dom: 't<"row col-lg-12 pl-0 pr-0 reporting-table-footer bg-quitch-white sticky-footer pt-1 pb-1"l<"mr-4 absolute-right right-65"i><"absolute-right"p>>',
    };

    //Student Reporting
    if ($('.js-data_table-learner').length)
    {
        const showClassName = $('#class-selector-dropdown-btn').length === 0;
        let target = $('.js-data_table-learner').data('request');
        let jsTable = $('.js-data_table-learner').DataTable(Object.assign(defaultJsTableOptions,
        {
            fnDrawCallback: function(settings)
            {
                if ($('input.js-class-filter').val() === '')
                {
                    $('td.dataTables_empty').html(trans('reporting.noClass'));
                }
                else
                {
                    $('.js-filter-table, .js-generate-report-btn, .btn-dropdown').removeClass('disabled').removeAttr('disabled');
                }
            },
            ajax:
            {
                url: target,
                type: 'POST',
                data: function(d)
                {
                    d._token = token;
                    d.email = $('[name="email"]').val();
                    d.name = $('[name="name"]').val();
                    d.class = $('[name="class"]').val();
                    d.enrolment_status = $('[name="enrolment_status"]').val();
                    d.invite_date_from = $('[name="invite_date_from"]').val();
                    d.invite_date_to = $('[name="invite_date_to"]').val();
                    d.enrol_date_from = $('[name="enrol_date_from"]').val();
                    d.enrol_date_to = $('[name="enrol_date_to"]').val();
                    d.performance = $('[name=performance]').val();
                    d.orgId = $('[name="orgId"]').val();
                    return d;
                },
            },
            beforeSend: function(request)
            {
                request.setRequestHeader('_token', token);
            },
            aoColumns: [
                { mData: 'name' },
                { mData: 'email' },
                { mData: 'external_reference' },
                { mData: 'enrolment_status' },
                { mData: 'last_login' },
                { mData: 'completion' },
                { mData: 'sectionsCompleted' },
                { mData: 'topicsCompleted' },
                { mData: 'totalQuestionsAnswered' },
                { mData: 'totalQuestionsAttempted' },
                { mData: 'accuracyPercent' },
                { mData: 'totalQuestionsCorrect' },
                { mData: 'totalQuestionsIncorrect' },
                { mData: 'totalQuestionsTimedOut' },
                { mData: 'totalQuestionsSkipped' },
                { mData: 'totalInstructionsViewed' },
                { mData: 'totalInstructionViews' },
                { mData: 'totalTime' },
                { mData: 'className', visible: showClassName },
                { mData: 'class_invite_date' },
                { mData: 'class_enrolled_date' },
            ],
        }));

        $('.js-filter-table').keyup(function()
        {
            jsTable.settings()[0].jqXHR.abort();
            jsTable.search($(this).val()).draw();
        });

        $('.js-filter-table').change(function()
        {
            $(this).trigger('keyup');
        });
    }
    //Class Reporting
    if ($('.js-data_table-classes').length)
    {
        let classTarget = $('.js-data_table-classes').data('request');
        let classJsTable = $('.js-data_table-classes').DataTable(Object.assign(defaultJsTableOptions,
        {
            fnDrawCallback: function(settings)
            {
                if ($('input.js-class-filter').val() === '')
                {
                    $('td.dataTables_empty').html(trans('reporting.noClass'));
                }
                else
                {
                    $('.js-filter-table, .js-generate-report-btn').removeAttr('disabled');
                }
            },
            ajax:
            {
                url: classTarget,
                type: 'POST',
                data: function(d)
                {
                    d._token = token;
                    d.class = $('[name="class"]').val();
                    d.answered_date_from = $('[name="answered_date_from"]').val();
                    d.answered_date_to = $('[name="answered_date_to"]').val();
                    d.class_name = $('[name="class_name"]').val();
                    d.section_name = $('[name="section_name"]').val();
                    d.question = $('[name="question"]').val();
                    d.org = $('[name="org"]').val();
                    return d;
                },
            },
            beforeSend: function(request)
            {
                request.setRequestHeader('_token', token);
            },
            aoColumns: [
                { mData: 'section_name' },
                { mData: 'topic_name' },
                { mData: 'question_type' },
                { mData: 'question' },
                { mData: 'student_count' },
                { mData: 'total_attempts' },
                { mData: 'total_pass' },
                { mData: 'total_failed' },
                { mData: 'totalTimeouts' },
                { mData: 'fail_rate' },
                { mData: 'totalSkipped' },
                { mData: 'total_duration' },
                { mData: 'avg_duration' },
                { mData: 'release_date' },
                { mData: 'first_answered' },
                { mData: 'last_answered' },
            ],
        }));

        $('.js-filter-table').keyup(function()
        {
            classJsTable.settings()[0].jqXHR.abort();
            classJsTable.search($(this).val()).draw();
        });

        $('.js-class-filter').change(function()
        {
            $(this).trigger('keyup');
        });
    }

    //Result Reporting
    if ($('.js-data_table-results').length)
    {
        let resultsTarget = $('.js-data_table-results').data('request');
        let resultsJsTable = $('.js-data_table-results').DataTable(Object.assign(defaultJsTableOptions,
        {
            fnDrawCallback: function(settings)
            {
                if ($('input.js-class-filter').val() === '')
                {
                    $('td.dataTables_empty').html(trans('reporting.noClass'));
                }
                else
                {
                    $('.js-filter-table, .js-generate-report-btn').removeAttr('disabled');
                }
            },
            ajax:
            {
                url: resultsTarget,
                type: 'POST',
                data: function(d)
                {
                    d._token = token;
                    d.class = $('[name="class"]').val();
                    d.user_email = $('[name="user_email"]').val();
                    d.section = $('[name="section"]').val();
                    d.topic = $('[name="topic"]').val();
                    d.question = $('[name="question"]').val();
                    d.org = $('[name="org"]').val();
                    return d;
                },
            },
            aoColumns: [
                { mData: 'sectionName' },
                { mData: 'topicName' },
                { mData: 'question_type' },
                { mData: 'question' },
                { mData: 'answer' },
                { mData: 'email' },
                { mData: 'name' },
                { mData: 'profile_reference' },
                { mData: 'result' },
                {
                    mData: 'points',
                    render: (data, type, row) =>
                    {
                        if (row.classType === 'attainment')
                        {
                            return row.attainmentPoints;
                        }
                        else if (row.classType === 'growth')
                        {
                            return row.growthPoints;
                        }
                        else if (row.classType === 'competition')
                        {
                            return row.competitionPoints;
                        }
                        return '-';
                    }
                },
                { mData: 'attemptNumber' },
                { mData: 'duration' },
                { mData: 'date_answered' },
            ],
        }));

        $('.js-filter-table').keyup(function()
        {
            resultsJsTable.settings()[0].jqXHR.abort();
            resultsJsTable.search($(this).val()).draw();
        });

        $('select.js-filter-table, .js-class-filter').change(function()
        {
            $(this).trigger('keyup');
        });
    }

    //Topics reporting
    if ($('.js-data_table-topic').length)
    {
        let resultsTarget = $('.js-data_table-topic').data('request');
        let resultsJsTable = $('.js-data_table-topic').DataTable(Object.assign(defaultJsTableOptions,
        {
            fnDrawCallback: function(settings)
            {
                if ($('.js-topic-dropdown-select input').val() === '')
                {
                    $('td.dataTables_empty').html(trans('reporting.selectTopic'));
                }
            },
            ajax:
            {
                url: resultsTarget,
                type: 'POST',
                data: function(d)
                {
                    d._token = token;
                    d.class = $('[name="class"]').val();
                    d.search = $('[name="search"]').val();
                    d.topicId = $('[name="topicId"]').val();
                    return d;
                },
            },
            beforeSend: function(request)
            {
                request.setRequestHeader('_token', token);
            },
            aoColumns: [
                { mData: 'name' },
                { mData: 'email' },
                { mData: 'externalReference' },
                { mData: 'completion' },
                { mData: 'questionsAnswered' },
                { mData: 'questionsPassed' },
                { mData: 'totalAttempts' },
                { mData: 'correctAttempts' },
                { mData: 'incorrectAttempts' },
                { mData: 'timeouts' },
                { mData: 'skips' },
                { mData: 'resourcesViewed' },
                { mData: 'totalViews' },
                { mData: 'totalTimePlayed' },
            ],
        }));

        $('.js-filter-table').keyup(function()
        {
            resultsJsTable.settings()[0].jqXHR.abort();
            resultsJsTable.search($(this).val()).draw();
        });

        $('.js-filter-table').change(function()
        {
            $(this).trigger('keyup');
        });
    }

    //Resources Reporting
    if ($('.js-data_table-resources').length)
    {
        let resourcesTarget = $('.js-data_table-resources').data('request');
        let resourcesJsTable = $('.js-data_table-resources').DataTable(Object.assign(defaultJsTableOptions,
        {
            fnDrawCallback: function(settings)
            {
                if ($('input.js-class-filter').val() === '')
                {
                    $('td.dataTables_empty').html(trans('reporting.noClass'));
                }
                else
                {
                    $('.js-filter-table, .js-generate-report-btn').removeAttr('disabled');
                }
            },
            ajax:
            {
                url: resourcesTarget,
                type: 'POST',
                data: function(d)
                {
                    d._token = token;
                    d.class = $('[name="class"]').val();
                    d.user_reference = $('[name="user_reference"]').val();
                    d.user_email = $('[name="user_email"]').val();
                    d.class_reference = $('[name="class_reference"]').val();
                    d.class_name = $('[name="class_name"]').val();
                    d.org = $('[name="org"]').val();
                    return d;
                },
            },
            beforeSend: function(request)
            {
                request.setRequestHeader('_token', token);
            },
            aoColumns: [
                { mData: 'sectionName' },
                { mData: 'topicName' },
                { mData: 'resource_title' },
                { mData: 'resource_type' },
                { mData: 'name' },
                { mData: 'email' },
                { mData: 'external_reference' },
                { mData: 'viewed' },
            ],
        }));

        $('.js-filter-table').keyup(function()
        {
            resourcesJsTable.settings()[0].jqXHR.abort();
            resourcesJsTable.search($(this).val()).draw();
        });

        $('.js-filter-table').change(function()
        {
            $(this).trigger('keyup');
        });
    }

    //evidence of participation Reporting
    if ($('.js-data_table-evidence-of-participation').length)
    {
        let resultsTarget = $('.js-data_table-evidence-of-participation').data('request');
        let resultsJsTable = $('.js-data_table-evidence-of-participation').DataTable(Object.assign(defaultJsTableOptions,
        {
            fnDrawCallback: function(settings)
            {
                if ($('input.js-eop-class-input').val() === '')
                {
                    $('td.dataTables_empty').html(trans('reporting.noClass'));
                }
                else
                {
                    $('.js-filter-table, .js-generate-report-btn').removeAttr('disabled');
                }
            },
            ajax:
            {
                url: resultsTarget,
                type: 'POST',
                data: function(d)
                {
                    d._token = token;
                    d.class = $('[name="class"]').val();
                    d.user_email = $('[name="user_email"]').val();
                    d.start_date = $('[name="start_date"]').val();
                    d.end_date = $('[name="end_date"]').val();
                    d.start_date2 = $('[name="start_date2"]').val();
                    d.end_date2 = $('[name="end_date2"]').val();
                    return d;
                },
            },
            beforeSend: function(request)
            {
                request.setRequestHeader('_token', token);
            },
            aoColumns: [
                { mData: 'className', bSortable: false },
                { mData: 'classCode', bSortable: false },
                { mData: 'email' },
                { mData: 'name' },
                { mData: 'profile_reference' },
                { mData: 'question' },
                { mData: 'answer' },
                { mData: 'result' },
                { mData: 'date_answered' },
            ],
        }));

        $('.js-filter-table').keyup(function()
        {
            resultsJsTable.settings()[0].jqXHR.abort();
            resultsJsTable.search($(this).val()).draw();
        });

        $('[type=hidden].js-filter-table').change(function()
        {
            $(this).trigger('keyup');
        });
    }

    $('input[type="date"].js-filter-table').change(function()
    {
        $(this).trigger('keyup');
    });
});

/**
 * Start report export job
 */
$(document).on('click', '.js-generate-report-btn', function(e)
{
    let form = $(e.target).parents('form');
    let formData = form.serializeArray();
    formData.push({ name: 'export', value: 1 });
    let classId = form.find('[name=class]').val();
    let orgId = null;
    if ($(this).hasClass('org'))
    {
        orgId = $('.js-filter-table.org').val();
    }
    $.ajax(
    {
        url: form.attr('action'),
        type: form.attr('method'),
        dataType: 'json',
        data: formData,
        success: function(response)
        {
            let now = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
            let status = trans(`reporting.${response.job.status}Status`);
            let section = `
                    <div class="row js-running-job-row " data-job-id="${response.job.id}">
                        <div class="col-1 js-running-job-icon-col-${response.job.id}" title="${status}"><i class="fa ${response.job.jobIcon}"></i></div>
                        <div class="col-3 js-running-job-status-col-${response.job.id}">${status}</div>
                        <div class="col-3">${now}</div>
                        <div class="col-2 js-running-job-buttons-col-${response.job.id}"></div>
                    </div>`;
            let reportsBar = $('.js-reports-bar');
            reportsBar.append(section);
            reportsBar.show();

            refreshReportJobDetails(orgId, classId, response.job.id, 500);
        },
        error: function(error)
        {
            let now = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
            let status = trans('reporting.errorStatus');
            let section = `
                    <div class="row">
                        <div class="col-3"></div>
                        <div class="col-1" title="${status}"><i class="fa fa-exclamation-circle"></i></div>
                        <div class="col-3">${now}</div>
                        <div class="col-3">${status}</div>
                        <div class="col-2"></div>
                    </div>`;
            let reportsBar = $('.js-reports-bar');
            reportsBar.append(section);
            reportsBar.show();
        },
    });

    return false;
});

function refreshReportJobDetails(orgId, classId, jobId, retryDelay)
{
    $.ajax(
    {
        url: orgId ? `/system/${orgId}/job/${jobId}/status` : `/jobs/get-status/${jobId}`,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            $(`.js-running-job-status-col-${jobId}`).html(trans(`reporting.${response.job.status}Status`));
            let iconCol = $(`.js-running-job-icon-col-${jobId}`);
            let statusCol = $(`.js-running-job-status-col-${jobId}`);
            iconCol.attr('title', trans(`reporting.${response.job.status}Status`));
            iconCol.html(`<i class="fa ${response.job.jobIcon}"></i>`);
            statusCol.html(trans(`reporting.${response.job.status}Status`));
            if (response.job.status === 'created' || response.job.status === 'running')
            {
                window.setTimeout(function()
                {
                    refreshReportJobDetails(orgId, classId, jobId, Math.ceil(retryDelay * 1.5));
                }, retryDelay);
            }
            else if (response.job.status === 'completed')
            {
                let buttonsCol = $(`.js-running-job-buttons-col-${jobId}`);
                let text = trans('reporting.downloadReport');
                let downloadUrl = `/admin/class/${classId}/job-log-file/${jobId}`;
                if (orgId)
                {
                    downloadUrl = `/system/${orgId}/job/${jobId}/download`;
                }
                buttonsCol.html(`
                        <a href="${downloadUrl}" class="btn btn-sm btn-light" data-telemetry-name="${response.job.subType} Report Download">${text}</a>`);
            }
        },
        error: function(error)
        {
            let iconCol = $(`.js-running-job-icon-col-${jobId}`);
            let statusCol = $(`.js-running-job-status-col-${jobId}`);
            iconCol.attr('title', trans(`reporting.errorStatus`));
            iconCol.html(`<i class="fa fa-exclamation-circle"></i>`);
            statusCol.html(trans('reporting.errorStatus'));
            window.setTimeout(function()
            {
                refreshReportJobDetails(orgId, classId, jobId, Math.ceil(retryDelay));
            }, retryDelay);
        },
    });
}

function updateEopTimeDisplay(start)
{
    let classInput = $('[name=class]');
    let startDate = null;
    let endDate = null;
    let inputNumber = '';
    if (start)
    {
        if (classInput.data('classStart'))
        {
            startDate = new Date(classInput.data('classStart'));
            endDate = new Date(startDate.getTime() + 3600000 * 24 * 30); //30 days after
        }
    }
    else if (classInput.data('classEnd'))
    {
        endDate = new Date(classInput.data('classEnd'));
        let tomorrow = new Date(new Date().getTime() + 3600000 * 24); //only the date is used, so no need to set time to 00:00:00
        if (endDate > tomorrow)
        {
            endDate = tomorrow;
        }
        startDate = new Date(endDate.getTime() - 3600000 * 24 * 30);

        inputNumber = '2';
    }

    if (startDate && endDate)
    {
        let dateFilterDisplay = $('#js-eop-date-filter' + inputNumber);
        dateFilterDisplay.html(startDate.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' }) + ' - ' + endDate.toLocaleDateString(undefined, { day: 'numeric', month: 'short', year: 'numeric' }));
        dateFilterDisplay.attr('hidden', false);

        $('#js-eop-start-date' + inputNumber).val(startDate.toLocaleDateString('en-US', { year: 'numeric' }) + '/' + startDate.toLocaleDateString('en-US', { month: 'numeric' }) + '/' + startDate.toLocaleDateString('en-US', { day: 'numeric' }));
        $('#js-eop-end-date' + inputNumber).val(endDate.toLocaleDateString('en-US', { year: 'numeric' }) + '/' + endDate.toLocaleDateString('en-US', { month: 'numeric' }) + '/' + endDate.toLocaleDateString('en-US', { day: 'numeric' }));
        $('#js-eop-start-date' + inputNumber).trigger('change');
    }
}

$(document).on('change', '.js-eop-class-input', function(e)
{
    let classDropdown = $('[data-value=' + e.target.value + ']');
    let classInput = $('[name=class]');
    if (classInput)
    {
        $('.js-filter-table, .js-generate-report-btn').removeClass('disabled').removeAttr('disabled');
    }
    else
    {
        return;
    }
    classInput.data('classStart', classDropdown.data('classStart'));
    classInput.data('classEnd', classDropdown.data('classEnd'));

    let dateInputs = $('.js-eop-date-checkbox');
    for (let i = 0; i < dateInputs.length; ++i)
    {
        if (dateInputs[i].checked)
        {
            updateEopTimeDisplay(dateInputs[i].id.includes('first'));
        }
    }
});

$(document).on('change', '.js-eop-date-checkbox', function(e)
{
    let inputNumber = '';
    if (!e.target.id.includes('first'))
    {
        inputNumber = '2';
    }

    if (e.target.checked)
    {
        updateEopTimeDisplay(e.target.id.includes('first'));
    }
    else
    {
        $('#js-eop-date-filter' + inputNumber).attr('hidden', true);
        $('#js-eop-start-date' + inputNumber).val('');
        $('#js-eop-end-date' + inputNumber).val('');
        $('#js-eop-start-date' + inputNumber).trigger('change');
    }
});

$(document).on('click', '.js-eop-date-button', function(e)
{
    let inputNumber = e.target.id.replace('js-eop-date-filter', '');

    $('#js-eop-start-date' + inputNumber).val('');
    $('#js-eop-end-date' + inputNumber).val('');
    $('.js-eop-date-filter' + inputNumber).each((idx, checkbox) =>
    {
        checkbox.checked = false;
    });
    $('#js-eop-start-date' + inputNumber).trigger('change');
});

$(document).on('change', '.js-reporting-class-select', function(e)
{
    $('.js-topic-dropdown-select .btn-dropdown').removeClass('disabled').attr('disabled', false).text('Topic');
    $('.js-topic-dropdown-select .js_dropdown-select-input').val('').change();

    let classId = $(this).val();

    $(`.js-topic-dropdown-select .dropdown-item:not([data-class-id=${classId}])`).hide();

    let topicItems = $(`.js-topic-dropdown-select .dropdown-item[data-class-id=${classId}]`);
    if (topicItems.length > 0)
    {
        topicItems.show();
    }
    else
    {
        $(`.js-topic-dropdown-select .js-no-topics`).show();
    }
});

$(document).on('change', '.js-topic-dropdown-select input', function(e)
{
    if ($(this).val())
    {
        $(`.search-input input, .js-generate-report-btn`).removeAttr('disabled');
    }
    else
    {
        $(`.search-input input, .js-generate-report-btn`).attr('disabled', true);
    }
});

$(document).on('change', '.js-leaderboard-report-class-select', function()
{
    if ($(this).val())
    {
        $('.js-generate-report-btn').removeAttr('disabled');
    }
    else
    {
        $('.js-generate-report-btn').attr('disabled', true);
    }
});

$(document).on('change', '.js-class-filter', function()
{
    if ($(this).val())
    {
        $('.js-filter-table, .js-generate-report-btn').removeClass('disabled').removeAttr('disabled');
    }
});