import { isClassArchived, trans } from '../../common';
import { updateClassPublishStatus } from './index';
import { Quitch } from '../../quitch';
import { telemetryTrackEvent } from '../../superuser/mixpanel';


$(document).ready(function()
{
    initManageLists();
});

/**
 * Handle UI drag drop
 */
$('ol.js_section-sortable').sortable(
{
    distance: 5,
    pullPlaceholder: true,
    items: '> li',
    onMousedown: function($item, _super, event)
    {
        if ($(event.target).hasClass('fa-ellipsis-v') || $(event.target).hasClass('topic-title'))
        {
            event.preventDefault();
            return true;
        }
        if ($('.js_class_details').length !== 0 && isClassArchived())
        {
            // No sorting for archived class
            return false;
        }
        return false;
    },
    onDragStart: function($item)
    {
        if ($($item).hasClass('accordion-parent'))
        {
            $('body').addClass('dragging-parents');
        }
        $item.parents('ol.defaults-section').addClass('dragging');
        $item.addClass('dragged');
        $('.dashboard-content .sortable')
            .not('#' + $item.parents('ol.js_section-sortable').attr('id'))
            .each(function()
            {
                $('#' + $(this).attr('id')).sortable('disable');
            });
    },
    onDrop: function($item, container, _super)
    {
        let content, url, endId, data;
        $('body').removeClass('dragging-parents');
        $item.parents('ol.defaults-section').removeClass('dragging');
        if ($($item[0]).find('div.topic').length > 0)
        {
            endId = $item.parents('ol.defaults-section').attr('id');
            data = $('ol.defaults-section')
                .sortable('serialize')
                .get();
        }
        let jsonString = JSON.stringify(data, null, ' ');
        let token = $('meta[name="csrf-token"]').attr('content');

        if (endId != null)
        {
            if (endId === 'section-topic-list')
            {
                content = { jsonString: JSON.stringify(JSON.parse(jsonString)[0]), _token: token };
                url = location.origin + '/update-order-section-topic';
            }
        }

        $.post(url, content, function()
        {
            $('ol.js_curriculum-sortable').sortable('enable');
        });
        updateClassPublishStatus('modified');

        container.el.removeClass('active');
        _super($item, container);
    },
    isValidTarget: function($item, container)
    {
        if ($($item[0]).hasClass('accordion-parent') && $(container.el[0]).hasClass('accordion-child'))
        {
            return false;
        }

        //Making sure you can't place a topic outside of a section
        if ($($item[0]).find('div.topic').length > 0)
        {
            if ($($item[0]).hasClass('accordion-block') && $(container.el[0]).hasClass('js_section-sortable'))
            {
                return false;
            }
        }
        let depth = 1,
            maxDepth = 2,
            children = $item
            .find('ol')
            .first()
            .find('li');
        depth += container.el.parents('ol').length;

        while (children.length)
        {
            depth++;
            children = children
                .find('ol')
                .first()
                .find('li');
        }
        return depth <= maxDepth;
    },
});

// Newer way of ordering used for questions and resources
function initManageLists()
{
    $('.js_manage-list-order').each(function(key, el)
    {
        let target = $(el).data('url');
        let group = $(el).data('group');
        let request_pending = 'request_pending_' + key;
        let isScrollY = false;

        window[request_pending] = null;
        let config = {
            handle: '.js_sort-handle',
            scroll: true,
            scrollSensitivity: 100,
            pullPlaceholder: true,
            items: '> li',
            onMousedown: function($item)
            {
                return true;
            },
            onDragStart: function($item)
            {
                $('body').addClass('dragging');
                $($item).addClass('dragged');
            },
            onDrag: function($item, position)
            {
                let positionY = position.top - window.scrollY;
                isScrollY = false;

                $('.js-section-list-item .section-list-item .topic .row').removeClass('hovered');
                let target = $(document.elementFromPoint(position.left, positionY));
                if ((target.hasClass('js-topic-list-item') || target.parents('.js-topic-list-item').length > 0) && !target.parents('.js_section-content-display').hasClass('active'))
                {
                    $('.placeholder').hide();
                    $($item).addClass('dragged-over-topic').removeClass('reorder');
                    $($item).attr('data-move-to-topic-id', target.attr('topic-id'));
                }
                else if (target.parents('.js-section-list-item').length > 0 || target.hasClass('js-section-list-item'))
                {
                    $($item).removeClass('reorder');
                    let closestEle = null;
                    let closestElePosY = null;
                    const sectionList = target.hasClass('js-section-list-item') ? target : target.parents('.js-section-list-item');
                    sectionList.find('.js-topic-list-item').each(function(idx, item)
                    {
                        let topicPosY = $(item).offset().top - $(window).scrollTop() + 24.25;
                        if (closestEle === null || Math.abs(topicPosY - positionY) < Math.abs(closestElePosY - positionY))
                        {
                            closestEle = item;
                            closestElePosY = topicPosY;
                        }
                    });

                    $(closestEle).parent().addClass('hovered');
                    if ($(closestEle).hasClass('active'))
                    {
                        $('.placeholder').show();
                        $($item).attr('data-move-to-topic-id', null);
                        $($item).addClass('reorder').removeClass('dragged-over-topic');
                    }
                    else
                    {
                        $('.placeholder').hide();
                        $($item).addClass('dragged-over-topic');
                        $($item).attr('data-move-to-topic-id', $(closestEle).attr('topic-id'));
                    }
                }
                else
                {
                    $('.placeholder').show();
                    $($item).attr('data-move-to-topic-id', null);
                    $($item).addClass('reorder').removeClass('dragged-over-topic');
                }

                let scroll = function scroll(stepY)
                {
                    let scrollY = document.documentElement.scrollTop || document.body.scrollTop;
                    window.scrollTo(0, (scrollY + stepY));

                    if (isScrollY)
                    {
                        setTimeout(function()
                        {
                            scroll(stepY);
                        }, 50);
                    }
                };

                // scroll to top, if mouse is near top
                if (positionY < 150)
                {
                    isScrollY = true;
                    scroll(-1);
                }

                // scroll to bottom, if mouse is near bottom
                if (positionY > (document.documentElement.clientHeight - 150))
                {
                    isScrollY = true;
                    scroll(1);
                }
            },
            onDrop: function($item, container)
            {
                isScrollY = false;
                $('body').removeClass('dragging');
                $($item).removeClass('dragged');
                let destTopicId = $item.attr('data-move-to-topic-id');
                if ($item.hasClass('dragged-over-topic'))
                {
                    let values = {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        topicContentId: $item.attr('data-topic-content-id'),
                        destTopicId: destTopicId,
                        withSnackbar: true
                    };

                    $.ajax(
                    {
                        url: '/admin/class/content/move',
                        type: 'POST',
                        dataType: 'json',
                        data: values,
                        async: false,
                        success: function(response)
                        {
                            $item.remove();
                            telemetryTrackEvent('movedContentToTopic', { 'status': 'success' });
                            Quitch.updateUrl({ section: destTopicId });
                        },
                        error: function(xhr, status, error)
                        {
                            telemetryTrackEvent('movedContentToTopic', { 'status': 'failed', 'debug': xhr.responseJSON.message, 'error': error });
                            Quitch.showSnackbar(trans('content.failedMoveContentToTopic'));
                        },
                    });
                    return;
                }

                if (!$item.hasClass('reorder'))
                {
                    return;
                }
                else
                {
                    $item.removeClass('reorder');
                }

                if ($item.parent().hasClass('nested-list'))
                {
                    if ($item.children('.question').length === 0)
                    {
                        Quitch.showSnackbar(trans('content.cannotAttachResourceToQuestion'));
                        let orderDnd = parseInt($item.attr('data-order-dnd'));
                        // Move list item back to original position
                        let ele = $item[0];
                        let parent = $item.parents('.js_manage-list-order');
                        if (orderDnd > 0)
                        {
                            parent
                                .find(`.js_content-list-item[data-order-dnd="${orderDnd-1}"]`)
                                .after(ele);
                            parent
                                .find(`.nested-list .js_content-list-item[data-order-dnd="${orderDnd}"]`).remove();
                        }
                        else
                        {
                            parent.prepend(ele);
                        }
                        return;
                    }
                    else
                    {
                        // copy schedule UI from resource to attached question
                        let resourceParent = $item.parents('.js_content-list-item').find('.instruction');
                        let resourceSchedule = resourceParent.find('.js_update-content-date');
                        let scheduleState = resourceParent.find('.accordion-parent').attr('class');

                        $item.find('.question .accordion-parent').removeClass().addClass(scheduleState);
                        $item.find('.question .js_update-content-date')
                            .attr('data-date-time', resourceSchedule.attr('data-date-time'))
                            .html(resourceSchedule.html());
                    }
                }

                let data = {
                    items: $(el)
                        .sortable('serialize')
                        .get()[0],
                };

                //Update content index of questions and resources
                $(container.el)
                    .find('> li.content-item')
                    .each((idx, item) =>
                    {
                        if ($(item).find('> .content-type .content-index').length === 1)
                        {
                            const lineEl = $(item).find('> .content-type .content-index')[0];
                            $(lineEl).text(idx + 1);
                        }
                    });

                //Update order dnd data attribute of content
                $(container.el).closest('.js_manage-list-order')
                    .find('li.content-item')
                    .each((orderDnd, item) =>
                    {
                        $(item).attr('data-order-dnd', orderDnd);
                    });

                let token = $('meta[name="csrf-token"]').attr('content');
                let jsonString = JSON.stringify(data, null, ' ');
                window[request_pending] = $.ajax(
                {
                    type: 'POST',
                    data: { _token: token, content: jsonString },
                    url: target,
                    beforeSend: function()
                    {
                        if (window[request_pending] != null)
                        {
                            window[request_pending].abort();
                        }
                    },
                    success: function(response)
                    {
                        Quitch.showSnackbar(response.message);
                        updateClassPublishStatus('modified');
                    },
                    error: function(response)
                    {
                        if (response.status === '400' || response.status === 400)
                        {
                            Quitch.showMessage('danger', response.responseJSON.message);
                        }
                    },
                });
            },
        };
        if (group)
        {
            config.nested = false; //MW: remove ability to nest
        }
        $(el).sortable(config);
    });
}