import { trans } from './common';
import { getEventNameForElement, telemetryTrackAndPerformUrlChange } from './superuser/mixpanel';

$(document).ready(function()
{
    loadUserPreferenceForClassList();
});

$(document).on('keyup', '.js_filter_classes', function()
{
    const showActiveClasses = !$('.js_class_list_setting').hasClass('archived-content');
    const value = $(this)
        .val()
        .toLowerCase();

    if (showActiveClasses)
    {
        $('#classesList tr td:contains("active")').filter(function()
        {
            const row = $(this).parent();
            row.toggle(
                row
                .text()
                .toLowerCase()
                .indexOf(value) > -1
            );
        });
    }
    else
    {
        $('#classesList tr').filter(function()
        {
            $(this).toggle(
                $(this)
                .text()
                .toLowerCase()
                .indexOf(value) > -1
            );
        });
    }
});

$(document).on('click', '.js_class_list_setting', function()
{
    const showActiveClasses = $('.js_class_list_setting').hasClass('archived-content');
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/class-manager/updateClassListSetting',
        type: 'POST',
        dataType: 'json',
        data: { showActiveClasses: showActiveClasses, _token: token },
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                let showArchivedClass = response.showArchivedClass;
                updateClassList(showArchivedClass, false);
            }
        },
    });
});

$(document).on('click', '.js-show-archived', function(e)
{
    e.preventDefault();
    const classSelector = $(this)
        .parent()
        .siblings('select');
    let showActiveClasses = classSelector.hasClass('show-archived');
    let token = $('meta[name="csrf-token"]').attr('content');

    $.ajax(
    {
        url: '/class-manager/updateClassListSetting',
        type: 'POST',
        dataType: 'json',
        data: { showActiveClasses: showActiveClasses, _token: token },
        success: function(response)
        {
            let showArchivedClass = response.showArchivedClass;
            if (response.status === '200' || response.status === 200)
            {
                updateClassDropDownList(showArchivedClass);
            }
        },
    });
});

$(document).on('click', '.js_class-action', function(e)
{
    let classId = $(e.target)
        .parents('.class-card')
        .data('class-id');

    const eventName = getEventNameForElement($(this), 'class-action');
    telemetryTrackAndPerformUrlChange('/class-manager/' + classId, eventName);
});

$(document).on('click', '.js-toggle-favourite-button', function(e)
{
    e.stopPropagation();

    let currentTarget = $(e.currentTarget);
    currentTarget.parent().removeClass('show');
    let classId = currentTarget.data('class-id');
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/class/' + classId + '/toggle-favourite',
        type: 'POST',
        dataType: 'json',
        data: { _token: token },
        success: function(response)
        {
            let classCard = $('.class-card-' + response.classId);
            if (response.preferences.favourite)
            {
                classCard.find('a.js-toggle-favourite-button').html(trans('home.unFavouriteButton'));
                classCard.find('i.favourite-star').removeClass('far');
                classCard.find('i.favourite-star').addClass('fas');
            }
            else
            {
                classCard.find('a.js-toggle-favourite-button').html(trans('home.favouriteButton'));
                classCard.find('i.favourite-star').addClass('far');
                classCard.find('i.favourite-star').removeClass('fas');
            }
            window.location.reload();
        },
    });
});

function loadUserPreferenceForClassList()
{
    const userSetting = $('.js_user_class_setting').val();
    let showArchivedClass = userSetting != '0';
    updateClassList(showArchivedClass, true);
    updateClassDropDownList(showArchivedClass);
}

/**
 * Show class dropdown list on reports pages based on user preference setting
 */
function updateClassDropDownList(showArchivedClass)
{
    const classSelector = $('.js-show-archived')
        .parent()
        .siblings('select');
    const initialValue = classSelector.val();
    if (showArchivedClass)
    {
        classSelector.addClass('show-archived');
        classSelector.children('option[data-archived=true]').show();
        classSelector.append(`<option data-archived=false value="archived" id="archiveTag" disabled>------${trans('reporting.archiveText')}------</option>`);
        $('.js-show-archived').text(trans('reporting.hideArchived'));
    }
    else
    {
        classSelector.removeClass('show-archived');
        classSelector.children('option[id*="archiveTag"]').remove();
        classSelector.children('option[data-archived=true]').hide();
        $('.js-show-archived').text(trans('reporting.classLabelArchiveLink'));
    }
    const options = classSelector.children('option').detach();
    options.sort(function(a, b)
    {
        return $(a).data('archived') - $(b).data('archived');
    });
    classSelector.append(options);
    const style = initialValue ? classSelector.find(`[value=${initialValue}]`).attr('style') : undefined;
    if (style === undefined || !style.includes('display: none'))
    {
        classSelector.val(initialValue);
    }
    else
    {
        classSelector.val(classSelector.find('option:first').val());
    }
}

/**
 * Show class list based on user preference setting
 * @param showArchivedClass
 * @param reload
 */
function updateClassList(showArchivedClass, reload)
{
    const totalClasses = $('#classesList tr').length;
    const totalArchivedClasses = $('#classesList tr td:contains("archived")').length;
    const value = $('.js_filter_classes').val() ?
        $('.js_filter_classes')
        .val()
        .toLowerCase() :
        $('.js_filter_classes').val();
    if (showArchivedClass || (!showArchivedClass && totalClasses == totalArchivedClasses && reload))
    {
        if (value)
        {
            $('#classesList tr').filter(function()
            {
                const filteredRow = $(this).toggle(
                    $(this)
                    .text()
                    .toLowerCase()
                    .indexOf(value) > -1
                );
                filteredRow.show();
            });
        }
        else
        {
            $('#classesList tr td:contains("archived")')
                .parent()
                .show();
        }
        $('.js_class_list_setting').text(trans('reporting.hideArchived'));
        $('.js_class_list_setting').addClass('archived-content');
    }
    else
    {
        $('#classesList tr td:contains("archived")')
            .parent()
            .hide();
        $('.js_class_list_setting').text(trans('reporting.classLabelArchiveLink'));
        $('.js_class_list_setting').removeClass('archived-content');
    }
}

$(document).on('input', '#class-search', function()
{
    let searchVal = String($(this).val()).toLowerCase();
    let classCards = $('.accordion-class');
    let noResults = true;

    classCards.each(function()
    {
        let className = String($(this).data('class-name'));
        if (className)
        {
            className = className.toLowerCase();
            if (className.includes(searchVal))
            {
                $(this).show();
                noResults = false;
            }
            else
            {
                $(this).hide();
            }
        }
    });

    if (noResults)
    {
        $('.zero-results-message').show();
    }
    else
    {
        $('.zero-results-message').hide();
    }
});