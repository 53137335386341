import { trans } from '../common.js';
import { Quitch } from '../quitch';
import { telemetryTrackEvent } from '../superuser/mixpanel';

/**
 * Bind click event handler to upload file buttons
 */
$(document).on('click', '[data-file-upload-btn]', e =>
{
    e.preventDefault();
    const $parent = $(e.target).parent();
    const action = $(e.target).attr('data-file-upload-btn');
    let $fileInput = $parent.find('input[type=file]');
    if ('upload' === action)
    {
        $fileInput.trigger('click');
    }
});

$(document).on('click', '.js-file-upload-new-btn', e =>
{
    e.preventDefault();
    $(e.target)
        .parents('.question-image, .answer, .image-box')
        .find('input[type=file]')
        .click();
});

$(document).on('click', '.js-file-upload-remove-btn', e =>
{
    e.preventDefault();
    const $parent = $(e.target).parents('.question-image, .answer, .image-box').find('.file-upload-section');
    removeUploadedFile($(e.target).data('file-upload'), $parent, $parent.find('[data-file-upload]'));
});

$(document).on('dragenter', '.image-file-drag-drop', e =>
{
    e.preventDefault();
    $(e.currentTarget).addClass('file-drop-hover');
});
$(document).on('dragleave', '.image-file-drag-drop', e =>
{
    $(e.currentTarget).removeClass('file-drop-hover');
});

$(document).on('dragover', '.image-file-drag-drop', e =>
{
    e.preventDefault();
    $(e.currentTarget).addClass('file-drop-hover');
    e.UseDefaultCursors = false;
});

$(document).on('drop', '.image-file-drag-drop', e =>
{
    e.preventDefault();
    $(e.currentTarget).removeClass('file-drop-hover');

    let fileUpload = $(e.target.parentElement).find('[data-file-upload]');
    fileUpload.prop('files', e.originalEvent.dataTransfer.files);
    fileUpload.change();
});

/**
 * Bind change event handler to upload file inputs
 */
$(document).on('change', '[data-file-upload]', e =>
{
    e.preventDefault();
    let target = e.target;
    $('.validateError').hide();
    $('.validateErrorList').empty();
    const $parent = $(target).parent();
    const files = $(target).prop('files');
    const type = $(target).attr('data-file-upload');
    const imagePreview = $parent.parent().find('.image-preview');
    const imageBox = $parent.closest('.image-box');
    const imageManagement = imageBox.find('.image-management');

    let endpoint = '/admin/file/upload-tenant';
    if (imageBox.data('upload-type') === 'universal')
    {
        endpoint = '/admin/file/upload-universal';
    }

    if (files && files.length > 0)
    {
        // client side extension / size validation
        const fileSize = files[0].size / 1024 / 1024; // in MB

        const fileExt = files[0].name.toLowerCase().split('.').pop();

        // TODO: i18n validation values
        if (type === 'document')
        {
            if (!['pdf', 'doc', 'docx', 'pptx'].includes(fileExt))
            {
                Quitch.modal({ message: trans('content.supportedDocs'), title: trans('content.unsupportedFileType') });
                return false;
            }
        }
        else if (type === 'pdf')
        {
            if (fileExt !== 'pdf')
            {
                Quitch.modal({ message: trans('content.unsupportedPdfOnly'), title: trans('content.unsupportedFileType') });
                return false;
            }
        }
        else if (type === 'csv')
        {
            if (fileExt !== 'csv')
            {
                Quitch.modal({ message: trans('invites.bulkSupportedText'), title: trans('content.unsupportedFileType') });
                return false;
            }
        }
        else
        {
            if (!['png', 'jpg', 'jpeg', 'gif'].includes(fileExt))
            {
                Quitch.modal({ message: trans('content.unsupportedImage'), title: trans('content.unsupportedFileType') });
                return false;
            }
        }

        if (5 <= fileSize)
        {
            Quitch.modal({ message: trans('content.fileLessThan5MB'), title: trans('content.fileSizeExceeded') });
            return false;
        }

        let form_data = new FormData();
        form_data.append('file', files[0]);
        form_data.append('class_id', $('.js_class-manager, .js-class-id').data('class-id') || null);
        form_data.append('_token', $('meta[name="csrf-token"]').attr('content'));
        $(target).data('uploading', true);

        imageManagement.hide();

        // Show loader in image preview, hide loading text if image preview is less than 100px wide
        imagePreview.html(`<div class="image-loader">
                                        <i class="fa-solid fa-circle-notch fa-pulse load-roll"></i>
                                        ${imagePreview.width() > 100 ? '<p class="ml-2">' + trans('base.loadingDots') + '</p>' : ''}
                                    </div>`);

        $.ajax(
        {
            url: endpoint,
            processData: false,
            contentType: false,
            data: form_data,
            type: 'POST',
            dataType: 'json',
            success: response =>
            {
                displayImagePreview($parent, imagePreview, response.filename.toString(), response.fileExt.toLowerCase(), response.url, response.path);

                if (type === 'question' || type === 'class' || type === 'resource')
                {
                    updateUploadedFileName(files[0].name, files[0].size, null, $parent.parentsUntil(null, '.question-image, .answer, .image-box'), true);
                }
                else if (type === 'answer')
                {
                    updateImageAnswerDropdown($parent.parentsUntil(null, '.question-image, .answer'), true);
                }
                else if (type === 'org-logo')
                {
                    updateUploadedFileName(files[0].name, files[0].size, null, $parent.parentsUntil(null, '.image-box'));
                    $('.org-details img').attr('src', response.url).show();
                }
                else if (type === 'document' || type === 'pdf' || type === 'csv')
                {
                    updateUploadedFileName(files[0].name, files[0].size, response.url, $parent.parentsUntil(null, '.image-box'));
                }

                $('#add-image-done-btn').prop('disabled', false);
                $('.add-image-modal-img').removeClass('selected');
            },
            error: error =>
            {
                //TODO: consider indicating error uploaded file??
                $parent
                    .find('button[data-file-upload-btn]')
                    .data('file-upload-btn', 'upload')
                    .text(trans('content.uploadImageBtn'));
                $parent
                    .parent()
                    .find('.image-preview')
                    .html(`<i class="fa fa-2x fa-exclamation-triangle" aria-hidden="true"></i>`);
                $parent.find('input[type=hidden]').val('');
            },
            complete: () =>
            {
                $(target).data('uploading', false);
                $parent.find('input[type=file]').val('');
            },
        });
    }
});

export function loadExistingImageToImageBox(parentElem, imageUrl)
{
    if (imageUrl == null || imageUrl === '')
    {
        return;
    }

    const storagePath = '/storage/' + imageUrl.split('/storage/').pop();
    const fileExt = imageUrl.split('.').pop().toLowerCase();

    // Remove the last '/' and everything before and the last '_' and everything after
    const origFileName = (imageUrl.slice(0, imageUrl.lastIndexOf('_'))).slice(imageUrl.lastIndexOf('/') + 1) + '.' + fileExt;

    const data = { 'fileUrl': storagePath };
    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/get-existing-file-data/',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, data: data },
        success: function(response, status, xhr)
        {
            if (xhr.status === 200)
            {
                const assetUrl = response.data['assetUrl'];
                const fileSize = response.data['fileSize'];
                const $parent = parentElem.find('.file-upload-section');
                const imagePreview = parentElem.find('.image-preview');

                displayImagePreview($parent, imagePreview, origFileName, fileExt, assetUrl, storagePath);
                updateUploadedFileName(origFileName, fileSize, null, $parent.parentsUntil(null, '.question-image, .answer, .image-box'), true, false);
            }
        }
    });

}

$(document).on('click', '.get-unsplash-img', function(e)
{
    $('.validateError').hide();
    e.preventDefault();
    handleUnsplash(this, true);
});

$(document).on('click', '.unsplash-link', function(e)
{
    e.stopPropagation();
    window.open('https://unsplash.com/', '_blank');
});

$(document).on('click', '.retry-unsplash-img', function(e)
{
    e.preventDefault();
    handleUnsplash(this, false);
});

export function displayImagePreview($parent, imagePreview, fileName, fileExt, url, path)
{
    $parent.find('.get-unsplash-img').text(trans('dashboard.classImageGenerate'));
    $parent.find('.get-unsplash-img').tooltip();
    $parent.find('.get-unsplash-img').tooltip('enable');
    $parent.find('.retry-unsplash-img').hide();
    $parent
        .find('button[data-file-upload-btn]')
        .data('file-upload-btn', 'clear')
        .text(trans('content.removeBtn'));
    imagePreview.html(previewImage(fileExt, fileName, url));
    $parent.find('input[type=hidden]:not(.js-file-name)').val(path).trigger('change');
    $parent.find('input[name=asset-url]').val(url).trigger('change');
    $parent.find('input[name=original-filename]').val(fileName).change();
}

function handleUnsplash(target, isInitialLoad)
{
    let $model = $(target).parents('#' + $(target).attr('data-modal-id'));
    let input = $model.find('.' + $(target).attr('data-input-class'));

    input.removeClass('error-input').closest('.form-group').find('.error-msg').remove();

    // remove formulas, tags, special characters and extra spaces
    let searchString = input.val()
        .replace(/(\[([^\]]+)\])|\(.*?\)/ig, ' ')
        .replace(/(<([^>]+)>)/ig, ' ')
        .replace(/[^a-zA-Z ]/g, ' ')
        .replace(/\s+/g, ' ').trim();
    if (!searchString)
    {
        let errLabel = $(target).attr('data-error-label');
        if (errLabel)
        {
            $(`.${errLabel}`).show();
            input.addClass('error-input');
        }
        else if (!input.hasClass('error-input') || input.after().is('.error-msg'))
        {
            input.addClass('error-input').after(`<p class="error-msg">${trans('content.requiredField')}</p>`);
        }
        return false;
    }

    let classImage = $model
        .find('.image-preview img')
        .attr('src');

    if (isInitialLoad)
    {
        $(target).tooltip();
        if (classImage && $(target).text() === 'Remove')
        {
            resetImagePreview($model, $(target).data('file-upload'));

            $model
                .find('input[name="image_path"]')
                .val('');
            $model
                .find('.retry-unsplash-img')
                .hide();
            $(target).text(trans('dashboard.classImageGenerate'));
            $(target).tooltip('enable');

            unsplashDropdownRetryToGet($model);
        }
        else
        {
            $(target)
                .parent()
                .find('button[data-file-upload-btn]')
                .data('file-upload-btn', 'upload')
                .text(trans('content.uploadImageBtn'));
            getUnsplashImages($model, searchString, $(target).data('file-upload'));
            $model
                .find('.retry-unsplash-img')
                .show();

            let dropdownUnsplash = $model.find('.dropdown .get-unsplash-img');
            dropdownUnsplash.removeClass('get-unsplash-img');
            dropdownUnsplash.addClass('retry-unsplash-img');
        }
    }
    else
    {
        //it's a reload
        let unsplashImages = JSON.parse(window.localStorage.getItem('unsplashImages'));
        if (classImage != undefined && unsplashImages && unsplashImages.length > 0)
        {
            reloadImages($model, unsplashImages, $(target).data('file-upload'));
        }
        else
        {
            getUnsplashImages($model, searchString, $(target).data('file-upload'));
        }
    }
}

function unsplashDropdownRetryToGet($model)
{
    //reset dropdown unsplash retry
    let dropdownUnsplash = $model
        .find('.dropdown .retry-unsplash-img');
    dropdownUnsplash.removeClass('retry-unsplash-img');
    dropdownUnsplash.addClass('get-unsplash-img');
}

function getUnsplashImages($model, searchString, imageType)
{
    $model
        .find('.validateErrorList')
        .empty();

    if (imageType === 'question' || imageType === 'class' || imageType === 'resource')
    {
        $model.find(`.js-${imageType}-image-content`).hide();
        $model.find(`.js-${imageType}-image-loader`).show();
    }
    else
    {
        $model.find('.image-preview').html(`<i class="fa-solid fa-circle-notch fa-pulse load-roll adjacent"></i>`);
    }

    $.ajax(
    {
        url: '/get-unsplash-image/' + searchString,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.imageUrls && response.imageUrls.length > 0)
            {
                addImageSizes(response.imageUrls).then((imageUrls) =>
                {
                    if (imageType === 'question' || imageType === 'class' || imageType === 'resource')
                    {
                        $model.find(`.js-${imageType}-image-content`).show();
                        $model.find(`.js-${imageType}-image-loader`).hide();
                    }

                    reloadImages($model, imageUrls, imageType);
                });
            }
            else
            {
                if (imageType === 'question' || imageType === 'class' || imageType === 'resource')
                {
                    $model.find(`.js-${imageType}-image-content`).show();
                    $model.find(`.js-${imageType}-image-loader`).hide();
                }

                $model
                    .find('.image-preview')
                    .html(`<i class="fa fa-2x fa-picture-o" aria-hidden="true"></i>`);
                $model
                    .find('.validateErrorList')
                    .append(response.message);
                $model
                    .find('.validateError')
                    .show();
                return false;
            }
        },
    });
}

async function addImageSizes(response)
{
    return new Promise(function(resolve)
    {
        let unSplashImages = [];
        for (const imageResponse of response)
        {
            getImageSize(imageResponse.url).then(function(imageSize)
            {
                unSplashImages.push({ id: imageResponse.id, url: imageResponse.url, imageSize: imageSize });
                if (unSplashImages.length >= response.length)
                {
                    resolve(unSplashImages);
                }
            }).catch(function(error)
            {
                unSplashImages.push({ id: imageResponse.id, url: imageResponse.url, imageSize: null });
                if (unSplashImages.length >= response.length)
                {
                    resolve(unSplashImages);
                }
            });
        }
    });
}

async function getImageSize(url)
{
    return new Promise(function(resolve, reject)
    {
        let xhr = new XMLHttpRequest();
        xhr.open('HEAD', url, true);
        xhr.onreadystatechange = function()
        {
            if (this.readyState === this.DONE)
            {
                resolve(parseInt(xhr.getResponseHeader('Content-Length')));
            }
        };
        xhr.onerror = function()
        {
            reject('Failed to get image size');
        };
        xhr.send(null);
    });
}

function reloadImages($model, response, imageType)
{
    $model
        .find('.image-preview')
        .empty();
    let imageRef = response.pop();
    $('input[name="image_path"]').val('unsplash://' + imageRef.id);
    $model
        .find('.image-preview')
        .append(`<img src="${imageRef.url}"/>`);

    if (imageType === 'question' || imageType === 'class' || imageType === 'resource')
    {
        $model
            .find(`input[name="${imageType}-img-path"]`)
            .val('unsplash://' + imageRef.id);
        let fileName = imageRef.url.split('/');
        fileName = fileName[fileName.length - 1].split('?')[0];
        $model
            .find(`input[name="original-filename"]`)
            .val(fileName).trigger('change');
        updateUploadedFileName(fileName, imageRef.imageSize, null, $model);
    }
    else if (imageType === 'answer')
    {
        $model
            .find('input[name="answer-img-paths[]"]')
            .val('unsplash://' + imageRef.id).trigger('change');
        updateImageAnswerDropdown($model, true);
    }
    else
    {
        $model
            .find('.get-unsplash-img')
            .text(trans('content.removeBtn'));
        $model
            .find('.get-unsplash-img')
            .tooltip();
        $model
            .find('.retry-unsplash-img')
            .show();
    }
    window.localStorage.setItem('unsplashImages', JSON.stringify(response));
}

export function updateUploadedFileName(fileName, fileSize, fileUrl, $section, isDirectFileUpload = false, formatSize = true)
{
    $section
        .find('.unsplash-upload')
        .hide();

    let imageManagement = $section.find('.image-management');
    imageManagement.find('.js-file-name').html(fileName);

    let size = fileSize;
    if (formatSize && size)
    {
        size = size / 1024;
        if (size < 100)
        {
            size = size.toPrecision(3);
        }
        else
        {
            size = size.toFixed(0);
        }
    }

    if (!size || size === '0.00')
    {
        imageManagement.find('.js-file-size').html('');
    }
    else
    {
        imageManagement.find('.js-file-size').html(trans('content.size', { size: size }));
    }

    if (fileUrl)
    {
        let src = '';
        if (fileUrl.toLowerCase().endsWith('.pdf'))
        {
            src = fileUrl;
        }
        else
        {
            src = 'https://docs.google.com/viewer?url=' + fileUrl;
        }
        imageManagement.find('.js-view-file-btn').attr('href', src);
    }

    imageManagement.show();

    $section
        .find('button[data-file-upload-btn]')
        .attr('data-file-upload-btn', 'clear')
        .text(trans('content.removeBtn'));

    if (isDirectFileUpload)
    {
        unsplashDropdownRetryToGet($section);
    }
}

export function resetImagePreview($imagePreviewParent, fileInputType, includeImageSupportText = true)
{
    if (fileInputType === 'question' || fileInputType === 'answer' || fileInputType === 'class' || fileInputType === 'resource')
    {
        let text = trans('content.imageUpload');
        let defaultImage = 'fa-upload';
        if (fileInputType === 'answer')
        {
            text = trans('content.addImage');
            defaultImage = 'fa-picture-o';
            updateImageAnswerDropdown($imagePreviewParent, false);
        }

        let newHtml = `<div class="image-preview-empty">
                           <i class="fa fa-2x ${defaultImage}" aria-hidden="true"></i>
                           <p>${text}</p>`;
        if (includeImageSupportText)
        {
            newHtml += `<label>${trans('content.imageSupport')}</label>`;
        }
        newHtml += `</div>`;

        $imagePreviewParent
            .find('.image-preview')
            .html(newHtml);
    }
    else if (fileInputType === 'org-logo')
    {
        $imagePreviewParent
            .find('.image-preview')
            .html(`<div class="image-preview-empty">
                            <i class="fa fa-2x fa-upload" aria-hidden="true"></i>
                            <p>${trans('content.imageUpload')}</p>
                            <label>${trans('orgManager.logoSupports')}</label>
                        </div>`);
    }
    else if (fileInputType === 'document')
    {
        $imagePreviewParent
            .find('.image-preview')
            .html(`<div class="image-preview-empty">
                            <i class="fa fa-2x fa-upload" aria-hidden="true"></i>
                            <p>${trans('content.docUpload')}</p>
                            <label>${trans('content.supportedDocs')}</label>
                        </div>`);
    }
    else if (fileInputType === 'pdf')
    {
        $imagePreviewParent
            .find('.image-preview')
            .html(`<div class="image-preview-empty">
                            <i class="fa fa-2x fa-upload" aria-hidden="true"></i>
                            <p>${trans('content.docUpload')}</p>
                            <label>${trans('content.docSupport')}</label>
                        </div>`);
    }
    else if (fileInputType === 'csv')
    {
        $imagePreviewParent
            .find('.image-preview')
            .html(`<div class="image-preview-empty">
                            <i class="fa fa-2x fa-upload" aria-hidden="true"></i>
                            <p>${trans('content.docUpload')}</p>
                            <label>${trans('invites.bulkSupportedText')}</label>
                        </div>`);
    }
    else
    {
        $imagePreviewParent
            .find('.image-preview')
            .html(`<i class="fa fa-2x fa-picture-o" aria-hidden="true"></i>`);
    }
}

export function removeUploadedFile(fileInputType, $parent, target)
{
    const includeImageSupportText = fileInputType !== 'answer';

    resetImagePreview($parent.parent(), fileInputType, includeImageSupportText);

    // update file upload btn state from 'clear' to 'upload'
    $parent.find('button[data-file-upload-btn]')
        .attr('data-file-upload-btn', 'upload')
        .text(trans('content.uploadFileBtn'));

    if (fileInputType === 'question' || fileInputType === 'class' || fileInputType === 'resource')
    {
        $parent
            .parents('.question-image, .answer, .image-box')
            .find('.image-management')
            .hide();
        $parent
            .parents('.question-image, .answer, .image-box')
            .find('.unsplash-upload')
            .show();
        $parent.show();
        unsplashDropdownRetryToGet($parent
            .parents('.question-image, .answer, .image-box'));
    }
    else if (fileInputType === 'answer')
    {
        unsplashDropdownRetryToGet($parent
            .parents('.question-image, .answer'));
        updateImageAnswerDropdown($parent.parents('.question-image, .answer'), false);
    }
    else if (fileInputType === 'org-logo' || fileInputType === 'document' || fileInputType === 'pdf' || fileInputType === 'csv')
    {
        $parent
            .parents('.image-box')
            .find('.image-management')
            .hide();
        $parent.show();
        if (fileInputType === 'org-logo')
        {
            $('.org-details img').hide();
        }
    }
    else
    {
        $(target)
            .attr('data-file-upload-btn', 'upload')
            .text(trans('content.uploadImageBtn'));
    }
    $parent.find('input[type=hidden]').val('').trigger('change');
    $parent.find('input[type=file]').val('');
}


function updateImageAnswerDropdown($section, hasImage)
{
    let removeButton = $section.find('.js-file-upload-remove-btn');
    if (hasImage)
    {
        removeButton.show();
        $section
            .find('button[data-file-upload-btn]')
            .attr('data-file-upload-btn', 'clear')
            .text(trans('content.removeBtn'));
    }
    else
    {
        removeButton.hide();
    }
}

/**
 * Return html of image or icon depending on filetype
 * @param fileExt
 * @param filename
 * @param url
 * @returns string
 */
export function previewImage(fileExt, filename, url)
{
    if (!['png', 'jpg', 'jpeg', 'gif'].includes(fileExt))
    {
        let icon = 'fa-file';
        if (['doc', 'docx'].includes(fileExt))
        {
            icon = 'fa-file-word';
        }
        else if (['ppt', 'pptx'].includes(fileExt))
        {
            icon = 'fa-file-powerpoint';
        }
        else if ('pdf' === fileExt)
        {
            icon = 'fa-file-pdf';
        }
        else if ('csv' === fileExt)
        {
            icon = 'fa-file-csv';
        }
        else if (['xls', 'xlsx'].includes(fileExt))
        {
            icon = 'fa-file-excel';
        }
        return `<i class="fa fa-2x file-icon ${icon}" aria-hidden="true" data-toggle="tooltip" title="${filename}"></i>`;
    }
    else
    {
        return `<img src="${url}" alt="image preview" onerror="imageErrorHandler(this)"/>`;
    }
}

$(document).on('keydown', '.js-add-image-modal-search', function(e)
{
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey)
    {
        e.preventDefault();
        $(this).blur();
    }
});

$(document).on('show.bs.modal', '#add-image-modal', function()
{
    // Reset modal on opening
    const $parent = $(this).find('.file-upload-section');
    removeUploadedFile('question', $parent, $parent.find('[data-file-upload]'));

    const imgLibrary = $(this).find('#img-library');
    if (!imgLibrary.data('origHtml'))
    {
        imgLibrary.data('origHtml', imgLibrary.html());
    }
    else
    {
        imgLibrary.html(imgLibrary.data('origHtml'));
    }

    $('#add-image-done-btn').prop('disabled', true);
});

$(document).on('shown.bs.modal', '#add-image-modal', function()
{
    const imgLibrary = $(this).find('#img-library');
    const leftCol = imgLibrary.find('.img-col-left');
    const rightCol = imgLibrary.find('.img-col-right');
    if (leftCol.html() == '' && rightCol.html() == '')
    {
        const url = $('#images-json-file').data('val');
        $.ajax(
        {
            url: url,
            type: 'GET',
            success: function(response)
            {
                if (response)
                {
                    setImageModalImages(response, imgLibrary);
                }
            },
        });
    }
});

async function setImageModalImages(imageUrls, imgLibrary)
{
    let leftColHeight = 0;
    let rightColHeight = 0;

    const getMeta = (url) =>
        new Promise((resolve, reject) =>
        {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (err) => reject(err);
            img.src = url;
        });

    const leftCol = imgLibrary.find('.img-col-left');
    const rightCol = imgLibrary.find('.img-col-right');
    const loadRollHtml = `
    <div class="img-upload-load-roll-holder">
        <i class="fa-solid fa-circle-notch fa-pulse img-upload-load-roll adjacent"></i>
    </div>
    `;
    leftCol.html(loadRollHtml);
    rightCol.html(loadRollHtml);

    for (let i = 0; i < imageUrls.length; ++i)
    {
        const img = await getMeta(imageUrls[i]['url']);
        if (img)
        {
            const classes = 'add-image-modal-img';
            const newHtml = `<div class="img-outer"><img src="${imageUrls[i]['url']}" class="${classes}" alt="image" draggable="false" data-unsplash-id="${imageUrls[i]['id']}"/></div>`;

            if (leftColHeight <= rightColHeight)
            {
                leftCol.find('.img-upload-load-roll-holder').before(newHtml);
                leftColHeight += img.naturalHeight;
            }
            else
            {
                rightCol.find('.img-upload-load-roll-holder').before(newHtml);
                rightColHeight += img.naturalHeight;
            }
        }
    }

    leftCol.find('.img-upload-load-roll-holder').remove();
    rightCol.find('.img-upload-load-roll-holder').remove();
}

$(document).on('change paste', '.js-add-image-modal-search', function(e)
{
    const searchString = $(this).val();
    if (searchString !== '')
    {
        telemetryTrackEvent('ep-add-image-modal-unsplash-run', { 'searchString': searchString });

        $('#add-image-done-btn').prop('disabled', true);

        const imgLibrary = $(this).closest('#img-library');
        const storedImgLibraryHtml = imgLibrary.html();

        const loadRollHtml = `
        <div class="img-upload-load-roll-holder">
            <i class="fa-solid fa-circle-notch fa-pulse img-upload-load-roll adjacent"></i>
        </div>
        `;
        imgLibrary.html(loadRollHtml);

        $.ajax(
        {
            url: '/get-unsplash-image/' + searchString,
            type: 'GET',
            dataType: 'json',
            success: function(response)
            {
                if (response.imageUrls && response.imageUrls.length > 0)
                {
                    imgLibrary.html(storedImgLibraryHtml);
                    $('.js-add-image-modal-search').val(searchString);
                    setImageModalImages(response.imageUrls, imgLibrary);
                }
                else
                {
                    console.log('No images returned from unsplash request for:', [searchString]);
                }
            }
        });
    }
});

$(document).on('click', '#upload-tab', function(e)
{
    const $parent = $(this).parents('.modal-body').first().find('.file-upload-section');
    const uploadPath = $parent.find('[name=\'img-upload-path\']');

    $('#add-image-done-btn').prop('disabled', uploadPath[0].value === '' || uploadPath[0].value == null);
});

$(document).on('click', '#img-library-tab', function(e)
{
    $('#add-image-done-btn').prop('disabled', !$('.add-image-modal-img').hasClass('selected'));
});

$(document).on('click', '.add-image-modal-img', function(e)
{
    $('.add-image-modal-img').removeClass('selected');
    $(this).addClass('selected');

    telemetryTrackEvent('ep-add-image-modal-image-selected');

    $('#add-image-done-btn').prop('disabled', false);

    const $parent = $(this).parents('.modal-body').first().find('.file-upload-section');
    removeUploadedFile('question', $parent, $parent.find('[data-file-upload]'));
});

$(document).on('change', '#add-image-modal [name=img-upload-path]', function(e)
{
    if ($(this).val() === '' && $('#upload-tab').hasClass('active'))
    {
        $('#add-image-done-btn').prop('disabled', true);
    }
});

$(document).on('click', '#add-image-done-btn', function(e)
{
    const imgSelector = $($('#add-image-done-btn').attr('data-img-dest'));
    let imgPreviewUrl;
    let imgPath;
    let imgName;

    if ($('#img-library-tab').hasClass('active'))
    {
        imgPreviewUrl = $('.add-image-modal-img.selected').attr('src');
        imgPath = 'unsplash://' + $('.add-image-modal-img.selected').attr('data-unsplash-id');
        imgName = $('.add-image-modal-img.selected').attr('data-unsplash-id');
    }
    else
    {
        imgPreviewUrl = $('#add-image-modal .image-preview img').attr('src');
        imgPath = $('#add-image-modal [name=img-upload-path]').val();
        imgName = $('#add-image-modal [name=original-filename]').val();
    }

    imgSelector.siblings('.image-filename-input').val(imgName);
    imgSelector.val(imgPath).change();
    imgSelector.siblings('.image-filename').text(imgName);
    imgSelector.siblings('.preview-image').attr('src', imgPreviewUrl).change().toggle(!!imgPreviewUrl);
});

$(document).on('change', '#add-document-modal [name=resource-doc-path]', function(e)
{
    $('#add-doc-done-btn').prop('disabled', $(this).val() === '');
});

$(document).on('click', '#add-doc-done-btn', function(e)
{
    const docSelector = $($('#add-doc-done-btn').attr('data-doc-dest'));
    const docPath = $('#add-document-modal [name=resource-doc-path]').val();
    const assetUrl = $('#add-document-modal [name=asset-url]').val();
    const filename = $('#add-document-modal [name=original-filename]').val();
    docSelector.siblings('.doc-filename-input').val(filename);
    docSelector.siblings('.doc-filename').text(filename);
    docSelector.siblings('.doc-asset-url').val(assetUrl);
    docSelector.val(docPath).change();
});

$(document).on('change', '#add-video-modal [name=video-link]', function(e)
{
    $('#add-video-done-btn').prop('disabled', !$(this).hasClass('valid') || $(this).val() === '');
});

$(document).on('click', '#add-video-done-btn', function(e)
{
    const inputSelector = $($(this).attr('data-video-dest'));
    const url = $('#add-video-modal [name=video-link]').val();
    inputSelector.siblings('.video-url').text(url);
    inputSelector.val(url).change();
});

$(document).on('show.bs.modal', '#add-document-modal', function()
{
    if ($(this).hasClass('pdf-only'))
    {
        $(this).find('.image-preview-empty label').text(trans('content.pdfOnly'));
        $(this).find('.file-upload-section [name=file-upload]').attr('accept', '.pdf');
    }
    else
    {
        $(this).find('.image-preview-empty label').text(trans('content.supportedDocs'));
        $(this).find('.file-upload-section [name=file-upload]').attr('accept', '.pdf,.doc,.docx,.pptx');
    }
});

$(document).on('hidden.bs.modal', '#add-document-modal', function()
{
    $(this).removeClass('pdf-only');
    const ele = $('#add-document-modal .image-box .file-upload-section');
    removeUploadedFile('document', ele, ele.find('[data-file-upload]'));
});

export function removeUploadedImageFromModal(modalId)
{
    const modal = $('#' + modalId);
    const uploadPath = modal.find('[name=\'img-upload-path\']');
    const fileInputType = modal.find('[name=\'file-upload\']').data('file-upload');

    if (uploadPath[0].value !== '' && uploadPath[0].value != null)
    {
        const parent = modal.find('.file-upload-section');
        removeUploadedFile(fileInputType, parent, parent.find('[data-file-upload]'));
    }
}