import mixpanel from 'mixpanel-browser';

let hasTelemetryInitialised = false;
let hasTelemetryIdentified = false;

$(document).ready(function()
{
    processMixpanel();
});

export function initTelemetry()
{
    const mixpanelEnabledEp = $('meta[name="mixpanelEnabledEp"]').attr('content');
    if (!mixpanelEnabledEp || mixpanelEnabledEp == 'false')
    {
        console.warn('Mixpanel for EP not enabled');
        return false;
    }

    if (hasTelemetryInitialised)
    {
        identifyTelemetry(); // In case it failed to identify initially
        return true;
    }

    const mixpanelToken = $('meta[name="mixpanelToken"]').attr('content');
    if (mixpanelToken)
    {
        mixpanel.init(mixpanelToken, { ip: false, debug: true });

        identifyTelemetry();

        hasTelemetryInitialised = true;
        return true;
    }
    else
    {
        console.warn('Mixpanel failed to initialise - mixpanelToken is undefined');
        return false;
    }
}

export function identifyTelemetry()
{
    if (hasTelemetryIdentified)
    {
        return true;
    }

    const userId = $('meta[name="userId"]').attr('content');
    if (userId)
    {
        mixpanel.identify(userId);
        hasTelemetryIdentified = true;
        return true;
    }
    else
    {
        console.warn('Mixpanel could not identify user - userId is undefined');
        return false;
    }
}

$(document).on('click', 'a', function(e) // Intercept every link click (unless it has the no-track-intercept class)
    {
        if (initTelemetry() && !$(this).hasClass('no-track-intercept'))
        {
            e.preventDefault();

            const name = getEventNameForElement($(this), 'link');
            const link = $(this).attr('href');
            const linkTarget = $(this).attr('target');
            if (link && link !== '#' && link !== '')
            {
                let hasCallbackRun = false;
                mixpanel.track(name, telemetryAddDefaultProperties(), function(res)
                {
                    if (!hasCallbackRun)
                    {
                        hasCallbackRun = true;
                        window.open(link, linkTarget ? linkTarget : '_self');
                    }
                });

                setTimeout(() =>
                {
                    if (!hasCallbackRun)
                    {
                        console.warn('Telemetry timed out (link)');
                        hasCallbackRun = true;
                        window.open(link, linkTarget ? linkTarget : '_self');
                    }
                }, 2000);
            }
            else
            {
                mixpanel.track(name, telemetryAddDefaultProperties());
            }
        }
    });

$(document).on('click', '[type="submit"]', function(e) // Intercept form submission
    {
        if (initTelemetry())
        {
            const form = $(this).closest('form');
            if (form)
            {
                e.preventDefault();

                const name = getEventNameForElement($(this), 'submit');

                let hasCallbackRun = false;
                mixpanel.track(name, telemetryAddDefaultProperties(), function(res)
                {
                    if (!hasCallbackRun)
                    {
                        hasCallbackRun = true;
                        form.submit();
                    }
                });

                setTimeout(() =>
                {
                    if (!hasCallbackRun)
                    {
                        console.warn('Telemetry timed out (form)');
                        hasCallbackRun = true;
                        form.submit();
                    }
                }, 2000);
            }
        }
    });

$(document).on('click', '[type="checkbox"]', function(e)
{
    if (initTelemetry())
    {
        const checked = $(this).is(':checked');
        let name = getEventNameForElement($(this), 'checkbox');
        name += '-' + (checked ? 'checked' : 'unchecked');
        telemetryTrackEvent(name, { checked: checked });
    }
});

$(document).on('click', '[type="radio"]', function(e)
{
    if (initTelemetry())
    {
        const checked = $(this).is(':checked');
        let name = getEventNameForElement($(this), 'radio');
        name += '-' + (checked ? 'selected' : 'deselected');
        telemetryTrackEvent(name, { checked: checked });
    }
});

$(document).on('click', '[type="reset"]', function(e)
{
    if (initTelemetry())
    {
        telemetryTrackEvent(getEventNameForElement($(this), 'reset'));
    }
});

$(document).on('click', '[type="button"]', function(e)
{
    if (initTelemetry())
    {
        // By default, button clicks are not intercepted. If clicking a button is going to lead to a redirect to another page
        // its on-click code will need to utilise telemetryTrackAndPerformUrlChange()

        const enableBtnTracking = $(this).attr('data-enable-btn-tracking');
        if (enableBtnTracking == null || enableBtnTracking == true)
        {
            telemetryTrackEvent(getEventNameForElement($(this), 'button'));
        }
    }
});

$(document).on('change', 'select', function(e)
{
    if (initTelemetry())
    {
        telemetryTrackEvent(getEventNameForElement($(this).find(':selected'), 'option'));
    }
});

$(document).on('click tap touch', '.js_track_mixpanel', function(e) // Try to only use where the above functions are not applicable
    {
        if (initTelemetry())
        {
            const eventName = getEventNameForElement($(this));
            if (eventName)
            {
                telemetryTrackEvent(eventName);
            }
        }
    });

export function telemetryTrackAndPerformUrlChange(link, eventName, eventProperties = {})
{
    if (initTelemetry())
    {
        let hasCallbackRun = false;
        mixpanel.track(eventName, telemetryAddDefaultProperties(eventProperties), function(res)
        {
            if (!hasCallbackRun)
            {
                hasCallbackRun = true;
                window.location.href = link;
            }
        });

        setTimeout(() =>
        {
            if (!hasCallbackRun)
            {
                console.warn('Telemetry timed out (function)');
                hasCallbackRun = true;
                window.location.href = link;
            }
        }, 2000);
    }
    else
    {
        window.location.href = link;
    }
}

export function telemetryTrackEvent(eventName, eventProperties = {})
{
    if (initTelemetry())
    {
        mixpanel.track(eventName, telemetryAddDefaultProperties(eventProperties));
    }
}

function processMixpanel()
{
    if (initTelemetry() && document.title && document.title !== 'Quitch')
    {
        if (window.location.hash)
        {
            processHashMixpanel(window.location.hash);
        }
        else
        {
            let title = document.title.replace(' - Quitch', '');
            telemetryTrackPageView(title, '', replaceClassUuid(window.location.href), replaceClassUuid(window.location.pathname));
        }
    }
}

function replaceClassUuid(text)
{
    let uuidRegex = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;
    return text.replace(uuidRegex, '{classId}');
}

function processHashMixpanel(hash)
{
    if (hash)
    {
        hash = hash.slice(1); // remove '#'
        let title = document.title.replace(' - Quitch', '');
        telemetryTrackPageView(`${title}`, `${hash}`, replaceClassUuid(window.location.href.split('#')[0]), replaceClassUuid(window.location.pathname));
    }
}

export function telemetryAddDefaultProperties(eventProperties = {})
{
    const userId = $('meta[name="userId"]').attr('content');
    const orgKey = $('meta[name="orgKey"]').attr('content');

    eventProperties['href'] = window.location.href;
    eventProperties['platform'] = 'ep';
    eventProperties['userShortId'] = userId ? userId : '';
    eventProperties['orgKey'] = orgKey ? orgKey : '';

    return eventProperties;
}

export function getEventNameForElement(ele, extraPrefix = '')
{
    let name = $(ele).attr('data-telemetry-name'); // Always use this first if it exists

    if (!name)
    {
        name = $(ele).attr('id'); // ID attribute
    }

    if (!name && $(ele).attr('name')) // Name attribute
    {
        name = $(ele).attr('name');
    }

    if (!name && $(ele).attr('title')) // Title attribute. Note: There may be an issue with translated titles being blank at this point
    {
        name = $(ele).attr('title');
    }

    if (!name && $(ele).attr('value')) // Value attribute
    {
        name = $(ele).attr('value');
    }

    if (!name && $(ele).text().length) // Inner text
    {
        name = $(ele).text().trim();
    }

    if (!name && $(ele).attr('href') && $(ele).attr('href') !== '#') // Link attribute
    {
        name = $(ele).attr('href');
        if (name)
        {
            const elems = name.split('/');
            name = elems[elems.length - 1];
            if (elems.length >= 2 && elems[elems.length - 2].length <= 20)
            {
                name = elems[elems.length - 2] + '-' + name;
            }
        }
    }

    if (name)
    {
        name = 'ep-' + extraPrefix + (extraPrefix !== '' ? '-' : '') + formatNameForTelemetry(name);
    }

    return name;
}

export function formatNameForTelemetry(name)
{
    return name.trim().replaceAll(' ', '-').replaceAll('_', '-').toLowerCase();
}

export function mixpanelEventCounter(eventName)
{
    mixpanel.people.increment(eventName);
}


function telemetryTrackPageView(title, subTitle, location, path)
{
    mixpanel.track('ep-page-view-' + formatNameForTelemetry(title) + (subTitle ? '-' : '') + formatNameForTelemetry(subTitle),
    {
        pageTitle: formatNameForTelemetry(title),
        tabTitle: formatNameForTelemetry(subTitle),
        pageLocation: location,
        pagePath: path
    });
}

// Send mixpanel page view when switching tabs
$(document).on('click', '.nav-link', function()
{
    if (initTelemetry() && document.title && document.title !== 'Quitch')
    {
        processHashMixpanel($(this).attr('data-target'));
    }
});

const snakeCase = string =>
{
    return string.replace(/\W+/g, '').split(/ |\B(?=[A-Z])/).map(word => word.toLowerCase()).join('_');
};