import { Quitch } from '../quitch';
import BalloonEditor from './ckeditor';

$(document).ready(function()
{
    initialiseEditor();
});

$(document).on('click', '.js-show-test-snackbar', function()
{
    Quitch.showSnackbar('Test Snackbar');
});

$(document).on('click', '.js-show-test-snackbar-dismiss', function()
{
    Quitch.showSnackbar('Test Snackbar 2', true);
});

$(document).on('click', '.js-show-test-modal-dialog', function()
{
    Quitch.modal(
    {
        message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        title: 'Modal dialog'
    });
});

$(document).on('click', '#show-add-img-modal-btn', function()
{
    $('#add-image-modal').modal('show');
});

function initialiseEditor()
{
    BalloonEditor
        .create(document.querySelector('.css-style-lib-balloon-editor.balloon-editor'))
        .then(editor =>
        {
            window.editor = editor;
        })
        .catch(err =>
        {
            console.error(handleSampleError(err));
        });
}

function handleSampleError(error)
{
    console.log('Balloon editor handleSampleError: ', error);
}