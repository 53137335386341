import { telemetryTrackAndPerformUrlChange, telemetryTrackEvent } from '../../superuser/mixpanel';
import { trans } from '../../common';

$(document).on('click', '.option-holder .option', function(e)
{
    const isDisabled = $(this).hasClass('disabled');
    if (!isDisabled)
    {
        $('.option-holder .option').removeClass('selected');
        $(this).addClass('selected');
        $('.continue-btn').prop('disabled', false);
    }

    telemetryTrackEvent('ep-class-edit-first-time-modal-select-option', { 'option': $(this).attr('data-type'), 'isDisabled': isDisabled });
});

$(document).on('click', '.continue-btn', function(e)
{
    $(this).prop('disabled', true);

    const type = $('.option-holder .option.selected').attr('data-type');

    if (type === 'upload')
    {
        $('#add-content-first-time-modal').modal('hide');
        const classId = $(this).attr('data-class-id');

        telemetryTrackAndPerformUrlChange(`/admin/class/${classId}/import`, 'ep-class-edit-first-time-modal-continue', { 'option': type });
    }
    else if (type === 'ai')
    {
        $('#add-content-first-time-modal').modal('hide');
        const classId = $('.js_class_details').data('class-id') || $('#js-class-id').val();
        $.ajax(
        {
            url: '/content/ai/modal',
            type: 'GET',
            dataType: 'json',
            data: { classId: classId },
            success: function(response)
            {
                $(response.view).modal({ cache: false, }, 'show').on('shown', function() {});
            },
        });

        telemetryTrackEvent('ep-class-edit-first-time-modal-continue', { 'option': type });
    }
    else
    {
        if (window.location.pathname.indexOf('/content-authoring') === 0)
        {
            $('#add-content-first-time-modal #first-time-body').hide();
            $('#add-content-first-time-modal .create-new-topic-body').show();

            $('input[name=topicName]').focus().val('').val(trans('content.myTopic'));
        }
        else
        {
            $('#add-content-first-time-modal').modal('hide');
        }

        telemetryTrackEvent('ep-class-edit-first-time-modal-continue', { 'option': type });
    }
});

export function showContentAuthoringFirstTimeModal()
{
    const forceShowContentModal = JSON.parse(window.localStorage.getItem('forceShowContentModal'));

    //TODO: Remove check for old content authoring once retired
    if (($('.content-authoring').length > 0 && $('.content-authoring-section').length === 0) ||
        ($('#class_content').length > 0 && $('.section-list-item').length === 0) || forceShowContentModal)
    {
        $('#add-content-first-time-modal').modal('show');
    }

    if (forceShowContentModal)
    {
        window.localStorage.setItem('forceShowContentModal', JSON.stringify(false));
    }
}

$(document).on('show.bs.modal', '#add-content-first-time-modal', function()
{
    if ($('.content-authoring-section').length === 0)
    {
        $('input[name="sectionName"]').val(trans('base.defaultSectionName'));
        $('.js-create-topic-btn').prop('disabled', false);
    }
});