import { Quitch } from '../quitch';
import { getSpinnerHtml } from '../../system';
import { trans } from '../common';

/**
 * Marketplace showcase page js
 */
$(document).on('click', '.js_marketplace-class-add-showcase', function(e)
{
    let classId = $(e.currentTarget).data('class-id');
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/marketplace/add-showcase/' + classId,
        type: 'POST',
        dataType: 'json',
        data: { _token: token },
        success: function(response)
        {
            let elem = $('.class-card-' + classId);
            elem.appendTo('.js-showcased-classes');
            elem.removeClass('js_marketplace-class-add-showcase');
            elem.addClass('js_marketplace-class-remove-showcase');
        },
    });
});

$(document).on('click', '.js_marketplace-class-remove-showcase', function(e)
{
    let classId = $(e.currentTarget).data('class-id');
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/manage/marketplace/remove-showcase/' + classId,
        type: 'POST',
        dataType: 'json',
        data: { _token: token },
        success: function(response)
        {
            let elem = $('.class-card-' + classId);
            elem.appendTo('.js-marketplace-classes');
            elem.removeClass('js_marketplace-class-remove-showcase');
            elem.addClass('js_marketplace-class-add-showcase');
        },
    });
});

$('.js-showcased-classes').sortable(
{
    distance: 5,
    pullPlaceholder: true,
    items: '.class-card',
    onDrop: function($item, container, _super)
    {
        _super($item, container);

        let token = $('meta[name="csrf-token"]').attr('content');
        let order = $('.js-showcased-classes')
            .sortable('serialize')
            .get()[0];
        $.ajax(
        {
            url: '/manage/marketplace/order-showcase',
            type: 'POST',
            dataType: 'json',
            data: { _token: token, order: order },
            success: function(response)
            {
                //not sure we need to do anything here
            },
        });
    },
});


/**
 * Marketplace transaction history page js
 */
$(document).on('change', '.js-marketplace-transaction-user-filter', function(e)
{
    let target = $(e.currentTarget);
    let input = $('.js-transactions-user-id-input');
    if (target[0].checked)
    {
        input.val(input.data('user-id'));
    }
    else
    {
        input.val('');
    }
});

$(document).on('click', '.js-transaction-history', function(e)
{
    e.preventDefault();

    let target = $(e.currentTarget.closest('tr'));
    let transactionId = target.data('transaction-id');

    let loadingSpinner = $('.loading-spinner');
    loadingSpinner.html(getSpinnerHtml());
    loadingSpinner.show();

    $('#transaction-history-modal .modal-body').hide();
    $('#transaction-history-modal').modal('toggle');

    $.ajax(
    {
        url: '/manage/marketplace/transaction/' + transactionId + '/history',
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            loadingSpinner.hide();
            let historyRowsHtml = '';
            $.each(response.transactionHistory, function(index, row)
            {
                historyRowsHtml += `<tr>
                                            <td>${row.id}</td>
                                            <td>${row.status}</td>
                                            <td>${row.updatedAt}</td>
                                        </tr>`;
            });
            $('.device-uuid').text(target.data('device-uuid'));
            $('.device-uuid').attr('title', target.data('device-uuid'));
            $('.store-type').text(target.data('store-type') || '-');
            $('.store-id').text(target.data('store-transaction-id') || '-');
            $('#transaction-history-modal .modal-body table tbody').html(historyRowsHtml);
            $('#transaction-history-modal .modal-body').show();
        },
        error: function(e, t, s)
        {
            Quitch.showErrors(e.message);
        },
    });
});

$(document).on('click', '.js-transaction-tools', function(e)
{
    e.preventDefault();

    let target = $(e.currentTarget.closest('tr'));
    let transactionId = target.data('transaction-id');
    $('#transaction-tools-modal').data('transaction-id', transactionId);

    $('#transaction-tools-modal .device-uuid').text(target.data('device-uuid'));
    $('#transaction-tools-modal .device-uuid').attr('title', target.data('device-uuid'));
    $('#transaction-tools-modal .user-email').text(target.data('user-email'));
    $('#transaction-tools-modal .store-id').text(target.data('store-transaction-id'));
    $('#transaction-tools-modal').modal('toggle');
});

$(document).on('click', '.js-create-transaction, .js-update-transaction, .js-delete-transaction', function(e)
{
    e.preventDefault();

    let target = $(e.currentTarget);
    let values = { _token: $('meta[name="csrf-token"]').attr('content'), transactionId: $('#transaction-tools-modal').data('transaction-id') };
    let url = '';
    if (target.hasClass('js-create-transaction'))
    {
        url = '/manage/marketplace/transaction/create';
        values['classInstanceId'] = $('.js-select-class').val();
    }
    else if (target.hasClass('js-update-transaction'))
    {
        url = '/manage/marketplace/transaction/update';
        values['status'] = $('.js-select-status').val();
    }
    else if (target.hasClass('js-delete-transaction'))
    {
        if (confirm('Are you sure you want to delete this transaction?'))
        {
            url = '/manage/marketplace/transaction/delete';
        }
        else
        {
            return;
        }
    }
    else
    {
        return;
    }

    $.ajax(
    {
        url: url,
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('#transaction-tools-modal').modal('toggle');
            Quitch.showSnackbar(response.message);
            window.location.reload();
        },
        error: function(e, t, s)
        {
            $('#transaction-tools-modal').modal('toggle');
            Quitch.showSnackbar(e.responseJSON.message);
        },
    });
});

$(document).on('click', '#js-update-wix-marketplace', function(e)
{
    e.preventDefault();
    if ($('html').hasClass('production'))
    {
        updateWixMarketplace();
    }
    else
    {
        Quitch.modal(
        {
            title: 'Update Wix Marketplace',
            message: '<p class="wrap">This will replace all classes on Wix with the classes in this environment. Are you certain you want to do this? 🗿</p>',
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered',
            okText: 'Ship it 🚢',
            cancelText: 'Turn back',
        }, function(response)
        {
            if (response === true)
            {
                updateWixMarketplace();
            }
        });
    }
});

function updateWixMarketplace()
{
    const btn = $('#js-update-wix-marketplace');
    let values = { _token: $('meta[name="csrf-token"]').attr('content') };
    if (btn.hasClass('loading'))
    {
        return;
    }
    else
    {
        btn.addClass('loading');
    }
    btn.find('.complete').hide();
    btn.find('.loader').show();

    $.ajax(
    {
        url: '/manage/marketplace/update-wix-marketplace',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            btn.removeClass('loading');
            btn.find('.loader').hide();
            btn.find('.complete').show();

            Quitch.showSnackbar(response.message);
        },
        error: function(e, t, s)
        {
            btn.removeClass('loading');
            btn.find('.loader').hide();
            Quitch.showSnackbar(e.responseJSON.message);
        },
    });
}