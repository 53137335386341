import { trans, transChoice } from '../../common';
import { selectContent } from './common';
import { addQuestion } from './index';
import { Quitch } from '../../quitch';
import { telemetryTrackEvent } from '../../superuser/mixpanel';
import { loadPreview } from './preview';

$(document).on('change', '.content-authoring-content .batch-checkbox input', function()
{
    const content = $(this).parents('.content-authoring-content');
    if ($(this).is(':checked'))
    {
        content.addClass('highlighted');
    }
    else
    {
        content.removeClass('highlighted');
    }

    // if more than one batch-checkbox is checked, show batch actions
    const checkedCount = $('.content-authoring-content .batch-checkbox input:checked').length;
    if (checkedCount > 0)
    {
        $('.batch-actions-toolbar .number-selected').text(trans('content.selected', { count: checkedCount }));
        $('.content-authoring-content .toolbar-btn').prop('disabled', true);
        if ($('.content-authoring-list .content-authoring-topic').length > 1)
        {
            $('.batch-actions-toolbar .move-items-btn').prop('disabled', false);
        }
        else
        {
            $('.batch-actions-toolbar .move-items-btn').prop('disabled', true);
        }
        $('.js-batch-select-all-items').addClass('disabled');
        content.parents('.content-authoring-topic').find('.js-batch-select-all-items').removeClass('disabled');
        $('.batch-actions-toolbar').addClass('show');
    }
    else
    {
        $('.content-authoring-content .toolbar-btn').prop('disabled', false);
        $('.batch-actions-toolbar').removeClass('show');
        $('.js-batch-select-all-items').removeClass('disabled');
    }
});

$(document).on('click', '.batch-actions-toolbar .close-btn', function(e)
{
    closeBatchActionsToolbar();
});

$(document).on('click', '.batch-actions-toolbar .move-items-btn', function(e)
{
    const selectedContent = $('.content-authoring-content .batch-checkbox input:checked').parents('.content-authoring-content');
    const selectedTopic = selectedContent.parents('.content-authoring-topic');
    const selectedTopicId = selectedTopic.attr('data-id');
    const selectedTopicContentIds = selectedContent.map(function()
    {
        return $(this).attr('data-topic-content-id');
    }).get();

    $('#move-modal .number-items').text(transChoice('content.itemsToMove', selectedTopicContentIds.length, { count: selectedTopicContentIds.length }));
    $('#move-modal-done-btn').attr('data-topic-content-ids', selectedTopicContentIds.join(','));

    const topicDropdownSelect = $('#move-modal .topic-dropdown-select');
    topicDropdownSelect.find('.inner-scroll').empty();
    $('.content-authoring-list .content-authoring-topic').each(function()
    {
        const topicId = $(this).attr('data-id');
        if (topicId !== selectedTopicId)
        {
            const topicTitle = $(this).find('.content-authoring-topic-heading .content-title .input-field').text();
            const topicOption = `<div class="dropdown-item" data-target="${topicId}">${topicTitle}</div>`;
            topicDropdownSelect.find('.inner-scroll').append(topicOption);
        }
    });

    topicDropdownSelect.find('.dropdown-item').first().click();
    $('#move-modal-done-btn').prop('disabled', false);
    $('#move-modal').modal('show');
});

$(document).on('click', '#move-modal-done-btn', function(e)
{
    $(this).prop('disabled', true);
    closeBatchActionsToolbar();

    const selectedTopicContentIds = $(this).attr('data-topic-content-ids').split(',');
    const selectedTopicId = $('#move-modal [name=moveToTopicSelection]').val();
    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        targetTopicId: selectedTopicId,
        topicContentIds: selectedTopicContentIds,
    };

    $.ajax(
    {
        url: '/content/authoring/batch/move',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            Quitch.showSnackbar(transChoice('content.itemsMoved', response.moved.length, { count: response.moved.length }));
            const destTopic = $(`.content-authoring-topic[data-id="${selectedTopicId}"]`);
            const topicFooter = destTopic.find('.content-authoring-topic-footer');
            response.moved.forEach(function(topicContentId)
            {
                const content = $(`.content-authoring-content[data-topic-content-id="${topicContentId}"]`);
                // move content html to bottom topic of destination topic
                content.insertBefore(topicFooter);
            });

            const origTopic = $('.content-authoring-topic.expanded');
            if (origTopic.find('.content-authoring-content').length > 0)
            {
                selectContent(origTopic.find('.content-authoring-content').first());
            }
            else
            {
                addQuestion($('[name=classId]').val(), origTopic.attr('data-id'));
            }

            $('#move-modal').modal('hide');

            telemetryTrackEvent('ep-batch-moved-content', { count: response.moved.length });
        },
    });
});

$(document).on('click', '.batch-actions-toolbar .delete-items-btn', function(e)
{
    const selectedContent = $('.content-authoring-content .batch-checkbox input:checked').parents('.content-authoring-content');
    const includesSentContent = selectedContent.toArray().some((el) => $(el).attr('data-schedule-status') === 'sent');

    Quitch.modal(
        {
            title: transChoice(includesSentContent ? `content.deleteItemsResultsTitle` : `content.deleteItemsTitle`, selectedContent.length, { count: selectedContent.length }),
            message: trans(includesSentContent ? 'content.deleteContentResultsDesc' : `content.deleteItemsDesc`),
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered modal-dialog-sm',
            okText: includesSentContent ? 'settings.yesDelete' : 'content.deleteBtn',
            okBtnCss: includesSentContent ? 'btn-danger' : 'btn-secondary',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                closeBatchActionsToolbar();

                const selectedQuestionIds = selectedContent.filter('.question').map(function()
                {
                    return $(this).attr('data-content-id');
                }).get();

                const selectedResourceIds = selectedContent.filter('.resource').map(function()
                {
                    return $(this).attr('data-content-id');
                }).get();

                const values = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    questionIds: selectedQuestionIds,
                    resourceIds: selectedResourceIds,
                    classId: $('[name=classId]').val(),
                };

                $.ajax(
                {
                    url: '/content/authoring/batch/delete',
                    type: 'POST',
                    dataType: 'json',
                    data: values,
                    success: function(response)
                    {
                        Quitch.showSnackbar(transChoice('content.itemsDeleted', response.deleted.length, { count: response.deleted.length }));
                        response.deleted.forEach(function(contentId)
                        {
                            $(`.content-authoring-content[data-content-id="${contentId}"]`).remove();
                        });

                        if (selectedContent.length > 0)
                        {
                            const origTopic = $('.content-authoring-topic.expanded');
                            if (origTopic.find('.content-authoring-content').length > 0)
                            {
                                selectContent(origTopic.find('.content-authoring-content').first());
                            }
                            else
                            {
                                loadPreview();
                                origTopic.find('.content-authoring-topic-footer').addClass('empty-topic');
                            }
                        }

                        telemetryTrackEvent('ep-batch-deleted-content', { count: response.deleted.length });
                    },
                });
            }
        });
});

$(document).on('click', '.content-authoring-topic-heading .js-batch-select-all-items', function(e)
{
    const topic = $(this).parents('.content-authoring-topic');
    topic.find('.content-authoring-content .batch-checkbox input').prop('checked', true).change();
});

export function closeBatchActionsToolbar()
{
    $('.batch-actions-toolbar').removeClass('show');
    $('.content-authoring-content').removeClass('highlighted');
    $('.content-authoring-content .batch-checkbox input').prop('checked', false);
    $('.js-batch-select-all-items').removeClass('disabled');
    $('.content-authoring-content .toolbar-btn').prop('disabled', false);
}