import { Quitch } from '../../quitch';
import { trans } from '../../common';
import { telemetryTrackEvent } from '../../superuser/mixpanel';
import { expandTopic, scrollToContent, validateAllContent } from './common';
import { loadPreview } from './preview';
import { addQuestion } from './index';

export function updateMoveToTopicList(currTopicId)
{
    const topics = $('.content-authoring-topic');
    const moveToTopics = $('.move-to-topics');
    moveToTopics.html('');

    topics.each((idx, topic) =>
    {
        const topicId = $(topic).data('id');
        if (topicId !== currTopicId)
        {
            const topicName = $(topic).find('.js-edit-section-topic-name').data('current-val');

            const html = `<div class="dropdown-item js-move-content-to-topic" data-topic-id="${topicId}">${topicName}</div>`;
            moveToTopics.append(html);
        }
    });
}

export function updateMoveToSectionList(currSectionId)
{
    const sections = $('.content-authoring-section');
    const moveToSections = $('.move-to-sections');
    moveToSections.html('');

    sections.each((idx, section) =>
    {
        const sectionId = $(section).data('id');
        if (sectionId !== currSectionId)
        {
            const sectionName = $(section).find('.js-edit-section-topic-name').data('current-val');

            const html = `<div class="dropdown-item js-move-topic-to-section" data-section-id="${sectionId}">${sectionName}</div>`;
            moveToSections.append(html);
        }
    });
}

$(document).on('submenu:shown', '.move-to-dropdown', function(e)
{
    const parentTopic = $(this).closest('.content-authoring-topic');
    if (parentTopic.length)
    {
        updateMoveToTopicList(parentTopic.data('id'));

        const parentSection = parentTopic.closest('.content-authoring-section');
        if (parentSection.length)
        {
            updateMoveToSectionList(parentSection.data('id'));
        }
    }
});

$(document).on('click', '.js-move-content', function(e)
{
    const direction = $(this).data('direction');
    const parentContent = $(this).closest('.content-authoring-content');
    const parentContentType = parentContent.hasClass('question') ? 'question' : 'resource';
    const parentContentId = parentContent.data('content-id');
    const parentTopic = $(this).closest('.content-authoring-topic');
    const parentTopicId = parentTopic.data('id');
    const allTopicContent = parentTopic.find('.content-authoring-content');

    let parentContentIdx = -1;
    let result = [];

    allTopicContent.each((idx, item) =>
    {
        const itemContentId = $(item).data('content-id');
        const itemTopicContentId = $(item).data('topic-content-id');
        if (itemContentId == parentContentId)
        {
            parentContentIdx = idx;
        }

        result.push({ 'orderDnd': idx, 'sectionId': parentTopicId, 'topicContentId': itemTopicContentId });
    });

    const fromIndex = parentContentIdx;
    let toIndex = parentContentIdx;
    switch (direction)
    {
        case 'up':
            toIndex = parentContentIdx - 1;
            break;
        case 'down':
            toIndex = parentContentIdx + 1;
            break;
        case 'top':
            toIndex = 0;
            break;
        case 'bottom':
            toIndex = allTopicContent.length - 1;
            break;
    }

    const element = result[fromIndex];
    result.splice(fromIndex, 1);
    result.splice(toIndex, 0, element);
    result = JSON.stringify({ 'items': result });

    switch (direction)
    {
        case 'up':
            if (parentContent.prev().length && parentContent.prev().hasClass('content-authoring-content'))
            {
                parentContent.prev().before(parentContent);
            }
            break;
        case 'down':
            if (parentContent.next().length && parentContent.next().hasClass('content-authoring-content'))
            {
                parentContent.next().after(parentContent);
            }
            break;
        case 'top':
            if (parentContent.siblings('.content-authoring-content').first().length)
            {
                parentContent.siblings('.content-authoring-content').first().before(parentContent);
            }
            break;
        case 'bottom':
            if (parentContent.siblings('.content-authoring-content').last().length)
            {
                parentContent.siblings('.content-authoring-content').last().after(parentContent);
            }
            break;
    }

    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        content: result
    };

    $.ajax(
    {
        url: '/admin/class/content/reorder',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            if (parentContentType === 'question')
            {
                Quitch.showSnackbar(trans('content.reorderedQuestion'));
            }
            else if (parentContentType === 'resource')
            {
                Quitch.showSnackbar(trans('content.reorderedResource'));
            }
        },
        error: function(xhr, status, error)
        {
            if (parentContentType === 'question')
            {
                Quitch.showSnackbar(trans('content.errorReorderingQuestion'));
                console.log('Reorder question error', [error]);
            }
            else if (parentContentType === 'resource')
            {
                Quitch.showSnackbar(trans('content.errorReorderingResource'));
                console.log('Reorder resource error', [error]);
            }
        }
    });

});

$(document).on('click', '.js-move-topic, .js-move-section', function(e)
{
    const isTopic = $(this).hasClass('js-move-topic');

    const direction = $(this).data('direction');
    const parentElement = $(this).closest(isTopic ? '.content-authoring-topic' : '.content-authoring-section');
    const parentElementId = parentElement.data('id');
    const allElements = isTopic ? parentElement.closest('.content-authoring-section').find('.content-authoring-topic') : $('.content-authoring-section');

    let parentElementIdx = -1;
    let result = [];

    allElements.each((idx, item) =>
    {
        const itemId = $(item).data('id');
        if (itemId == parentElementId)
        {
            parentElementIdx = idx;
        }

        result.push({ 'itemId': itemId });
    });

    const fromIndex = parentElementIdx;
    let toIndex = parentElementIdx;
    switch (direction)
    {
        case 'up':
            toIndex = parentElementIdx - 1;
            break;
        case 'down':
            toIndex = parentElementIdx + 1;
            break;
        case 'top':
            toIndex = 0;
            break;
        case 'bottom':
            toIndex = allElements.length - 1;
            break;
    }

    const element = result[fromIndex];
    result.splice(fromIndex, 1);
    result.splice(toIndex, 0, element);
    result = JSON.stringify({ 'items': result });

    const elemClass = isTopic ? 'content-authoring-topic' : 'content-authoring-section';
    switch (direction)
    {
        case 'up':
            if (parentElement.prev().length && parentElement.prev().hasClass(elemClass))
            {
                parentElement.prev().before(parentElement);
            }
            break;
        case 'down':
            if (parentElement.next().length && parentElement.next().hasClass(elemClass))
            {
                parentElement.next().after(parentElement);
            }
            break;
        case 'top':
            if (parentElement.siblings(`.${elemClass}`).first().length)
            {
                parentElement.siblings(`.${elemClass}`).first().before(parentElement);
            }
            break;
        case 'bottom':
            if (parentElement.siblings(`.${elemClass}`).last().length)
            {
                parentElement.siblings(`.${elemClass}`).last().after(parentElement);
            }
            break;
    }

    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classId: $('[name=classId]').val(),
        order: result
    };

    $.ajax(
    {
        url: '/content/authoring/topic-section/order',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            Quitch.showSnackbar(trans(isTopic ? 'content.reorderedTopic' : 'content.reorderedSection'));
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(trans(isTopic ? 'content.errorReorderingTopic' : 'content.errorReorderingSection'));
        }
    });

});

$(document).on('click', '.js-move-content-to-topic', function(e)
{
    const parentContent = $(this).closest('.content-authoring-content');
    const parentTopicContentId = parentContent.data('topic-content-id');
    const parentContentType = parentContent.hasClass('question') ? 'question' : 'resource';
    const origTopic = $(this).closest('.content-authoring-topic');
    const origTopicId = origTopic.data('id');
    const destTopicId = $(this).data('topic-id');
    const destTopic = $(`.content-authoring-topic[data-id="${destTopicId}"]`);
    const destTopicName = destTopic.find('.js-edit-section-topic-name').data('current-val');
    const origContentElem = $(`.content-authoring-content[data-topic-content-id="${parentTopicContentId}"]`);
    const topicFooter = destTopic.find('.content-authoring-topic-footer');

    origContentElem.insertBefore(topicFooter); // Move the content

    const origTopicContentCount = origTopic.find('.content-authoring-content').length;
    if (origTopicContentCount > 0)
    {
        expandTopic(destTopic, scrollToContent.bind(null, origContentElem, 400, 100));
    }
    else
    {
        addQuestion($('[name=classId]').val(), origTopicId);
    }

    validateAllContent();
    loadPreview();

    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        topicContentId: parentTopicContentId,
        destTopicId: destTopicId,
        appendToBottom: true,
    };

    $.ajax(
    {
        url: '/admin/class/content/move',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            Quitch.showSnackbar(trans(`content.${parentContentType}MovedToTopic`, { topic: destTopicName }));
            telemetryTrackEvent('contentAuthoring-movedContentToTopic', { 'status': 'success' });
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(trans('content.failedMoveContentToTopic'));
            telemetryTrackEvent('contentAuthoring-movedContentToTopic', { 'status': 'failed', 'debug': xhr.responseJSON.message, 'error': error });
        },
    });

});

$(document).on('click', '.js-move-topic-to-section', function(e)
{
    const origTopic = $(this).closest('.content-authoring-topic');
    const origTopicId = origTopic.data('id');
    const destSectionId = $(this).data('section-id');
    const destSection = $(`.content-authoring-section[data-id="${destSectionId}"]`);
    const destSectionBody = destSection.find('.content-authoring-section-body');
    const classId = $('[name=classId]').val();

    destSectionBody.append(origTopic); // Move the topic

    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        classId: classId,
        topicId: origTopicId,
        destSectionId: destSectionId,
    };

    $.ajax(
    {
        url: '/content/authoring/topic/move',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            Quitch.showSnackbar(response.message);
            telemetryTrackEvent('contentAuthoring-movedTopicToSection', { 'status': 'success' });
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(xhr.responseJSON.message);
            telemetryTrackEvent('contentAuthoring-movedTopicToSection', { 'status': 'failed', 'debug': xhr.responseJSON.message, 'error': error });
        },
    });

});