import { Quitch } from '../../quitch';
import { confirmI18n, isClassArchived, trans } from '../../common';
import { updateClassPublishStatus } from './index';
import { mixpanelEventCounter, telemetryTrackEvent } from '../../superuser/mixpanel';

$(document).ready(function()
{
    initialiseSection();
});

/**
 * Sections Hide Show content
 */
$(document).on('click tap touch', '.js_section-content-display', function(e)
{
    e.stopPropagation();
    $('.js_content-list').hide();
    $('.js_section-placeholder').remove();
    $('.js_section-content-display').removeClass('active');

    let el = $(this);
    el.addClass('active');
    let target = el.data('rel');

    //Disable the add topic button
    $('.js_section-create[data-type=topic]').addClass('inactive-topic');
    $('.js_section-create[data-type=topic]').attr('title', trans('content.newTopicTooltip'));
    if ($(target).length)
    {
        if ($(target).is(':hidden'))
        {
            $(target).show();
            $(target).find('ol.nested-list').show();
        }
        else
        {
            $(target).hide();
            $(target).find('ol.nested-list').hide();
        }
    }
    setupSection();
    if (el.hasClass('accordion-parent'))
    {
        $('.js_section-create[data-type=topic]').removeClass('inactive-topic');
        $('.js_section-create[data-type=topic]').attr('title', '');
    }
});

/**
 * Create section form
 */
$(document).on('click', '.js_section-create', e =>
{
    e.preventDefault();

    if (e.currentTarget.classList.contains('inactive-topic'))
    {
        return;
    }

    $('.js_content-list').hide();

    // reset existing placeholders
    $('.js_section-placeholder').remove();
    const sectionType = $(e.target).data('type');
    const $createSection = $('.js_create-new-sections');
    $createSection
        .find('input')
        .val('')
        .data('type', sectionType);

    $('.js_section-create[data-type=topic]').addClass('inactive-topic');
    $('.js_section-create[data-type=topic]').attr('title', trans('content.newTopicTooltip'));

    if ('section' === sectionType)
    {
        // creating a new section for class
        $('.js_section-content-display').removeClass('active');
        $createSection.find('h4.title').text(trans('content.sectionCreate'));
        $createSection.find('input').attr('placeholder', trans('content.sectionName'));

        const placeholder = `<li class="accordion-parent section-list-item active js_section-placeholder" data-type="section" data-rel=".js_create-new-sections" >
                                <div class="row section-area topic-select-btn">
                                    <i class="fa fa-ellipsis-v icon-colore" style="display: none"></i>
                                    <h4 class="section-title">${trans('content.sectionTitle')}</h4>
                                    <div class="right">
                                       <i class="fa fa-times delete add-margin-top-left st-del-btn" aria-hidden="true" style="display: none"></i>
                                    </div>
                                </div>
                                <ol class="accordion-child"></ol>
                            </li>`;
        $(placeholder).insertBefore('.section-form-position');
    }
    else
    {
        // creating a new topic for a section
        const $topicParent = $('.section-list-item.active');
        if ($topicParent.length === 0)
        {
            disableNewQuestionResourceBtn();
            return;
        }
        $('.js_section-content-display').removeClass('active');
        $createSection.find('h4.title').text(trans('content.topicCreate'));
        $createSection.find('input').attr('placeholder', trans('content.topicName'));
        const topicPlaceholder = `<li class="accordion-block section-list-item active js_section-placeholder" data-type="topic" data-rel=".js_create-new-sections">
                                            <div class="row topic">
                                                <div class="row">
                                                    <div class="section-row">
                                                        <i class="fa fa-ellipsis-v icon-colore" style="display: none"></i>
                                                        <h4 class="topic-title">${trans('content.topicsTitle')}</h4>
                                                    </div>
                                                </div>
                                                <i class="fa fa-times delete add-margin-top-left delete-topic-btn" aria-hidden="true" style="display: none"></i>
                                            </div>
                                       </li>`;
        $topicParent.append(topicPlaceholder);
    }

    // show section/topic create item, focus input and scroll into view
    $createSection.show(() =>
    {
        if ($('.js_section-placeholder').length)
        {
            $createSection.find('input').focus();
            $('.js_section-placeholder')[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    });
});

$(document).on('input', '.js_create-section-input', e =>
{
    const sectionType = $(e.target).data('type');
    if ('section' === sectionType)
    {
        // update new section name as we type
        $('.js_section-placeholder h4.section-title').text(trans('content.sectionTitle') + ' ' + $(e.target).val());
    }
    else
    {
        // update new topic name as we type
        $('.js_section-placeholder h4.topic-title').text(trans('content.topicsTitle') + ' ' + $(e.target).val());
    }
});

$(document).on('keypress', '.js_create-section-input', e =>
{
    if (e.which === 13)
    {
        $('.js_create-section').click();
    }
});

/**
 * Submit create topic/section form
 */
$(document).on('click', '.js_create-section', function()
{
    const $input = $('.js_create-new-sections input');
    const sectionType = $input.data('type');

    // create section payload
    const values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['class_id'] = $('.js_class_details').data('class-id');
    values['section_name'] = $input.val();

    // if creating a topic add topic details (parent id and order)
    if ('topic' === $input.data('type'))
    {
        const $topicParent = $('.js_section-placeholder').parent();
        values['order_dnd'] = $topicParent.find('ol').children('li').length;
        values['parent_id'] = $topicParent.data('section');
    }

    if ($input.is(':disabled'))
    {
        return;
    }
    else
    {
        //disable input before submit
        $input.prop('disabled', true);
    }

    $.ajax(
    {
        url: '/section',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: response =>
        {
            if (response.status === '200' || response.status === 200)
            {
                if ('section' === sectionType)
                {
                    mixpanelEventCounter('sectionCreated');
                    Quitch.showMessage('success', 'base.alertSectionCreated');
                }
                else
                {
                    mixpanelEventCounter('topicCreated');
                    Quitch.showMessage('success', 'base.alertTopicSaved');
                }

                // update publish and reload page
                updateClassPublishStatus('modified');
                Quitch.updateUrl({ section: response.section.id }, 'ep-create-section-or-topic-success', { 'sectionType': sectionType ? sectionType.toString() : 'unknown' });
            }
            else
            {
                if (response.message && response.message.length > 0)
                {
                    Quitch.showMessage('danger', response.message);
                }
                else
                {
                    Quitch.showMessage('danger', 'base.alertSectionNotCreated');
                }
                // enable input on error
                $input.prop('disabled', false);

                telemetryTrackEvent('ep-create-section-or-topic-error');
            }
        },
    });
});

$(document).on('click', '.st-del-btn', function()
{
    if (confirmI18n('content.deleteSectionText') === false) return false;
    if (confirmI18n('content.deleteAllContent') === false) return false;

    const id = $(this)
        .closest('div')
        .attr('section-id');
    const delete_section = $(this);
    $(`.js_content-list.js_content-for-${id}`).remove();

    $.ajax(
    {
        url: '/section/delete/' + id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                const sectionEle = delete_section
                    .parentsUntil('.keep-active-btn')
                    .parent('li');

                sectionEle.find('.js-topic-list-item').each(function()
                {
                    let topicId = $(this).attr('topic-id');
                    $(`.js_content-list.js_content-for-${topicId}`).remove();
                });
                sectionEle.remove();

                $('.instruction-assesment-list').empty();
                Quitch.showMessage('success', response.message);
                updateClassPublishStatus('modified');

                $('.js_section-create[data-type=topic]').addClass('inactive-topic');
                $('.js_section-create[data-type=topic]').attr('title', trans('content.newTopicTooltip'));

                updateEmptyState();
            }
            else
            {
                Quitch.showMessage('danger', response.message);
            }
            window.scrollTo(0, 0);
        },
    });
});

$(document).on('click', '.delete-topic-btn', function(event)
{
    event.stopPropagation();
    if (confirmI18n('content.deleteText') === false) return false;
    if (confirmI18n('content.deleteQAndAText') === false) return false;

    const id = $(this).attr('topic-id-del');
    const delTopic = $(this).parentsUntil('.accordion-child');
    const delTopicItem = $(this).parentsUntil('.section-list-item').parent('li');

    $.ajax(
    {
        url: '/section/delete/' + id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                if (delTopicItem.hasClass('active')) // Check if it's the selected topic that was deleted
                {
                    disableNewQuestionResourceBtn();
                    $('.js_content-list').hide();
                }

                delTopic.remove();
                $('.js_content-for-' + id).remove();

                Quitch.showMessage('success', 'base.alertTopicDelete');
                updateClassPublishStatus('modified');
                updateEmptyState();
            }
            else
            {
                Quitch.showMessage('danger', 'base.alertTopicDeleteFailed');
            }
            window.scrollTo(0, 0);
        },
    });
});

$(document).on('focusout', '.js_topic-name-edit', function(e)
{
    updateTopicOnFocusLoss(e);
});

$(document).on('focusout', '.js_section-name-edit', function(e)
{
    updateTopicOnFocusLoss(e);
});

$(document).on('input', '.js_topic-name-edit', function(e)
{
    $('.section-list-item.active .section-row')
        .attr('topic-id', $(e.target).data('section-id'))
        .children('h4')
        .html(trans('base.topicNameEdit', { name: $(e.target).val() }));
});

$(document).on('input', '.js_section-name-edit', function(e)
{
    $('.section-list-item.active .section-area')
        .children('h4')
        .html(trans('base.sectionNameEdit', { name: $(e.target).val() }));
});

/**
 * Responsible for showing and scrolling to a section
 */
function initialiseSection(sectionId = false)
{
    sectionId = sectionId ? sectionId : window.loadContentSection || false;
    if (sectionId && sectionId.length > 0)
    {
        const $sectionEl = $(`[data-section="${sectionId}"]`);
        if ($sectionEl.length)
        {
            $sectionEl.trigger('click'); // triggers: js_section-content-display click event
            $sectionEl[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    updateEmptyState();
}

export function updateEmptyState()
{
    $('.js-ca-step-1').toggle($('.js_section-content-display[data-type=section]').length === 0);
    $('.js-ca-step-2').toggle($('.js_section-content-display[data-type=topic]').length === 0);
    $('.js-ca-step-3').toggle($('.js_content-list-item').length === 0);
}

function setupSection()
{
    let activeSection = $('.js_section-content-display.active');
    if (!activeSection.length)
    {
        return;
    }
    // lock buttons if clicking on section not topic
    // todo: remove this if we want to allow questions to be created under sections
    let type = activeSection.data('type');
    if (isClassArchived() || type === 'section')
    {
        disableNewQuestionResourceBtn();
    }
    else
    {
        enableNewQuestionResourceBtn();
    }
}

function enableNewQuestionResourceBtn()
{
    $('.new-question-resource-btn').attr('style', 'display:inline-block');
    $('.new-question-resource-btn-disabled').attr('style', 'display:none');
}

function disableNewQuestionResourceBtn()
{
    $('.new-question-resource-btn').attr('style', 'display:none');
    $('.new-question-resource-btn-disabled').attr('style', 'display:inline-block');
}

function updateTopicOnFocusLoss(e)
{
    e.preventDefault();
    let values = {};
    values['id'] = $(e.target).data('section-id');
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['section_name'] = $(e.target).val();

    const originalVal = $(e.target).data('original');
    if (originalVal === values['section_name'])
    {
        return;
    }
    $.ajax(
    {
        url: '/section/' + values['id'],
        type: 'PUT',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                Quitch.showMessage('success', response.message);
                updateClassPublishStatus('modified');
            }
            else
            {
                Quitch.showMessage('danger', response.message);
            }
            $(e.target)
                .data('original', response.sectionName)
                .val(response.sectionName);
        },
    });
}