import { toggleScheduleBtn } from './scheduling';
import { isClassArchived, trans } from '../../common';
import { Quitch } from '../../quitch';
import { tryShowFeedbackModal } from '../feedback-modal';
import { telemetryTrackAndPerformUrlChange } from '../../superuser/mixpanel';

$(document).ready(function()
{
    updateClassPublishStatus();
    disableContentEditing();
    showFeedbackModal();
});

$(document).on('click', '.js-check-publish-exit', function(e)
{
    e.preventDefault();

    let publishMessage = $('.js_publishIcon');

    const target = e.currentTarget;

    if (publishMessage.hasClass('modified'))
    {
        Quitch.modal(
            {
                title: trans('content.publishAlertBtn'),
                message: trans('content.notPublishedBackMessage'),
                type: 'confirm_custom',
                okText: 'base.yesPublish',
                cancelText: 'base.later',
            },
            function(response)
            {
                if (response === true)
                {
                    $('.js-publish-btn').click();
                }
                else
                {
                    telemetryTrackAndPerformUrlChange(target.href, 'ep-check-publish-exit-no');
                }
            }
        );
    }
    else
    {
        telemetryTrackAndPerformUrlChange(target.href, 'ep-content-edit-back');
    }
});

export function updateClassPublishStatus(newStatus)
{
    if (isClassArchived()) return; // do not show publish indicator on archived classes

    const $classDetails = $('.js_class_details');
    if ($classDetails.length === 1)
    {
        if (newStatus)
        {
            $classDetails.data('published', newStatus);
            $('.js_notifications').hide();
        }
        $('.js_publishIcon').removeClass('published unpublished modified btn-primary btn-secondary');
        $('.js_publishIcon i').removeClass('fa-check fa-cloud-upload');

        const isModified = $classDetails.data('published') === 'modified';
        const isUnpublished = $classDetails.data('published') === 'unpublished';

        if (isModified)
        {
            $('.js_publishIcon').addClass('modified btn-primary');
            $('.js_publishIcon i').addClass('fa-cloud-upload');
        }
        else if (isUnpublished)
        {
            $('.js_publishIcon').addClass('unpublished btn-primary');
            $('.js_publishIcon i').addClass('fa-cloud-upload');
        }
        else
        {
            $('.js_publishIcon').addClass('published btn-secondary');
            $('.js_publishIcon i').addClass('fa-check');
        }

        toggleScheduleBtn();
    }
    else if ($classDetails.length > 1)
    {
        throw 'found multiple class details: aborting';
    }
}

function disableContentEditing()
{
    if (isClassArchived())
    {
        $(
            '.js-edit-question-btn, .js-edit-instruction-btn, .active-resource-question, .show-btn, .js_section-name-edit, .js_topic-name-edit, .js_update-topic-btn, .js_new-content-dropdown, .js_schedule-btn'
        ).prop('disabled', true);
    }
}

function showFeedbackModal()
{
    const urlParams = new URLSearchParams(window.location.search);
    const feedback = urlParams.get('feedback');
    if (feedback === 'createWithAi')
    {
        tryShowFeedbackModal('Create with AI', trans('feedback.howSatisfied', { feature: trans('content.createAI') }));
    }
    else if (feedback === 'wordImportWithAi')
    {
        tryShowFeedbackModal('Word Import with AI', trans('feedback.howSatisfied', { feature: trans('import.wordImportWithAI') }));
    }
    else if (feedback === 'excelImport')
    {
        tryShowFeedbackModal('Excel Import', trans('feedback.howSatisfied', { feature: trans('import.excelImport') }));
    }
}