import { trans } from '../../common';
import { Quitch } from '../../quitch';
import { updateCanvasTokenInputState } from '../../class-manager/integrations/canvas';


/**
 * Based on button state, initialise and open Canvas integration settings modal or open remove Canvas integration confirmation
 */
$(document).on('click', '.canvas-org-settings-update-btn', function(e)
{
    if ($(this).attr('data-state') === 'connect')
    {
        $('#canvas-org-settings-form')[0].reset();
        updateCanvasTokenInputState('initial', 'disabled');
        $('#canvas-org-settings-modal').modal('show');
    }
    else if ($(this).attr('data-state') === 'remove')
    {
        Quitch.modal(
            {
                title: trans('orgManager.canvasRemoveTitle'),
                message: `<p>${trans('orgManager.canvasRemoveDesc')}</p>`,
                type: 'confirm_custom',
                cssClass: 'modal-dialog-centered',
                okText: 'content.removeBtn',
                cancelText: 'base.cancel',
            },
            function(response)
            {
                if (response === true)
                {
                    $.ajax(
                    {
                        url: '/canvas/org/remove',
                        type: 'GET',
                        dataType: 'json',
                        success: function(response)
                        {
                            $('.canvas-org-settings-update-btn').attr('data-state', 'connect');
                            $('.canvas-org-settings-update-btn').text(trans('orgManager.connect')).removeClass('btn-outline').addClass('btn-primary');
                            $('.js-canvas-updated-by').text('');
                            Quitch.showSnackbar(trans('orgManager.canvasRemoved'));
                        },
                        error: function(e, t, s)
                        {
                            $('#canvas-org-settings-modal').modal('hide');
                            Quitch.showSnackbar(trans('orgManager.canvasRemoveFailed'));
                        },
                    });
                }
            }
        );
    }
});

$(document).on('submit', '#canvas-org-settings-form', function(e)
{
    e.preventDefault();

    $.ajax(
    {
        url: '/canvas/org/add',
        type: 'POST',
        data: $(this).serialize(),
        success: function(response)
        {
            $('.canvas-org-settings-update-btn').attr('data-state', 'remove');
            $('.canvas-org-settings-update-btn').text(trans('orgManager.remove')).removeClass('btn-primary').addClass('btn-outline');
            $('#canvas-org-settings-modal').modal('hide');
            $('.js-canvas-updated-by').text(trans('orgManager.connectedBy', { name: response.name, time: response.updatedAt }));
            Quitch.showSnackbar(trans('orgManager.canvasConnected'));
        },
        error: function(e, t, s)
        {
            $('#canvas-org-settings-modal').modal('hide');
            Quitch.showSnackbar(trans('orgManager.canvasConnectFailed'));
        },
    });
});

/**
 * Automatically un-focus token input on paste to immediately validate pasted token
 */
$(document).on('paste', '#js-canvas-org-token-input', function(e)
{
    let tokenInput = $(this);
    window.setTimeout(() =>
    {
        tokenInput.blur();
    }, 10);
});

/**
 * Validate Canvas token on input update
 */
$(document).on('change', '#js-canvas-org-token-input', function()
{
    $('.js-canvas-connect-btn').prop('disabled', true);

    let input = $(this).val();
    if (input.length > 0)
    {
        updateCanvasTokenInputState('loading');
        $.ajax(
        {
            url: '/canvas/token/validate',
            type: 'POST',
            dataType: 'json',
            data: { _token: $('meta[name="csrf-token"]').attr('content'), canvasToken: input },
            success: function(response)
            {
                if (response.valid)
                {
                    updateCanvasTokenInputState('valid');
                    $('.js-canvas-connect-btn').prop('disabled', false);
                }
                else
                {
                    updateCanvasTokenInputState('error', 'disabled');
                }
            },
            error: function(xhr, status, error)
            {
                updateCanvasTokenInputState('error', 'disabled');
            },
        });
    }
    else
    {
        updateCanvasTokenInputState('initial');
    }
});