import { trans, transChoice, hideModalPopBox } from './components/common.js';
import { Quitch } from './components/quitch.js';
import { initialiseInputs } from './components/form/input';
import { initialiseContentAuthoring } from './components/class-manager/content-authoring';

export function getSpinnerHtml()
{
    return '<div class="text-center"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>';
}

export function loadingMessage(message)
{
    return `<div class="loading load-message text-center">${trans(message)} <i class="fa fa-pulse fa-spinner"></i></div>`;
}

export function getUpdateMessage(message, type, replace)
{
    return `<div class="alert alert-${type}">${trans(message, replace)}</div>`;
}

export function getMessage(message, type)
{
    return `<div class="alert text-center alert-${type}" style="margin-top: -1px !important;">${trans(message)}</div>`;
}

$('.modal').on('hide', function()
{
    $('.modal-backdrop').hide();
});

// Fixes broken scroll in modal after closing overlapping modal
$(document).on('hidden.bs.modal', '.modal', function()
{
    $('.modal:visible').length && $(document.body).addClass('modal-open');
});

$(document).on('shown.bs.modal', '.modal', function()
{
    if ($('.ui-tooltip').length)
    {
        $('[data-toggle="tooltip"]').tooltip('destroy'); // Fix for tooltips staying open
    }
    initialiseTooltip();
});

$('.snackbar-dismiss').click(function()
{
    $('#snackbar').removeClass('show');
});

// On Ready Actions
$(document).ready(function()
{
    processJavascriptPlugins();
    initialiseTabs();
    initialiseInputs();
    Quitch.initialiseSnackbar();
    initialiseContentAuthoring();
});

/*********************************************************************/

// On Success of ajax request reprocess js plugins as html is being injected dynamically
$(document).ajaxSuccess(function(event, xhr)
{
    if (xhr.status === '200' || xhr.status === 200)
    {
        processJavascriptPlugins();
    }
});

$(document).ajaxStart(function()
{
    $('.ajax.loading').addClass('show');
});

$(document).ajaxStop(function()
{
    $('.ajax.loading').removeClass('show');
});

// Register global ajax error listeners
$.ajaxSetup(
{
    statusCode:
    {
        // xsrf or session expired, show error to user
        401: (jqxhr, textStatus, errorThrown) =>
        {
            // using timeout to prevent multiple session expired messages at once.
            if (!window.error401)
            {
                window.error401 = true;
                setTimeout(() =>
                {
                    window.error401 = false;
                }, 6000);
                Quitch.showErrors('base.sessionExpired');
            }
        },
        403: (jqxhr, textStatus, errorThrown) =>
        {
            // using timeout to prevent multiple session expired messages at once.
            if (!window.error403)
            {
                window.error403 = true;
                setTimeout(() =>
                {
                    window.error403 = false;
                }, 6000);
                Quitch.showErrors('base.orgChanged');
            }
        },
    },
});

// stop dataTable plugin from throwing javascript alerts (horrible ux)
$.fn.dataTable.ext.errMode = 'none';

// store the currently selected tab in the hash value
$(document).on('click', 'ul.nav-hash > li > a, ul.nav-hash > li > ul, ul.nav-hash .dropdown-menu > a', function(e)
{
    if (history && history.pushState)
    {
        history.pushState({}, '', $(e.target).data('target'));
    }
    else
    {
        window.location.hash = $(e.target).data('target');
    }
});

export function initialiseTooltip(targetEle = false)
{
    if (!targetEle)
    {
        // initialise all tooltips
        targetEle = $('[data-toggle="tooltip"]');
    }

    targetEle.not('[data-placement=bottom],[data-placement=right]').tooltip(
    {
        content: function()
        {
            return this.getAttribute('title');
        },
        position: { my: 'left top+5', at: 'left bottom', collision: 'flipfit' },
        open: function(event, ui)
        {
            // Close tooltip on click off
            $(document).one('click', function()
            {
                if ($('.ui-tooltip').length > 0 && $(event.target).is(':ui-tooltip'))
                {
                    $(event.target).tooltip('close');
                }
            });
        }
    });

    targetEle.filter('[data-placement=bottom]').tooltip(
    {
        tooltipClass: 'bs-tooltip-bottom tooltip show compact',
        open: function(event, ui)
        {
            //add an arrow above the tooltip (might be able to get rid of this with bootstrap upgrade or removing something blocking the arrow)
            let html = ui.tooltip.html();
            let targetBounds = event.target.getBoundingClientRect();
            let uiBounds = ui.tooltip[0].getBoundingClientRect();
            let uiLeft = uiBounds.x;
            const uiBorderRadius = Number.parseInt(ui.tooltip.css('border-radius'));
            if (uiLeft > targetBounds.x - uiBorderRadius)
            {
                uiLeft = targetBounds.x - uiBorderRadius;
                ui.tooltip[0].style.left = uiLeft + 'px';
            }
            else if (uiLeft + uiBounds.width - uiBorderRadius < targetBounds.x + targetBounds.width)
            {
                uiLeft = targetBounds.x + targetBounds.width - uiBounds.width + uiBorderRadius;
                ui.tooltip[0].style.left = uiLeft + 'px';
            }
            const arrowWidth = 12.8;
            let left = targetBounds.x + targetBounds.width / 2 - uiLeft - arrowWidth / 2; //left so that middle of arrow is middle of target
            html = `<div class="arrow" style="margin-top: -6px; left: ${left}px"></div>` + html;
            ui.tooltip.html(html);

            // Close tooltip on click off
            $(document).one('click', function()
            {
                if ($('.ui-tooltip').length > 0 && $(event.target).is(':ui-tooltip'))
                {
                    $(event.target).tooltip('close');
                }
            });
        }
    });

    targetEle.filter('[data-placement=right]').tooltip(
    {
        tooltipClass: 'bs-tooltip-right tooltip show compact',
        open: function(event, ui)
        {
            // Add an arrow to the left of the tooltip
            let html = ui.tooltip.html();
            const targetBounds = event.target.getBoundingClientRect();

            ui.tooltip[0].style.left = (targetBounds.right + 12) + 'px';
            ui.tooltip[0].style.top = targetBounds.top + 'px';

            const arrowWidth = 12.8;
            const top = targetBounds.height / 2 - arrowWidth / 2;
            html = `<div class="arrow" style="top: ${top}px; left: -6px"></div>` + html;
            ui.tooltip.html(html);
            $('.ui-tooltip').addClass('compact');

            // Close tooltip on click off
            $(document).one('click', function()
            {
                if ($('.ui-tooltip').length > 0 && $(event.target).is(':ui-tooltip'))
                {
                    $(event.target).tooltip('close');
                }
            });
        }
    });

}

function processJavascriptPlugins()
{
    initialiseTooltip();
    $('input.js_timepicker').timepicker({ timeFormat: 'h:i A' });
    $('select.js_chosen').chosen({ disable_search_threshold: 10, width: '100%', 'min-width': '250px' });
    $('.accordion').accordion(
    {
        header: 'h4',
        collapsible: true,
        heightStyle: 'content',
        active: 0,
    });
    $('.dd').nestable();
}

function initialiseTabs()
{
    if ($('.primary-tabs').length === 0)
    {
        return;
    }

    // on load of the page: switch to the currently selected tab
    let windowHash = window.location.hash ? window.location.hash.toLowerCase() : window.location.hash;
    let primaryTab = windowHash;
    let secondaryTab = false;

    // Determine primary tab based on secondary
    // todo: consider using path based hashes (eg #users/active)
    switch (windowHash)
    {
        case '#teacher-list':
        case '#pending-teacher':
        case '#suspended-teacher':
            secondaryTab = primaryTab;
            primaryTab = false;
            break;
        case '#active':
        case '#pending':
        case '#suspended':
            secondaryTab = primaryTab;
            primaryTab = '#learners';
            break;
        case '#unmarkquestion':
        case '#markquestion':
            secondaryTab = primaryTab;
            primaryTab = '#assess-answer';
            break;
    }

    if (primaryTab)
    {
        // prioritise visible tabs over hidden ones in dropdown
        if ($(`.primary-tabs a[data-target="${primaryTab}"]:visible`).length > 0)
        {
            $(`.primary-tabs a[data-target="${primaryTab}"]:visible`).tab('show');
        }
        else
        {
            $(`.primary-tabs a[data-target="${primaryTab}"]`).tab('show');
        }

    }

    if (secondaryTab)
    {
        $(`.secondary-tabs a[data-target="${secondaryTab}"]`).tab('show');
    }
}

$(document).on('keyup', '.positive-val-only', function()
{
    let inputVal = $(this).val();
    if (!inputVal.match(/^[+]?([0-9]+(?:[.0-9]*)?|\.[0-9]+)$/))
    {
        this.value = '';
    }
});

$(document).on('click', '.js_close-accordion', function(e)
{
    let chevron = $(e.target);
    let accordion = chevron.closest('div').siblings('div');
    let activeClassesBtn = chevron.parent('span').siblings('div');
    if (chevron.hasClass('fa-chevron-down'))
    {
        chevron.removeClass('fa-chevron-down');
        chevron.addClass('fa-chevron-right');
        accordion.hide();
        if (activeClassesBtn.length !== 0)
        {
            activeClassesBtn.hide();
        }

        if (accordion.parent().hasClass('full-expand'))
        {
            accordion.parent().addClass('h-100');
        }
    }
    else
    {
        chevron.addClass('fa-chevron-down');
        chevron.removeClass('fa-chevron-right');
        accordion.show();
        if (activeClassesBtn.length !== 0)
        {
            activeClassesBtn.show();
        }
        if (accordion.parent().hasClass('full-expand'))
        {
            accordion.parent().removeClass('h-100');
        }
    }
});

$(document).on('click', '.nav-close-accordion', function(e)
{
    let list = $(this);
    let chevron = list.find('i');
    let accordion = list.children('.nav-submenu');
    if (chevron.hasClass('fa-chevron-down'))
    {
        chevron.removeClass('fa-chevron-down');
        chevron.addClass('fa-chevron-right');
        accordion.hide();
    }
    else
    {
        chevron.addClass('fa-chevron-down');
        chevron.removeClass('fa-chevron-right');
        accordion.show();
    }
});

$(document).on('click', '.js_sidenav', function(e)
{
    toggleSidenav(this);
});

function toggleSidenav(self)
{
    let $target = $('.sidebar-quitch');
    let topnav = $('.organisation-details');
    let page = $('#page-wrapper');

    if ($(self).hasClass('hamburger-nav'))
    {
        topnav.children('a.hamburger-nav').addClass('hide');
    }
    else
    {
        topnav.children('a.hamburger-nav').removeClass('hide');
    }
    if ($target.hasClass('sidenav-extended'))
    {
        $target.removeClass('sidenav-extended');
        page.removeClass('sidenav-extended');
        topnav.removeClass('topnav-push');
    }
    else
    {
        $target.addClass('sidenav-extended');
        topnav.addClass('topnav-push');
        page.addClass('sidenav-extended');
    }
}

// use native colour picker to fill our input
$(document).on('change', '.colour-picker input[type=text]', e =>
{
    const $parent = $(e.target).parent();
    const color = $(e.target).val();
    $parent.find('input[type=color]').val(color);
});

$(document).on('change', '.colour-picker input[type=color]', e =>
{
    const $parent = $(e.target)
        .parent()
        .parent();
    const color = $(e.target).val();
    $parent.find('input[type=text]').val(color);
});

// We can attach the `fileselect` event to all file inputs on the page
$(document).on('change', ':file', function()
{
    let input = $(this),
        numFiles = input.get(0).files ? input.get(0).files.length : 1,
        label = input
        .val()
        .replace(/\\/g, '/')
        .replace(/.*\//, '');
    input.trigger('fileselect', [numFiles, label]);
});
$(':file').on('fileselect', function(event, numFiles, label)
{
    let input = $(this)
        .parents('.input-group')
        .find(':text'),
        log = numFiles > 1 ? numFiles + ' files selected' : label;

    if (input.length)
    {
        input.val(log);
    }
});

String.prototype.capitalize = function()
{
    return this.replace(/(^|\s)([a-z])/g, function(m, p1, p2)
    {
        return p1 + p2.toUpperCase();
    });
};

// TODO: remove global, used by csv invite import
const loadResourceFile = (window.loadResourceFile = function(event)
{
    let reader = new FileReader();
    let $fileInput = $(event.target);
    reader.onload = function()
    {
        let filename = $fileInput.val().replace(/C:\\fakepath\\/i, '');
        $fileInput
            .parent()
            .find('.resource-filename')
            .text(filename)
            .show();
        if (filename.endsWith('.csv'))
        {
            $fileInput
                .parent()
                .parent()
                .find('.js-csv-file-format-warning')
                .hide();
        }
        else
        {
            $fileInput
                .parent()
                .parent()
                .find('.js-csv-file-format-warning')
                .show();
        }
    };
    reader.readAsDataURL(event.target.files[0]);
});

$(document).on('click', '.js_email-checks-send', function(e)
{
    e.preventDefault();
    let $form = $('#js_email-checks');
    $.ajax(
    {
        url: $form.attr('action'),
        method: $form.attr('method') ? $form.attr('method') : 'POST',
        dataType: 'json',
        data: $form.serialize(),
        success: function(data) {},
    });
});

$(document).on('click', '.js_email-checks-view', function(e)
{
    e.preventDefault();
    let $form = $('#js_email-checks');
    $.ajax(
    {
        url: '/manage/tenant/email-view',
        method: 'POST',
        data: $form.serialize(),
        success: function(data)
        {
            $('.js_email-output').html(data);
        },
    });
});

$(document).on('click', '.js-copy-auth-token', function(e)
{
    let $target = $(e.target);
    let token = $target
        .parents('tr')
        .children('.js-auth-token-cell')
        .html();
    navigator.clipboard.writeText(token).then(
        function()
        {
            Quitch.showMessage('info', 'auth.clipboardCopied');
        },
        function(err)
        {
            Quitch.showErrors(['auth.clipboardError', err]);
        }
    );
});

$(document).on('click', '.js-expire-auth-token', function(e)
{
    let tokenId = $(e.currentTarget).data('token-id');
    let _token = $('meta[name="csrf-token"]').attr('content');
    $.post('/tokens/expire', { tokenId: tokenId, _token: _token }, function(response)
    {
        if (response.updated > 0)
        {
            location.reload();
        }
        else
        {
            Quitch.showErrors('auth.expireError');
        }
    });
});

$(document).on('click', '.js-delete-auth-token', function(e)
{
    let tokenId = $(e.currentTarget).data('token-id');
    let _token = $('meta[name="csrf-token"]').attr('content');
    $.post('/tokens/delete', { tokenId: tokenId, _token: _token }, function(response)
    {
        if (response.deleted > 0)
        {
            location.reload();
        }
        else
        {
            Quitch.showErrors('auth.deleteError');
        }
    });
});

$(document).on('click', '.js-test-api-with-token', function(e)
{
    $('input#token-input').val($(e.currentTarget).data('token'));
    $('#api-testing-modal').modal('show');
});

$(document).on('click', '.js-test-api-button', function(e)
{
    let api = $('#js-api-to-test-select').val();
    let version = $('#js-api-version-to-test-select').val();
    let url = `/api/quitch/${version}/${api}`;
    let form = $('#js-api-test-form');
    form.attr('action', url);
    form.submit();
});

$(document).on('click', '.js-api-add-extra-input-btn', function(e)
{
    let extraInputsDiv = $('.js-api-extra-inputs-div');
    let inputNum = extraInputsDiv.data('inputnum') + 1;
    extraInputsDiv.data('inputnum', inputNum);
    let section = `
        <div class="row">
            <div class="col">
                <label>Input Name ${inputNum}</label>
                <div class="class-setting-inner-row clearfix">
                    <input type="text" name="api-test-field-name" class="js-api-extra-input-name-entry" data-inputId="${inputNum}" value="input${inputNum}">
                </div>
            </div>
            <div class="col">
                <label>Input Value ${inputNum}</label>
                <div class="class-setting-inner-row clearfix">
                    <input type="text" name="input${inputNum}" id="js-api-extra-input-${inputNum}">
                </div>
            </div>
        </div>`;
    extraInputsDiv.append(section);
});

$(document).on('change', '.js-api-extra-input-name-entry', function(e)
{
    let nameEntry = $(e.currentTarget);
    let valueEntry = $('#js-api-extra-input-' + nameEntry.data('inputid'));
    valueEntry.attr('name', nameEntry.val());
});

$(document).on('click', '#js-filters-btn', function()
{
    let popBox = $('#js-pop-box-outer');
    if (popBox.attr('hidden') !== undefined)
    {
        popBox.removeAttr('hidden');
    }
    else
    {
        popBox.attr('hidden', true);
    }

    if ($(this).hasClass('js-btn-popup-highlight'))
    {
        $(this).removeClass('js-btn-popup-highlight');
    }
    else
    {
        $(this).addClass('js-btn-popup-highlight');
    }
});

$(document).on('click', '.js-btn-transient', function()
{
    $(this).attr('hidden', true);
});

$(document).on('click', '#js-pop-box-page-bg', function()
{
    hideModalPopBox(this);
});