/**
 * Common Quitch UI
 */

import { trans } from './common';
import { telemetryTrackAndPerformUrlChange } from './superuser/mixpanel';

export class Quitch
{
    static updateUrl(params, eventName = '', eventProperties = {})
    {
        let queryString = $.param(params);
        queryString = queryString.length === 0 ? queryString : `?${queryString}`;

        if (eventName !== '')
        {
            telemetryTrackAndPerformUrlChange(`${window.location.pathname}${queryString}`, eventName, eventProperties);
        }
        else
        {
            window.location.href = `${window.location.pathname}${queryString}`;
        }
    }

    static showMessage(type, message, replace = {})
    {
        type = type ? type : 'info';
        const i18nMessage = trans(message, replace);
        let $alertDiv = $(`<div class="alert alert-${type}">${i18nMessage}</div>`);
        $alertDiv.hide().appendTo('.js_toast');
        $alertDiv
            .fadeIn()
            .delay(5000)
            .fadeOut(400, $alertDiv.remove);
    }

    static showErrors(errors, replace = {})
    {
        if (typeof errors === 'string' || errors instanceof String)
        {
            Quitch.showMessage('danger', errors, replace);
        }
        else
        {
            $.each(errors, (key, error) =>
            {
                const i18nError = trans(error, replace);
                Quitch.showMessage('danger', `<span class="key ${key}">${i18nError}</span>`);
            });
        }
    }

    static initialiseSnackbar()
    {
        let snackbar = $('#snackbar');
        if ($.trim(snackbar.html()))
        {
            snackbar.addClass('show');
            // After 5 seconds, remove the show class from DIV
            setTimeout(function()
            {
                snackbar.removeClass('show');
            }, 5000);
        }
    }

    static showSnackbar(message, dismiss = false, actionClass = null, actionLabel = null)
    {
        let innerHTML = message;
        if (actionClass && actionLabel)
        {
            innerHTML += `<span class="ml-auto pl-2 pr-2 snackbar-action ${actionClass}">${actionLabel}</span>`;
        }
        if (dismiss)
        {
            innerHTML += `<i class="fas fa-times ml-auto snackbar-dismiss"></i>`;
        }

        $('#snackbar').html(innerHTML);
        Quitch.initialiseSnackbar();
    }

    static showCallToAction(selector, id, options)
    {
        if (!selector || !id)
        {
            return;
        }
        let defaults = {
            type: 'info',
            showIcon: false,
            title: false,
            description: '',
            replace: true,
            showDismiss: false,
            dismissCallback: false,
            actionText: false,
            actionCallback: false
        };
        let config = $.extend({}, defaults, options);
        let textHtml = '';

        if (config.title)
        {
            config.showIcon = true;
            textHtml += `<div class="title">${trans(config.title)}</div>`;
        }
        if (config.description)
        {
            textHtml += `<div class="description">${trans(config.description)}`;
            if (config.actionText)
            {
                // Check if action text already has <a> tag
                if ($(config.actionText).is('a'))
                {
                    textHtml += ` ${config.actionText}`;
                }
                else
                {
                    textHtml += ` <a href="#" class="link action">${trans(config.actionText)}</a>`;
                }
                if (config.actionCallback)
                {
                    $(document).on('click', `${selector} .action`, function()
                    {
                        config.actionCallback();
                    });
                }
            }
            textHtml += `</div>`;
        }
        if (config.showDismiss)
        {
            $(document).on('click', `${selector} .dismiss`, function()
            {
                $(`#${id}`).remove();
                if (config.dismissCallback)
                {
                    config.dismissCallback();
                }
            });
        }

        let ctaHtml = `<div id="${id}" class="call-to-action ${config.type}">
                                ${config.showIcon ? '<div class="icon"></div>' : ''}
                                <div class="text">${textHtml}</div>
                                ${config.showDismiss ? '<div class="dismiss"></div>' : ''}
                             </div>`;

        // Replace children in element or append to end
        if (config.replace)
        {
            $(selector).html(ctaHtml);
        }
        else
        {
            $(selector).append(ctaHtml);
        }
    }

    /**
     * Custom UI Based Modal for Alerts/Confirm
     * */
    static modal(options, callback)
    {
        this.modalCallback = callback;

        if (!options)
        {
            return;
        }
        // convert string parameter into message object
        options = typeof options === 'string' ? { message: options } : options;

        let defaults = { type: 'alert', showClose: false, message: '', title: false, cssClass: '', okText: 'base.ok', okBtnCss: 'btn-secondary', cancelText: 'base.cancel' };
        let settings = $.extend({}, defaults, options);
        let $dialogBox = $('#js_dialog-box');
        $dialogBox.find('button').show();
        $dialogBox.find('.close').toggle(settings.showClose);
        $dialogBox.find('.modal-footer').removeClass('confirm');
        $dialogBox.find('.js_ok-btn').css('float', '');
        $dialogBox.find('.js_ok-btn').html(trans('base.ok'));
        $dialogBox.find('.js_ok-btn').attr('class', 'btn btn-sm js_ok-btn').addClass(settings.okBtnCss);
        $dialogBox.find('.js_cancel-btn').html(trans('base.cancel'));
        $dialogBox.find('.modal-title').html(settings.title);
        $dialogBox.find('.modal-body').html(settings.message);
        $dialogBox.find('.modal-header').hide();
        $dialogBox.find('.modal-dialog').addClass(settings.cssClass);
        if (settings.title || settings.showClose)
        {
            $dialogBox.find('.modal-header').show();
        }
        if (settings.type === 'alert')
        {
            $dialogBox.find('.js_ok-btn').css('float', 'right');
            $dialogBox.find('.js_cancel-btn').hide();
            $dialogBox.modal('show');
        }
        else if (settings.type === 'alert-no-button')
        {
            $dialogBox.find('.js_ok-btn').hide();
            $dialogBox.find('.js_cancel-btn').hide();
            $dialogBox.modal('show');
        }
        else if (settings.type === 'confirm')
        {
            $dialogBox.find('.modal-footer').addClass('confirm');
            $dialogBox.find('.js_ok-btn').html(trans('base.yes'));
            $dialogBox.find('.js_cancel-btn').html(trans('base.no'));
            $dialogBox.modal('show');
        }
        else if (settings.type === 'confirm_custom')
        {
            $dialogBox.find('.modal-footer').addClass('confirm');
            $dialogBox.find('.js_ok-btn').html(trans(settings.okText));
            $dialogBox.find('.js_cancel-btn').html(trans(settings.cancelText));
            $dialogBox.modal('show');
        }
        else
        {
            throw 'unknown dialog type:';
        }
    }
}

$(document).on('click', '#js_dialog-box .js_ok-btn, #js_dialog-box .js_cancel-btn', function(e)
{
    e.preventDefault();
    let value = $(this).attr('data-dismiss');
    if (typeof Quitch.modalCallback === 'function')
    {
        Quitch.modalCallback(value === 'true');
    }
    $('#js_dialog-box').modal('hide');
});

$(document).on('hide.bs.modal', '#js_dialog-box', function(e)
{
    if (!($(document.activeElement).hasClass('js_ok-btn') || $(document.activeElement).hasClass('js_cancel-btn')))
    {
        if (typeof Quitch.modalCallback === 'function')
        {
            Quitch.modalCallback(false);
        }
    }
});