import { trans } from '../../common';
import { Quitch } from '../../quitch';

/**
 * Based on button state, initialise and open Teams integration settings modal or open remove Teams integration confirmation
 */
$(document).on('click', '.ai-org-settings-update-btn', function(e)
{
    if ($(this).attr('data-state') === 'connect')
    {
        $('#ai-org-settings-modal').modal('show');
    }
    else if ($(this).attr('data-state') === 'remove')
    {
        Quitch.modal(
            {
                title: trans('orgManager.removeChatGpt'),
                message: `<p>${trans('orgManager.canvasRemoveDesc')}</p>`,
                type: 'confirm_custom',
                cssClass: 'modal-dialog-centered',
                okText: 'content.removeBtn',
                cancelText: 'base.cancel',
            },
            function(response)
            {
                if (response === true)
                {
                    let values = {};
                    values['_token'] = $('meta[name="csrf-token"]').attr('content');
                    values['orgId'] = $('.org-details').attr('data-org-id');
                    values['chatGptActive'] = 0;

                    $.ajax(
                    {
                        url: '/organisation/ai/update',
                        type: 'POST',
                        dataType: 'json',
                        data: values,
                        success: response =>
                        {
                            $('.ai-org-settings-update-btn').attr('data-state', 'connect')
                                .text(trans('orgManager.connect'))
                                .removeClass('btn-outline').addClass('btn-primary');
                            Quitch.showSnackbar(trans('orgManager.integrationRemoved'));
                        },
                        error: function(xhr, status, error)
                        {
                            Quitch.showSnackbar(trans('orgManager.errorOccurred'));
                        },
                    });
                }
            }
        );
    }
});

$(document).on('click', '#js-ai-connect-btn', function()
{
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['orgId'] = $('.org-details').attr('data-org-id');
    values['chatGptActive'] = 1;

    $.ajax(
    {
        url: '/organisation/ai/update',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: response =>
        {
            $('.ai-org-settings-update-btn').attr('data-state', 'remove')
                .text(trans('orgManager.remove'))
                .removeClass('btn-primary').addClass('btn-outline');
            $('#ai-org-settings-modal').modal('hide');
            Quitch.showSnackbar(trans('orgManager.integrationConnected'));
        },
        error: function(xhr, status, error)
        {
            Quitch.showSnackbar(trans('orgManager.errorOccurred'));
        },
    });
});