import { trans } from '../../common';
import { addCharacterLimitCounter, deleteContent, selectContent, updateContentIncompleteFlag, updateTopicIncompleteCounter, validateTitle, validInputLength } from './common';
import { updateQuestionFromAnswers } from '../content-authoring-old/questions';
import { Quitch } from '../../quitch';
import { loadPreview } from './preview';

$(document).on('click', '.js-delete-question', function(e)
{
    deleteContent($(this).parents('.content-authoring-content'), 'question');
});

export function createPlaceholders(questionEle)
{
    let ansIdx = 0;
    questionEle.find('.option-list .option-item').each(function()
    {
        const input = $(this).find('.title.input-field');
        input.attr('data-placeholder', trans('content.answerNo', { index: ++ansIdx }));
        input.text('');
        input.attr('data-current-val', '');
        input.addClass('new');
    });
}

export function updatePlaceholders(questionEle)
{
    let ansIdx = 0;
    questionEle.find('.option-list .option-item').each(function()
    {
        const input = $(this).find('.title.input-field');
        input.attr('data-placeholder', trans('content.answerNo', { index: ++ansIdx }));
    });
}

$(document).on('keyup', '.content-authoring-content .content-input.error', function(e)
{
    if ($(this).parents('.content-authoring-content').attr('data-content-type') === 'fill-in-the-blanks')
    {
        if (titleIncludesBrackets($(this).text()))
        {
            $(this).removeClass('error');
            $(this).next('.error-msg').remove();
        }
    }
});

export function validateQuestion(questionEle)
{
    if (questionEle.attr('data-content-type') === 'fill-in-the-blanks')
    {
        const inputEle = questionEle.find('.content-title');
        inputEle.removeClass('error');
        inputEle.next('.error-msg').remove();
        const text = inputEle.find('.input-field').text().trim();
        // check if closed brackets are present in title
        if (text.length > 0 && !titleIncludesBrackets(text))
        {
            inputEle.addClass('error');
            inputEle.after(`<div class="error-msg body-small mb-2">${trans('content.errorNoSquareBrackets')}</div>`);
            return false;
        }
    }

    if (questionEle.hasClass('newly-added'))
    {
        return true;
    }

    let valid;
    valid = validateTitle(questionEle.find('.content-title'), 'content.errorQuestionRequired', 500);

    questionEle.removeClass('incomplete-settings');
    if (!validInputLength(questionEle.find('[name=hint]'), 500) ||
        !validInputLength(questionEle.find('[name=explanation]'), 500) ||
        !validInputLength(questionEle.find('[name=imageAltText]'), 500))
    {
        questionEle.addClass('incomplete-settings');
        valid = false;
    }

    return valid;
}

function titleIncludesBrackets(title)
{
    return title.match(/^\[[^\[\]]*$|^[^\[\]]*\]$|\[[^\[\]]*\]/);
}

/**
 * Validate options on keyup after 250ms of inactivity
 */
let optionInputTimer;
$(document).on('keyup', '.content-authoring-content .option-item .input-field', function(e)
{
    window.clearTimeout(optionInputTimer);
    optionInputTimer = setTimeout(() =>
    {
        const questionEle = $(this).parents('.content-authoring-content');
        const validAnswers = validateOptions(questionEle);
        updateContentIncompleteFlag(questionEle, (questionEle.find('.content-title.content-input.error').length === 0) && validAnswers);
        updateTopicIncompleteCounter(questionEle.parents('.content-authoring-topic'));
    }, 250);
});

export function validateOptions(questionEle)
{
    const questionType = questionEle.attr('data-content-type');
    if (questionType === 'free-text')
    {
        return true;
    }

    questionEle.find('.option-list .error-msg').remove();
    const options = questionEle.find('.option-item');
    let valid = true;
    let errors = [];
    let uniqueAnswers = [];
    let hasDuplicates = false;
    let filledAnswersCount = 0;
    let correctAnswersCount = 0;
    let emptyCorrectAnswersCount = 0;
    const newlyAdded = questionEle.find('.option-list.newly-added').length > 0;

    for (let i = 0; i < options.length; i++)
    {
        let title = options.eq(i).find('.title').text().trim();
        if (title.length > 0)
        {
            filledAnswersCount++;
            if (!hasDuplicates)
            {
                if (uniqueAnswers.includes(title))
                {
                    hasDuplicates = true;
                }
                else
                {
                    uniqueAnswers.push(title);
                }
            }

            if (title.length > 300)
            {
                addCharacterLimitCounter(options.eq(i).find('.option-input'), 300, title.length);
                valid = false;
            }
        }

        if (options.eq(i).find('.js-toggle-correct:checked').length > 0)
        {
            correctAnswersCount++;
            if (title.length === 0)
            {
                emptyCorrectAnswersCount++;
            }
        }
    }

    if (hasDuplicates)
    {
        errors.push(`<div class="error-item">${trans('content.errorDuplicateAnswers')}</div>`);
        valid = false;
    }

    // At least two answers are required (don't show if newly added)
    if (!newlyAdded && filledAnswersCount < 2)
    {
        errors.push(`<div class="error-item">${trans('content.errorTwoAnswersRequired')}</div>`);
        valid = false;
    }
    else if (filledAnswersCount > 1 && newlyAdded)
    {
        questionEle.find('.option-list').removeClass('newly-added');
    }

    // Check at least one correct answer or at least one filled correct answer if not newly added
    if (questionType !== 'fill-in-the-blanks' && (correctAnswersCount === 0 || (correctAnswersCount === emptyCorrectAnswersCount && !newlyAdded)))
    {
        errors.push(`<div class="error-item">${trans(questionType === 'select-all-that-apply' ? 'content.errorSelectAtLeastOneCorrect' : 'content.errorSelectOneCorrect')}</div>`);
        valid = false;
    }

    questionEle.find('.below-answer-errors').remove();
    if (errors.length > 0)
    {
        questionEle.find('.schedule-status-toolbar').before(`<div class="below-answer-errors">${errors.join('')}</div>`);
    }

    return valid;
}

$(document).on('change', '[name=question-type-change]', function(e)
{
    const questionEle = $(this).closest('.content-authoring-content.question');
    const newQuestionType = $(this).val();
    const oldQuestionType = questionEle.attr('data-content-type');
    const dropdownBtn = questionEle.find('.js-change-question-type-dropdown .btn-dropdown');
    dropdownBtn.attr('disabled', true);

    if (newQuestionType === oldQuestionType)
    {
        dropdownBtn.removeAttr('disabled');
        return;
    }

    const answers = extractAnswers(questionEle);

    switch (newQuestionType)
    {
        case 'free-text':
            changeQuestionTypeModal(questionEle, oldQuestionType, function()
            {
                updateQuestionAnswers(questionEle, [], newQuestionType);
            });
            break;
        case 'true-false':
        {
            let newAnswers = [
            {
                value: trans('content.true'),
                correct: true
            },
            {
                value: trans('content.false'),
                correct: false
            }];
            if (oldQuestionType === 'free-text')
            {
                updateQuestionAnswers(questionEle, newAnswers, newQuestionType);
            }
            else
            {
                changeQuestionTypeModal(questionEle, oldQuestionType, function()
                {
                    updateQuestionAnswers(questionEle, newAnswers, newQuestionType);
                });
            }
        }
        break;
        case 'fill-in-the-blanks':
        {
            let title = questionEle.find('.content-title').text().trim();

            if (oldQuestionType === 'free-text')
            {
                updateQuestionAnswers(questionEle, false, newQuestionType);
            }
            else
            {
                // order answers by correct
                answers.sort((a, b) =>
                {
                    if (a.correct && !b.correct)
                    {
                        return -1;
                    }
                    if (!a.correct && b.correct)
                    {
                        return 1;
                    }
                    return 0;
                });


                answers.forEach((ans, idx) =>
                {
                    if (ans.correct)
                    {
                        title += ' [......]';
                    }
                });

                updateQuestionAnswers(questionEle, answers, newQuestionType, title);
            }
        }
        break;
        case 'select-all-that-apply':
            if (oldQuestionType === 'free-text')
            {
                updateQuestionWithPlaceholderAnswers(questionEle, newQuestionType);
            }
            else
            {
                updateQuestionAnswers(questionEle, false, newQuestionType);
            }
            break;
        case 'multi-choice':
            if (oldQuestionType === 'select-all-that-apply')
            {
                // If already only one correct answer, keep answers the same, otherwise set the first answer as correct
                let correctCount = 0;
                answers.forEach((ans, idx) =>
                {
                    if (ans.correct)
                    {
                        if (correctCount < 1)
                        {
                            answers[idx].correct = true;
                        }
                        else
                        {
                            answers[idx].correct = false;
                        }
                        ++correctCount;
                    }
                });

                if (correctCount === 1)
                {
                    updateQuestionAnswers(questionEle, false, newQuestionType);
                }
                else
                {
                    updateQuestionAnswers(questionEle, answers, newQuestionType);
                }
            }
            else if (oldQuestionType === 'free-text')
            {
                updateQuestionWithPlaceholderAnswers(questionEle, newQuestionType);
            }
            else if (oldQuestionType === 'fill-in-the-blanks')
            {
                answers.forEach((ans, idx) =>
                {
                    answers[idx].correct = idx === 0;
                });

                updateQuestionAnswers(questionEle, answers, newQuestionType);
            }
            else
            {
                updateQuestionAnswers(questionEle, answers, newQuestionType);
            }
            break;
        default:
            console.error('unknown question type', newQuestionType);
            dropdownBtn.removeAttr('disabled');
    }
});

$(document).on('click', '.content-authoring-content .add-option-btn', function(e)
{
    const questionEle = $(this).parents('.content-authoring-content');
    addFakeOption(questionEle);
});

function addFakeOption(questionEle)
{
    const html = $('.option-template-holder').first().html();
    $(questionEle).find('.option-list').append(html);

    const newElem = $(questionEle).find('.option-item.option-template');
    newElem.removeClass('hide option-template');
    const siblingsCount = newElem.siblings('.option-item').length;
    if (questionEle.attr('data-content-type') !== 'fill-in-the-blanks')
    {
        const optionToggleId = `optionToggle-${questionEle.attr('data-content-id')}-${siblingsCount + 1}`;
        newElem.find('.js-toggle-correct').attr('id', optionToggleId);
        newElem.find('.js-toggle-correct ~ label').attr('for', optionToggleId);
    }
    else
    {
        newElem.find('.toggle-slide').addClass('disabled');
    }
    newElem.find('.input-field').attr('data-placeholder', trans('content.answerNo', { index: siblingsCount + 1 })).focus();
    loadPreview();
}

function changeQuestionTypeModal(questionEle, oldQuestionType, callback)
{
    Quitch.modal(
        {
            title: trans('content.changeQuestionTypeTitle'),
            message: trans('content.changeQuestionTypeDesc'),
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered modal-dialog-sm',
            okText: 'content.changeQuestionType',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                callback();
            }
            else
            {
                // revert dropdown
                questionEle.find('[name=question-type-change]').val(oldQuestionType);
                questionEle.find(`.dropdown-item[data-target=${oldQuestionType}]`).click();
            }
        }
    );
}

function extractAnswers(questionEle)
{
    return questionEle.find('.option-list .option-item').map(function()
    {
        const id = $(this).attr('data-option-id');
        const value = $(this).find('.title.input-field').attr('data-current-val');
        const correct = !!$(this).find('.js-toggle-correct:checked').length;
        return {
            id,
            value,
            correct
        };
    }).get();
}

function updateQuestionWithPlaceholderAnswers(questionEle, newQuestionType, newTitle = false)
{
    let newAnswers = [];
    for (let i = 0; i < 4; i++)
    {
        newAnswers.push({ value: trans('content.answerNo', { index: i + 1 }), correct: i === 0 });
    }
    updateQuestionAnswers(questionEle, newAnswers, newQuestionType, newTitle, true);
}

function updateQuestionAnswers(questionEle, newAnswers, newQuestionType, newTitle = false, ansPlaceholders = false)
{
    if (newAnswers === false)
    {
        changeQuestionType(questionEle, newQuestionType);
    }
    else
    {
        const values = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            assessmentId: questionEle.attr('data-content-id'),
            classId: $('[name=classId]').val(),
            options: newAnswers
        };

        $.ajax(
        {
            url: '/content/authoring/options/set',
            type: 'POST',
            dataType: 'json',
            data: values,
            success: function(response, status, xhr)
            {
                changeQuestionType(questionEle, newQuestionType, newTitle, ansPlaceholders);
            },
            error: function(xhr, status, error)
            {
                console.error('Failed to change question type while updating options: ', error);
                Quitch.showSnackbar(trans('content.errorChangeQuestionType'));
            }
        });
    }
}

function changeQuestionType(questionEle, newQuestionType, newTitle = false, ansPlaceholders = false)
{
    const values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        assessmentId: questionEle.attr('data-content-id'),
        classId: $('[name=classId]').val(),
        type: newQuestionType,
        renderUi: true
    };

    if (newTitle)
    {
        values.title = newTitle;
    }

    $.ajax(
    {
        url: '/content/authoring/question/edit',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            const updatedQuestion = $(response.questionHtml);
            questionEle.replaceWith(updatedQuestion);
            if (ansPlaceholders)
            {
                createPlaceholders(updatedQuestion);
            }

            if (newQuestionType === 'fill-in-the-blanks')
            {
                // fill blank with correct answer
                const questionInput = updatedQuestion.find('.content-title .input-field')[0];
                const answerInputs = updatedQuestion.find('.option-item .title.input-field');
                updateQuestionFromAnswers(questionInput, answerInputs, null);
            }

            selectContent(updatedQuestion);
            Quitch.showSnackbar(trans('content.changedQuestionType'));
        },
        error: function(xhr, status, error)
        {
            console.error('Failed to change question type: ', error);
            Quitch.showSnackbar(trans('content.errorChangeQuestionType'));
        }
    });
}