import { initialiseContentDetails } from './content-details';
import { trans } from '../../common';
import { validateOptions, validateQuestion } from './question';
import { validateResource } from './resource';
import { updateTopicTally, validateSectionTopic } from './topic';
import { loadPreview } from './preview';
import { Quitch } from '../../quitch';
import { toggleTopicSchedulePublishBtn, updatePublishBtn } from './schedule-publish';

export function selectContent(contentEle, scrollTo = false, force = false)
{
    // Prevent content selection if already selected
    if (!force && (contentEle.hasClass('selected')))
    {
        // Open content details if error inside
        if (!$('.content-authoring-wrapper').hasClass('expanded-sidebar') && contentEle.hasClass('incomplete-settings') && contentEle.attr('data-schedule-status') === 'draft')
        {
            initialiseContentDetails(contentEle);
        }
        return;
    }

    const topicEle = contentEle.parents('.content-authoring-topic').first();
    if (topicEle.hasClass('collapsed'))
    {
        expandTopic(topicEle, toggleContent.bind(null, contentEle, true, true));
    }
    else
    {
        toggleContent(contentEle, scrollTo);
    }
}

function toggleContent(contentEle, scroll = false, setFocus = false)
{
    const lastSelected = $('.content-authoring-list .content-authoring-content.selected');
    const toggleContentBody = function()
    {
        $('.content-authoring-content').addClass('collapsed').removeClass('selected edit');
        const contentBody = contentEle.find('.content-body');

        if (contentEle.attr('data-schedule-status') === 'draft')
        {
            if (!contentBody.hasClass('hide'))
            {
                $('.js-content-edit').attr('hidden', true);
                contentBody.slideToggle(300, function()
                {
                    // remove inline display style after animation is complete
                    $(this).removeAttr('style');
                    $('.js-content-edit').removeAttr('hidden');
                });
            }

            contentEle.removeClass('collapsed').addClass('selected edit');
            if (scroll)
            {
                scrollToContent(contentEle, 400, 100);
            }
            if (setFocus)
            {
                contentEle.find('.input-field').first().focus();
            }
        }
        else
        {
            contentEle.addClass('selected collapsed');
            if (scroll)
            {
                scrollToContent(contentEle, 400, 100);
            }
        }
        loadPreview(contentEle);
        $('.preview-content-wrapper .inner-body').fadeIn('fast');
    };

    if (lastSelected.length > 0 && lastSelected.attr('data-schedule-status') === 'draft' && !lastSelected.find('.content-body').hasClass('hide'))
    {
        $('.js-content-edit').attr('hidden', true);
        lastSelected.find('.content-body').slideToggle(200, function()
        {
            $(this).removeAttr('style');
            $('.js-content-edit').removeAttr('hidden');

            lastSelected.removeClass('newly-added');
            lastSelected.find('.newly-added').removeClass('newly-added');
            validateAllContent();
            toggleContentBody();
        });
    }
    else
    {
        if (lastSelected.length > 0)
        {
            lastSelected.removeClass('newly-added');
            lastSelected.find('.newly-added').removeClass('newly-added');
        }
        validateAllContent();
        toggleContentBody();
    }

    // Refresh content details if already expanded, otherwise open it if error inside
    if (($('.content-authoring-wrapper').hasClass('expanded-sidebar') || contentEle.hasClass('incomplete-settings')) && contentEle.attr('data-schedule-status') === 'draft')
    {
        initialiseContentDetails(contentEle);
    }
    else
    {
        $('.content-authoring-wrapper').removeClass('expanded-sidebar');
    }
}

export function expandTopic(topicEle, callback = null)
{
    const lastExpanded = $('.content-authoring-list .content-authoring-topic.expanded');

    const contentElemsCurrTopic = lastExpanded.find('.content-authoring-content');
    const contentElemsDestTopic = topicEle.find('.content-authoring-content');
    const collapseSpeedCurrTopic = 100 * (contentElemsCurrTopic.length === 0 ? 1 : contentElemsCurrTopic.length);
    const expandSpeedDestTopic = 100 * (contentElemsDestTopic.length === 0 ? 1 : contentElemsDestTopic.length);

    const toggleTopicBody = function()
    {
        $('.content-authoring-topic').addClass('collapsed').removeClass('expanded');
        topicEle.find('.content-authoring-topic-body').slideToggle(expandSpeedDestTopic, function()
        {
            // remove inline display style after animation is complete
            $(this).removeAttr('style');
        });

        topicEle.removeClass('collapsed').addClass('expanded');

        if (callback)
        {
            callback();
        }
    };

    if (lastExpanded.length > 0)
    {
        $('.content-authoring-list').data('last-open-topic-id', lastExpanded.data('id'));

        lastExpanded.find('.content-authoring-content .batch-checkbox input').prop('checked', false).change();
        lastExpanded.find('.content-authoring-topic-body').slideToggle(collapseSpeedCurrTopic, function()
        {
            $(this).removeAttr('style');
            toggleTopicBody();
        });
    }
    else
    {
        toggleTopicBody();
    }
}

export function scrollToContent(contentEle, duration = 500, delay = 250)
{
    window.setTimeout(() =>
    {
        animScroll($('.content-authoring-list'), contentEle, 5, -96);
    }, delay);
}

function animScroll(scrollArea, toElem, rate, extraOffset, startedTime = 0)
{
    if (startedTime === 0)
    {
        startedTime = Date.now();
    }

    const outerArea = scrollArea.get(0).getBoundingClientRect();
    const elemArea = toElem.get(0).getBoundingClientRect();

    const diff = (elemArea.top - outerArea.top) + extraOffset;

    const isScrolledToBottom = scrollArea.get(0).scrollTop + scrollArea.get(0).offsetHeight === scrollArea.get(0).scrollHeight;
    const isScrolledToTop = scrollArea.get(0).scrollTop === 0;

    const scrollingEnded = Math.abs(diff) < 1 || (diff > 0 && isScrolledToBottom) || (diff < 0 && isScrolledToTop);
    if (!scrollingEnded && (Date.now() - startedTime < 5000))
    {
        const rateToUse = rate > Math.abs(diff) ? Math.abs(diff) : rate;

        if (diff > 0) // We are wanting to scroll down
        {
            scrollArea.get(0).scrollTop += rateToUse;
        }
        else if (diff < 0) // We are wanting to scroll up
        {
            scrollArea.get(0).scrollTop -= rateToUse;
        }

        window.setTimeout(function()
        {
            animScroll(scrollArea, toElem, rate, extraOffset, startedTime);
        }, 0);
    }
}

export function validateTitle(inputEle, requiredMsg, charLimit)
{
    let title = inputEle.find('.input-field').text().trim();

    let error = false;
    inputEle.removeClass('error had-error');
    inputEle.next('.error-msg').remove();
    let cssClass = 'error-msg body-small';

    if (!title && requiredMsg)
    {
        inputEle.addClass('error');
        inputEle.after(`<div class="${cssClass}">${trans(requiredMsg)}</div>`);
        error = true;
    }
    else if (title.length > charLimit)
    {
        addCharacterLimitCounter(inputEle, charLimit, title.length);
        error = true;
    }

    return !error;
}

export function addCharacterLimitCounter(inputEle, charLimit, length = null)
{
    length = length || inputEle.find('.input-field').text().trim().length;
    if (length > charLimit)
    {
        inputEle.addClass('error');
        inputEle.after(`<div class="error-msg has-counter text-right"><span class="counter" data-max-length="${charLimit}"><span class="count">${length}</span>/${charLimit}</span></div>`);
    }
}

export function validInputLength(inputEle, charLimit)
{
    // Render input as span to get its text length without any formatting
    return $(`<span>${inputEle.val()}</span>`).text().trim().length <= charLimit;
}

export function validateAllContent()
{
    $('.content-authoring-section').each(function()
    {
        const sectionEle = $(this);
        validateSectionTopic(sectionEle);

        sectionEle.find('.content-authoring-topic').each(function()
        {
            const topicEle = $(this);
            validateSectionTopic(topicEle);
            updateTopicTally(topicEle);

            topicEle.find('.content-authoring-content.question').each(function()
            {
                const questionEle = $(this);
                validateAndUpdateQuestionIncompleteFlag(questionEle);
            });

            topicEle.find('.content-authoring-content.resource').each(function()
            {
                const resourceEle = $(this);
                validateAndUpdateResourceIncompleteFlag(resourceEle);
            });

            updateTopicIncompleteCounter(topicEle);
        });
    });

    $('.content-authoring-content[data-schedule-status=draft] .content-input .input-field').attr('contenteditable', 'true');
    $('.content-authoring-content:not([data-schedule-status=draft]) .content-input .input-field').attr('contenteditable', 'false');
    toggleTopicSchedulePublishBtn();
}

export function updateContentIncompleteFlag(contentEle, valid)
{
    contentEle.toggleClass('incomplete', !valid);
    contentEle.find('.schedule-status-toolbar .incomplete').toggleClass('active', !valid).toggle(!valid);
}

export function validateAndUpdateQuestionIncompleteFlag(questionEle)
{
    const validQuestion = validateQuestion(questionEle);
    const validAnswers = validateOptions(questionEle);
    updateContentIncompleteFlag(questionEle, validQuestion && validAnswers);
    return validQuestion && validAnswers;
}

export function validateAndUpdateResourceIncompleteFlag(resourceEle)
{
    const validResource = validateResource(resourceEle);
    updateContentIncompleteFlag(resourceEle, validResource);
    return validResource;
}

export function updateTopicIncompleteCounter(topicEle)
{
    const questionsIncompleteCount = topicEle.find('.content-authoring-content .incomplete.active').length;
    topicEle.find('.js-topic-incomplete-counter')
        .text(questionsIncompleteCount)
        .attr('data-count', questionsIncompleteCount)
        .toggle(questionsIncompleteCount > 0);
}

export function getPrevContentId(parentContent, btn = null)
{
    let prevContentId = parentContent ? parentContent.prev().data('content-id') : null;

    if (btn)
    {
        const parentFooter = $(btn).closest('.content-authoring-topic-footer');
        if (parentFooter.length)
        {
            const allContent = parentFooter.first().closest('.content-authoring-topic-body').find('.content-authoring-content');
            if (allContent.length)
            {
                prevContentId = allContent.last().data('content-id');
            }
        }
    }

    return prevContentId;
}

export function deleteContent(contentEle, contentType)
{
    const contentSent = contentEle.attr('data-schedule-status') === 'sent';
    const contentTypeCapitalised = contentType[0].toUpperCase() + contentType.slice(1);
    Quitch.modal(
        {
            title: trans(contentSent ? `content.delete${contentTypeCapitalised}ResultsTitle` : `content.delete${contentTypeCapitalised}Title`),
            message: trans(contentSent ? 'content.deleteContentResultsDesc' : `content.delete${contentTypeCapitalised}Desc`),
            type: 'confirm_custom',
            cssClass: 'modal-dialog-centered modal-dialog-sm',
            okText: contentSent ? 'settings.yesDelete' : 'content.deleteBtn',
            okBtnCss: contentSent ? 'btn-danger' : 'btn-secondary',
            cancelText: 'base.cancel',
        },
        function(response)
        {
            if (response === true)
            {
                let values = {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    classId: $('[name=classId]').val()
                };

                if (contentType === 'question')
                {
                    values.assessmentId = contentEle.attr('data-content-id');
                }
                else
                {
                    values.resourceId = contentEle.attr('data-content-id');
                }

                $.ajax(
                {
                    url: contentType === 'question' ? '/content/authoring/question/delete' : '/content/resource/remove',
                    type: 'POST',
                    dataType: 'json',
                    data: values,
                    success: function(response)
                    {
                        const footer = contentEle.closest('.content-authoring-topic-body').find('.content-authoring-topic-footer');
                        if (footer.length && !footer.hasClass('empty-topic'))
                        {
                            if (contentEle.siblings('.content-authoring-content').length === 0)
                            {
                                footer.addClass('empty-topic');
                            }
                        }

                        if (contentEle.siblings('.content-authoring-content').length === 1)
                        {
                            let siblingEle = contentEle.siblings('.content-authoring-content').first();
                            selectContent(siblingEle, false, true);
                        }
                        else
                        {
                            if (contentEle.next())
                            {
                                selectContent(contentEle.next(), false, true);
                            }
                            else
                            {
                                selectContent(contentEle.prev(), false, true);
                            }
                        }
                        contentEle.remove();
                        validateAllContent();
                        loadPreview();
                        updatePublishBtn();
                    }
                });
            }
        }
    );
}

export function detectOS()
{
    let userAgent = window.navigator.userAgent;
    let os = 'Unknown';

    if (userAgent.includes('Macintosh'))
    {
        os = 'Macintosh';
    }
    else if (userAgent.includes('Windows'))
    {
        os = 'Windows';
    }
    else if (userAgent.includes('Linux'))
    {
        os = 'Linux';
    }

    return os;
}

function getFirstNode(node)
{
    if (node.firstChild !== null)
    {
        node = node.firstChild;
        return getFirstNode(node);
    }
    else
    {
        return node;
    }
}

function getLastNode(node)
{
    if (node.lastChild !== null)
    {
        node = node.lastChild;
        return getLastNode(node);
    }
    else
    {
        return node;
    }
}

/**
 * Trims the whitespace and break tags from the input element (includes ones wrapped in styling tags).
 * Removes empty style tags.
 * @param inputEle
 * @returns string
 */
export function trimWhitespaceAndBreaks(inputEle)
{
    let childNodes = inputEle[0].childNodes;
    if (childNodes.length === 0)
    {
        return '';
    }
    
    do
    {
        let firstNode = childNodes[0];
        if (firstNode.nodeType !== Node.TEXT_NODE)
        {
            firstNode = getFirstNode(firstNode);
        }
        
        if (firstNode.nodeValue)
        {
            // trim space at start of node
            firstNode.nodeValue = firstNode.nodeValue.replace(/^(?:&nbsp;|\s)+/, '');
            
            if (firstNode.nodeValue === '')
            {
                // Remove empty style tag
                firstNode.parentNode.removeChild(firstNode);
            }
            else
            {
                // Reached first node with some text
                break;
            }
        }
        else
        {
            firstNode.parentNode.removeChild(firstNode);
        }
        
        childNodes = inputEle[0].childNodes;
    }
    while (childNodes.length > 0);
    
    do
    {
        let lastNode = childNodes[childNodes.length - 1];
        if (lastNode.nodeType !== Node.TEXT_NODE)
        {
            lastNode = getLastNode(lastNode);
        }
        
        if (lastNode.nodeValue)
        {
            // trim space at end of node
            lastNode.nodeValue = lastNode.nodeValue.replace(/(?:&nbsp;|\s)+$/, '');
            
            if (lastNode.nodeValue === '')
            {
                // Remove empty style tag
                lastNode.parentNode.removeChild(lastNode);
            }
            else
            {
                // Reached last node with some text
                break;
            }
        }
        else
        {
            lastNode.parentNode.removeChild(lastNode);
        }
        
        childNodes = inputEle[0].childNodes;
    }
    while (childNodes.length > 0);
    
    return inputEle.html();
}