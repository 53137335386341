import { telemetryTrackEvent } from '../superuser/mixpanel';
import { Quitch } from '../quitch';
import { trans } from '../common';

export function tryShowFeedbackModal(featureName, text, showSkip = true)
{
    /* TODO: uncomment when ChatGPT implementation out of beta or when we implement another feedback modal
    $.ajax(
    {
        url: '/feedback-modal/seen/',
        type: 'GET',
        dataType: 'json',
        data: { 'eventType': eventType },
        success: function(response, status, xhr)
        {
            if ((xhr.status === '200' || xhr.status === 200) && !response.alreadySeen)
            {
                showFeedbackModal(featureName, text, showSkip);
            }
        }
    });
     */

    showFeedbackModal(featureName, text, showSkip);
}

/**
 * Initialises and shows a feedback modal with the given feature name, text, and optional showSkip flag.
 * @param {string} featureName - The name of the feature being used.
 * @param {string} text - The text to display in the feedback modal.
 * @param {boolean} [showSkip=true] - Whether to show the skip button in the feedback modal.
 * @return {void} This function does not return anything.
 */
export function showFeedbackModal(featureName, text, showSkip = true)
{
    $('#js_dialog-box-feedback-modal .js-how-satisfied-text').text(text);
    $('.js-feedback-feature-name').val(featureName);
    $('.js-feedback-modal-submit-btn').prop('disabled', true);
    $('.js-feedback-text-area').val('');
    $('.js-satisfaction-icon').removeClass('js-satisfaction-icon-selected');
    $('#feedback-modal-skip-btn').toggle(showSkip);
    $('#feedback-modal-cancel-btn').toggle(!showSkip);

    $('#js_dialog-box-feedback-modal').modal('show');

    telemetryTrackEvent('ep-feedback-modal-show', { 'featureName': featureName });
}

$(document).on('click', '.js-feedback-modal-submit-btn', function()
{
    // Get selected satisfaction icon (can be null)
    const featureName = $('.js-feedback-feature-name').val();
    const satisfaction = $('.js-satisfaction-icon-selected').attr('data-type');
    const text = $('.js-feedback-text-area').val();

    const token = $('meta[name="csrf-token"]').attr('content');
    $.ajax(
    {
        url: '/feedback-modal/submit',
        type: 'POST',
        dataType: 'json',
        data: { _token: token, featureName: featureName, satisfaction: satisfaction, feedbackMessage: text },
        success: function(response, status, xhr)
        {
            Quitch.showSnackbar(trans('feedback.thanks'));
        }
    });

    $('#js_dialog-box-feedback-modal').modal('hide');
    telemetryTrackEvent('ep-feedback-modal-submit', { 'featureName': featureName, 'satisfaction': satisfaction, 'text': text });
});

$(document).on('click', '.js-satisfaction-icon', function()
{
    $('.js-feedback-modal-submit-btn').prop('disabled', false);

    $('.js-satisfaction-icon').removeClass('js-satisfaction-icon-selected');
    $(this).addClass('js-satisfaction-icon-selected');

    telemetryTrackEvent('ep-feedback-modal-select-satisfaction-icon', { 'satisfaction': $(this).attr('data-type') });
});

$(document).on('hidden.bs.modal', '#js_dialog-box-feedback-modal', function()
{
    const url = new URL(window.location.href);
    url.searchParams.delete('feedback');
    window.history.replaceState({}, document.title, url);
});