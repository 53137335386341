import { trans } from '../../common';
import { updateAnswerContentFromQuestionBox, updateQnBoxContentFromAnsBox } from './questions';

$(document).on('click', '.enableFormulas', function(e)
{
    let parentModal = $(e.currentTarget).closest('.modal');
    let titleElement = parentModal.find('.js_formula-control');
    if ($(e.target).prop('checked'))
    {
        titleElement.removeClass('hide-formula');
    }
    else
    {
        titleElement.addClass('hide-formula');
    }
});

$(document).on('click', '.js-formula-edit-button', function(e)
{
    let target = $(e.currentTarget);
    let charLimit;
    if ($(this).hasClass('question-formula-button'))
    {
        charLimit = $(this)
            .parent()
            .siblings('span[data-counter]')
            .data('counter');
    }
    else
    {
        charLimit = $(this)
            .siblings('span[data-counter]')
            .data('counter');
    }
    let fieldName = target.data('field');
    let modal = target.closest('.modal');
    let selector = `textarea[name=${fieldName}]`;
    let field = modal.find(selector);

    if (field.length > 1 && target.data('target-rel'))
    {
        let subSelector = `[data-rel="${target.data('target-rel')}"]`;
        field = field.filter(subSelector);
        selector += subSelector;
    }

    let formulaField = $('#js-formula-entry');
    formulaField.val(field.val());
    formulaField.data('field-selector', `#${modal[0].id} ${selector}`);

    createFormulaToolbar();
    $('#formula-edit-modal span[data-counter]').attr('data-counter', charLimit);
    $('#formula-edit-modal').modal({ backdrop: 'static', keyboard: false }, 'show');

    formulaField.trigger('keyup');
});

$(document).on('keyup', '#js-formula-entry', function()
{
    $('#js_formula-display').html($('#js-formula-entry').val());
    MathJax.typeset(['#js_formula-display']);
});

$(document).on('click', '#js-formula-save', function()
{
    let formulaTextbox = $('#js-formula-entry');
    if (formulaTextbox.hasClass('counter-limit-reached'))
    {
        return;
    }

    const fieldSelector = formulaTextbox.data('field-selector');

    const field = $(fieldSelector);
    field.val(formulaTextbox.val());

    const modalId = $(field).closest('.modal').attr('id');
    const isEditModal = modalId === 'edit-question-modal';

    if (fieldSelector.includes('textarea[name=title]'))
    {
        updateAnswerContentFromQuestionBox(field.first()[0], isEditModal);
    }
    else if (fieldSelector.includes('textarea[name=answer_option]'))
    {
        updateQnBoxContentFromAnsBox(field.first()[0], isEditModal);
    }

    $('#formula-edit-modal').modal('hide');
    $('.js_data-counter').trigger('keyup');
});

$(document).on('click', '.js_formula-dropdown', function(e)
{
    e.stopPropagation();
    setTimeout(function()
    {
        $('#js-formula-entry').focus();
    }, 10);
});

$(document).on('click', '.js_formula-insert-item', function(e)
{
    let target = $(e.currentTarget);
    let formulaTextBox = $('#js-formula-entry');
    let fullText = formulaTextBox.val();

    let cursorStart = formulaTextBox.prop('selectionStart');
    let cursorEnd = formulaTextBox.prop('selectionEnd');
    let text = fullText.substr(0, cursorStart);
    text += target.data('content-start');
    text += fullText.substr(cursorStart, cursorEnd - cursorStart);
    text += target.data('content-end');
    text += fullText.substr(cursorEnd);
    formulaTextBox.val(text);
    formulaTextBox.trigger('keyup');

    setTimeout(function()
    {
        $('#js-formula-entry').focus();
        formulaTextBox.prop('selectionStart', cursorStart + target.data('content-start').length);
        formulaTextBox.prop('selectionEnd', cursorEnd + target.data('content-start').length);
    }, 10);
});

function createFormulaToolbar()
{
    let toolbarData = [
    {
        altTitle: trans('content.htmlTags'),
        innerHtml: '<b>B</b><i>I</i><U>U</U>',
        btnClass: 'btn-primary',
        dropdown: [
        {
            altTitle: trans('content.bold'),
            innerHtml: '&nbsp;<b>B</b>',
            start: '<b>',
            end: '</b>',
        },
        {
            altTitle: trans('content.italic'),
            innerHtml: '&nbsp;<i>I</i>',
            start: '<i>',
            end: '</i>',
        },
        {
            altTitle: trans('content.underline'),
            innerHtml: '&nbsp;<u>U</u>',
            start: '<u>',
            end: '</u>',
        },
        {
            altTitle: trans('content.code'),
            innerHtml: '&nbsp;<code>for(...)</code>',
            start: '<code>',
            end: '</code>',
        },
        {
            altTitle: trans('content.dotPointContainer'),
            innerHtml: trans('content.dotPointContainer2'),
            start: '<ul>',
            end: '</ul>',
        },
        {
            altTitle: trans('content.numberedListContainer'),
            innerHtml: trans('content.numberedListContainer2'),
            start: '<ol>',
            end: '</ol>',
        },
        {
            altTitle: trans('content.listItem'),
            innerHtml: trans('content.listItem2'),
            start: '<li>',
            end: '</li>',
        },
        {
            altTitle: trans('content.nonBreakingSpace'),
            innerHtml: '_',
            start: '&nbsp;',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.formulaSection'),
        innerHtml: 'f(x)',
        btnClass: 'btn-primary',
        dropdown: [
        {
            altTitle: trans('content.inlineFormula'),
            innerHtml: trans('content.inlineFormula'),
            start: '\\( ',
            end: ' \\)',
        },
        {
            altTitle: trans('content.ownLineFormula'),
            innerHtml: trans('content.ownLineFormula'),
            start: '\\[ ',
            end: ' \\]',
        }, ],
    },
    {
        altTitle: trans('content.layoutTooltip'),
        innerHtml: '\\(a^b \\frac{a}{b}\\)',
        dropdown: [
        {
            altTitle: trans('content.subscriptTooltip'),
            innerHtml: '\\[a_b\\]',
            start: '_{',
            end: '}',
        },
        {
            altTitle: trans('content.superscriptTooltip'),
            innerHtml: '\\[a^b\\]',
            start: '^{',
            end: '}',
        },
        {
            altTitle: trans('content.fractionTooltip'),
            innerHtml: '\\[\\frac{a}{b}\\]',
            start: '\\frac{',
            end: '}{1}',
        },
        {
            altTitle: trans('content.overTooltip'),
            innerHtml: '\\[a \\over b\\]',
            start: '\\over',
            end: '',
        },
        {
            altTitle: trans('content.matrixTooltip'),
            innerHtml: '\\begin{pmatrix}1&0\\\\0&1\\end{pmatrix}',
            start: '\\begin{pmatrix}\n1 & 0 \\\\\n0 & 1\n\\end{pmatrix}',
            end: '',
        },
        {
            altTitle: trans('content.matrixTooltip'),
            innerHtml: '\\begin{bmatrix}1&0\\\\0&1\\end{bmatrix}',
            start: '\\begin{bmatrix}\n1 & 0 \\\\\n0 & 1\n\\end{bmatrix}',
            end: '',
        },
        {
            altTitle: trans('content.matrixTooltip'),
            innerHtml: '\\begin{matrix}1&0\\\\0&1\\end{matrix}',
            start: '\\begin{matrix}\n1 & 0 \\\\\n0 & 1\n\\end{matrix}',
            end: '',
        },
        {
            altTitle: trans('content.matrixTooltip'),
            innerHtml: '\\begin{pmatrix}1&0&0\\\\0&1&0\\\\0&0&1\\end{pmatrix}',
            start: '\\begin{pmatrix}\n1 & 0 & 0\\\\\n0 & 1 & 0\\\\\n0 & 0 & 1\n\\end{pmatrix}',
            end: '',
        },
        {
            altTitle: trans('content.casesTooltip'),
            innerHtml: '\\[a = \\begin{cases}b&\\text{if}\\\\c&\\text{if}\n\\end{cases}\\]',
            start: '\\begin{cases}\n',
            end: ' & \\text{if }  \\\\\n & \\text{if }\n\\end{cases}',
        }, ],
    },
    {
        altTitle: trans('content.operatorsTooltip'),
        innerHtml: '\\(\\pm \\ne\\)',
        dropdown: [
        {
            altTitle: trans('content.plusMinusTooltip'),
            innerHtml: '\\(\\pm\\)',
            start: '\\pm',
            end: '',
        },
        {
            altTitle: trans('content.timesTooltip'),
            innerHtml: '\\(\\times\\)',
            start: '\\times',
            end: '',
        },
        {
            altTitle: trans('content.divideTooltip'),
            innerHtml: '\\(\\div\\)',
            start: '\\div',
            end: '',
        },
        {
            altTitle: trans('content.notEqualTooltip'),
            innerHtml: '\\(\\ne\\)',
            start: '\\ne',
            end: '',
        },
        {
            altTitle: trans('content.approxTooltip'),
            innerHtml: '\\(\\approx\\)',
            start: '\\approx',
            end: '',
        },
        {
            altTitle: trans('content.lessThanEqualTooltip'),
            innerHtml: '\\(\\leq\\)',
            start: '\\leq',
            end: '',
        },
        {
            altTitle: trans('content.greaterThanEqualTooltip'),
            innerHtml: '\\(\\geq\\)',
            start: '\\geq',
            end: '',
        },
        {
            altTitle: trans('content.equivalentTooltip'),
            innerHtml: '\\(\\equiv\\)',
            start: '\\equiv',
            end: '',
        },
        {
            altTitle: trans('content.proportionalTooltip'),
            innerHtml: '\\(\\propto\\)',
            start: '\\propto',
            end: '',
        },
        {
            altTitle: trans('content.congruentTooltip'),
            innerHtml: '\\(\\cong\\)',
            start: '\\cong',
            end: '',
        },
        {
            altTitle: trans('content.muchLessThanTooltip'),
            innerHtml: '\\(\\ll\\)',
            start: '\\ll',
            end: '',
        },
        {
            altTitle: trans('content.muchGreaterThanTooltip'),
            innerHtml: '\\(\\gg\\)',
            start: '\\gg',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.groupOperatorTooltip'),
        innerHtml: '\\(\\sqrt{a}\\int\\sum\\)',
        dropdown: [
        {
            altTitle: trans('content.sqrtTooltip'),
            innerHtml: '\\(\\sqrt{a}\\)',
            start: '\\sqrt{',
            end: '}',
        },
        {
            altTitle: trans('content.cubedTooltip'),
            innerHtml: '\\(\\sqrt[3]{a}\\)',
            start: '\\sqrt[3]{',
            end: '}',
        },
        {
            altTitle: trans('content.nthTooltip'),
            innerHtml: '\\(\\sqrt[n]{a}\\)',
            start: '\\sqrt[n]{',
            end: '}',
        },
        {
            altTitle: trans('content.sumTooltip'),
            innerHtml: '\\(\\sum\\)',
            start: '\\sum ',
            end: '',
        },
        {
            altTitle: trans('content.sumTooltip'),
            innerHtml: '\\(\\sum_a^b{n}\\)',
            start: '\\sum_{a}^{b}',
            end: '',
        },
        {
            altTitle: trans('content.productTooltip'),
            innerHtml: '\\(\\prod\\)',
            start: '\\prod ',
            end: '',
        },
        {
            altTitle: trans('content.productTooltip'),
            innerHtml: '\\(\\prod_a^b{n}\\)',
            start: '\\prod_{a}^{b}',
            end: '',
        },
        {
            altTitle: trans('content.integralTooltip'),
            innerHtml: '\\(\\int\\)',
            start: '\\int ',
            end: '',
        },
        {
            altTitle: trans('content.integralTooltip'),
            innerHtml: '\\(\\int_a^b\\)',
            start: '\\int_{a}^{b}',
            end: '',
        },
        {
            altTitle: trans('content.partialIntegralTooltip'),
            innerHtml: '\\(\\oint\\)',
            start: '\\oint ',
            end: '',
        },
        {
            altTitle: trans('content.partialIntegralTooltip'),
            innerHtml: '\\(\\oint_a^b\\)',
            start: '\\oint_{a}^{b}',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.characterModifiersTooltip'),
        innerHtml: '\\(\\vec{a}a\\prime\\)',
        dropdown: [
        {
            altTitle: trans('content.primeTooltip'),
            innerHtml: '\\(a\\prime\\)',
            start: '',
            end: '\\prime',
        },
        {
            altTitle: trans('content.vectorTooltip'),
            innerHtml: '\\(\\vec{a}\\)',
            start: '\\vec{',
            end: '}',
        },
        {
            altTitle: trans('content.barTooltip'),
            innerHtml: '\\(\\bar{a}\\)',
            start: '\\bar{',
            end: '}',
        },
        {
            altTitle: trans('content.hatTooltip'),
            innerHtml: '\\(\\hat{a}\\)',
            start: '\\hat{',
            end: '}',
        },
        {
            altTitle: trans('content.tildeTooltip'),
            innerHtml: '\\(\\tilde{a}\\)',
            start: '\\tilde{',
            end: '}',
        },
        {
            altTitle: trans('content.dotTooltip'),
            innerHtml: '\\(\\dot{a}\\)',
            start: '\\dot{',
            end: '}',
        },
        {
            altTitle: trans('content.ddotTooltip'),
            innerHtml: '\\(\\ddot{a}\\)',
            start: '\\ddot{',
            end: '}',
        },
        {
            altTitle: trans('content.acuteTooltip'),
            innerHtml: '\\(\\acute{a}\\)',
            start: '\\acute{',
            end: '}',
        },
        {
            altTitle: trans('content.breveTooltip'),
            innerHtml: '\\(\\breve{a}\\)',
            start: '\\breve{',
            end: '}',
        },
        {
            altTitle: trans('content.checkTooltip'),
            innerHtml: '\\(\\check{a}\\)',
            start: '\\check{',
            end: '}',
        },
        {
            altTitle: trans('content.graveTooltip'),
            innerHtml: '\\(\\grave{a}\\)',
            start: '\\grave{',
            end: '}',
        },
        {
            altTitle: trans('content.wideTildeTooltip'),
            innerHtml: '\\(\\widetilde{ab}\\)',
            start: '\\widetilde{',
            end: '}',
        },
        {
            altTitle: trans('content.wideHatTooltip'),
            innerHtml: '\\(\\widehat{ab}\\)',
            start: '\\widehat{',
            end: '}',
        },
        {
            altTitle: trans('content.overlineTooltip'),
            innerHtml: '\\(\\overline{ab}\\)',
            start: '\\overline{',
            end: '}',
        },
        {
            altTitle: trans('content.underlineTooltip'),
            innerHtml: '\\(\\underline{ab}\\)',
            start: '\\underline{',
            end: '}',
        }, ],
    },
    {
        altTitle: trans('content.symbolsTooltip'),
        innerHtml: '\\(\\infty\\partial\\)',
        dropdown: [
        {
            altTitle: trans('content.infinityTooltip'),
            innerHtml: '\\(\\infty\\)',
            start: '\\infty',
            end: '',
        },
        {
            altTitle: trans('content.partialTooltip'),
            innerHtml: '\\(\\partial\\)',
            start: '\\partial',
            end: '',
        },
        {
            altTitle: trans('content.deltaTooltip'),
            innerHtml: '\\(\\delta\\)',
            start: '\\delta',
            end: '',
        },
        {
            altTitle: trans('content.deltaTooltip'),
            innerHtml: '\\(\\Delta\\)',
            start: '\\Delta',
            end: '',
        },
        {
            altTitle: trans('content.dollarTooltip'),
            innerHtml: '$',
            start: '\\$',
            end: '',
        },
        {
            altTitle: trans('content.ampersandTooltip'),
            innerHtml: '&',
            start: '\\&',
            end: '',
        },
        {
            altTitle: trans('content.alephTooltip'),
            innerHtml: '\\(\\aleph\\)',
            start: '\\aleph',
            end: '',
        },
        {
            altTitle: trans('content.hbarTooltip'),
            innerHtml: '\\(\\hbar\\)',
            start: '\\hbar',
            end: '',
        },
        {
            altTitle: trans('content.emptySetTooltip'),
            innerHtml: '\\(\\emptyset\\)',
            start: '\\emptyset',
            end: '',
        },
        {
            altTitle: trans('content.ellTooltip'),
            innerHtml: '\\(\\ell\\)',
            start: '\\ell',
            end: '',
        },
        {
            altTitle: trans('content.angleTooltip'),
            innerHtml: '\\(\\angle\\)',
            start: '\\angle',
            end: '',
        },
        {
            altTitle: trans('content.imaginaryPartTooltip'),
            innerHtml: '\\(\\Im\\)',
            start: '\\Im',
            end: '',
        },
        {
            altTitle: trans('content.realPartTooltip'),
            innerHtml: '\\(\\Re\\)',
            start: '\\Re',
            end: '',
        },
        {
            altTitle: trans('content.blackboardBoldTooltip'),
            innerHtml: '\\(\\mathbb{A}\\)',
            start: '\\mathbb{',
            end: '}',
        },
        {
            altTitle: trans('content.imathTooltip'),
            innerHtml: '\\(\\imath\\)',
            start: '\\imath',
            end: '',
        },
        {
            altTitle: trans('content.jmathBoldTooltip'),
            innerHtml: '\\(\\jmath\\)',
            start: '\\jmath',
            end: '',
        },
        {
            altTitle: trans('content.heartsTooltip'),
            innerHtml: '\\(\\heartsuit\\)',
            start: '\\heartsuit',
            end: '',
        },
        {
            altTitle: trans('content.diamondsTooltip'),
            innerHtml: '\\(\\diamondsuit\\)',
            start: '\\diamondsuit',
            end: '',
        },
        {
            altTitle: trans('content.spadesTooltip'),
            innerHtml: '\\(\\spadesuit\\)',
            start: '\\spadesuit',
            end: '',
        },
        {
            altTitle: trans('content.clubsTooltip'),
            innerHtml: '\\(\\clubsuit\\)',
            start: '\\clubsuit',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.greekLetterTooltip'),
        innerHtml: '\\(\\alpha\\beta\\pi\\)',
        dropdown: [
        {
            altTitle: trans('content.alphaTooltip'),
            innerHtml: '\\(\\alpha\\)',
            start: '\\alpha',
            end: '',
        },
        {
            altTitle: trans('content.betaTooltip'),
            innerHtml: '\\(\\beta\\)',
            start: '\\beta',
            end: '',
        },
        {
            altTitle: trans('content.gammaTooltip'),
            innerHtml: '\\(\\gamma\\)',
            start: '\\gamma',
            end: '',
        },
        {
            altTitle: trans('content.gammaTooltip'),
            innerHtml: '\\(\\Gamma\\)',
            start: '\\Gamma',
            end: '',
        },
        {
            altTitle: trans('content.deltaTooltip'),
            innerHtml: '\\(\\delta\\)',
            start: '\\delta',
            end: '',
        },
        {
            altTitle: trans('content.deltaTooltip'),
            innerHtml: '\\(\\Delta\\)',
            start: '\\Delta',
            end: '',
        },
        {
            altTitle: trans('content.epsilonTooltip'),
            innerHtml: '\\(\\epsilon\\)',
            start: '\\epsilon',
            end: '',
        },
        {
            altTitle: trans('content.epsilonTooltip'),
            innerHtml: '\\(\\varepsilon\\)',
            start: '\\varepsilon',
            end: '',
        },
        {
            altTitle: trans('content.zetaTooltip'),
            innerHtml: '\\(\\zeta\\)',
            start: '\\zeta',
            end: '',
        },
        {
            altTitle: trans('content.etaTooltip'),
            innerHtml: '\\(\\eta\\)',
            start: '\\eta',
            end: '',
        },
        {
            altTitle: trans('content.thetaTooltip'),
            innerHtml: '\\(\\theta\\)',
            start: '\\theta',
            end: '',
        },
        {
            altTitle: trans('content.thetaTooltip'),
            innerHtml: '\\(\\Theta\\)',
            start: '\\Theta',
            end: '',
        },
        {
            altTitle: trans('content.thetaTooltip'),
            innerHtml: '\\(\\vartheta\\)',
            start: '\\vartheta',
            end: '',
        },
        {
            altTitle: trans('content.iotaTooltip'),
            innerHtml: '\\(\\iota\\)',
            start: '\\iota',
            end: '',
        },
        {
            altTitle: trans('content.kappaTooltip'),
            innerHtml: '\\(\\kappa\\)',
            start: '\\kappa',
            end: '',
        },
        {
            altTitle: trans('content.lambdaTooltip'),
            innerHtml: '\\(\\lambda\\)',
            start: '\\lambda',
            end: '',
        },
        {
            altTitle: trans('content.lambdaTooltip'),
            innerHtml: '\\(\\Lambda\\)',
            start: '\\Lambda',
            end: '',
        },
        {
            altTitle: trans('content.muTooltip'),
            innerHtml: '\\(\\mu\\)',
            start: '\\mu',
            end: '',
        },
        {
            altTitle: trans('content.nuTooltip'),
            innerHtml: '\\(\\nu\\)',
            start: '\\nu',
            end: '',
        },
        {
            altTitle: trans('content.xiTooltip'),
            innerHtml: '\\(\\xi\\)',
            start: '\\xi',
            end: '',
        },
        {
            altTitle: trans('content.xiTooltip'),
            innerHtml: '\\(\\Xi\\)',
            start: '\\Xi',
            end: '',
        },
        {
            altTitle: trans('content.piTooltip'),
            innerHtml: '\\(\\pi\\)',
            start: '\\pi',
            end: '',
        },
        {
            altTitle: trans('content.piTooltip'),
            innerHtml: '\\(\\Pi\\)',
            start: '\\Pi',
            end: '',
        },
        {
            altTitle: trans('content.rhoTooltip'),
            innerHtml: '\\(\\rho\\)',
            start: '\\rho',
            end: '',
        },
        {
            altTitle: trans('content.rhoTooltip'),
            innerHtml: '\\(\\varrho\\)',
            start: '\\varrho',
            end: '',
        },
        {
            altTitle: trans('content.sigmaTooltip'),
            innerHtml: '\\(\\sigma\\)',
            start: '\\sigma',
            end: '',
        },
        {
            altTitle: trans('content.sigmaTooltip'),
            innerHtml: '\\(\\Sigma\\)',
            start: '\\Sigma',
            end: '',
        },
        {
            altTitle: trans('content.sigmaTooltip'),
            innerHtml: '\\(\\varsigma\\)',
            start: '\\varsigma',
            end: '',
        },
        {
            altTitle: trans('content.tauTooltip'),
            innerHtml: '\\(\\tau\\)',
            start: '\\tau',
            end: '',
        },
        {
            altTitle: trans('content.upsilonTooltip'),
            innerHtml: '\\(\\upsilon\\)',
            start: '\\upsilon',
            end: '',
        },
        {
            altTitle: trans('content.upsilonTooltip'),
            innerHtml: '\\(\\Upsilon\\)',
            start: '\\Upsilon',
            end: '',
        },
        {
            altTitle: trans('content.phiTooltip'),
            innerHtml: '\\(\\phi\\)',
            start: '\\phi',
            end: '',
        },
        {
            altTitle: trans('content.phiTooltip'),
            innerHtml: '\\(\\Phi\\)',
            start: '\\Phi',
            end: '',
        },
        {
            altTitle: trans('content.phiTooltip'),
            innerHtml: '\\(\\varphi\\)',
            start: '\\varphi',
            end: '',
        },
        {
            altTitle: trans('content.chiTooltip'),
            innerHtml: '\\(\\chi\\)',
            start: '\\chi',
            end: '',
        },
        {
            altTitle: trans('content.psiTooltip'),
            innerHtml: '\\(\\psi\\)',
            start: '\\psi',
            end: '',
        },
        {
            altTitle: trans('content.psiTooltip'),
            innerHtml: '\\(\\Psi\\)',
            start: '\\Psi',
            end: '',
        },
        {
            altTitle: trans('content.omegaTooltip'),
            innerHtml: '\\(\\omega\\)',
            start: '\\omega',
            end: '',
        },
        {
            altTitle: trans('content.omegaTooltip'),
            innerHtml: '\\(\\Omega\\)',
            start: '\\Omega',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.bracketsTooltip'),
        innerHtml: '()[]{}',
        dropdown: [
        {
            altTitle: trans('content.normalBracketsTooltip'),
            innerHtml: '\\(()\\)',
            start: '(',
            end: ')',
        },
        {
            altTitle: trans('content.squareBracketTooltip'),
            innerHtml: '\\([]\\)',
            start: '[',
            end: ']',
        },
        {
            altTitle: trans('content.braceTooltip'),
            innerHtml: '\\(\\{\\}\\)',
            start: '\\{',
            end: '\\}',
        },
        {
            altTitle: trans('content.sizeAdjustingBracketsTooltip'),
            innerHtml: '\\(\\left(\\right)\\)',
            start: '\\left(',
            end: '\\right)',
        },
        {
            altTitle: trans('content.sizeAdjustingSquareBracketTooltip'),
            innerHtml: '\\(\\left[\\right]\\)',
            start: '\\left[',
            end: '\\right]',
        },
        {
            altTitle: trans('content.smallNormalBracketsTooltip'),
            innerHtml: '\\(\\small(\\small)\\)',
            start: '\\small(',
            end: '\\small)',
        },
        {
            altTitle: trans('content.big1NormalBracketsTooltip'),
            innerHtml: '\\(\\big(\\big)\\)',
            start: '\\big(',
            end: '\\big)',
        },
        {
            altTitle: trans('content.big2NormalBracketsTooltip'),
            innerHtml: '\\(\\Big(\\Big)\\)',
            start: '\\Big(',
            end: '\\Big)',
        },
        {
            altTitle: trans('content.big3NormalBracketsTooltip'),
            innerHtml: '\\(\\bigg(\\bigg)\\)',
            start: '\\bigg(',
            end: '\\bigg)',
        },
        {
            altTitle: trans('content.big4NormalBracketsTooltip'),
            innerHtml: '\\(\\Bigg(\\Bigg)\\)',
            start: '\\Bigg(',
            end: '\\Bigg)',
        },
        {
            altTitle: trans('content.smallSquareBracketTooltip'),
            innerHtml: '\\(\\small[\\small]\\)',
            start: '\\small[',
            end: '\\small]',
        },
        {
            altTitle: trans('content.big1SquareBracketTooltip'),
            innerHtml: '\\(\\big[\\big]\\)',
            start: '\\big[',
            end: '\\big]',
        },
        {
            altTitle: trans('content.big1SquareBracketTooltip'),
            innerHtml: '\\(\\Big[\\Big]\\)',
            start: '\\Big[',
            end: '\\Big]',
        },
        {
            altTitle: trans('content.big1SquareBracketTooltip'),
            innerHtml: '\\(\\bigg[\\bigg]\\)',
            start: '\\bigg[',
            end: '\\bigg]',
        },
        {
            altTitle: trans('content.big1SquareBracketTooltip'),
            innerHtml: '\\(\\Bigg[\\Bigg]\\)',
            start: '\\Bigg[',
            end: '\\Bigg]',
        }, ],
    },
    {
        altTitle: trans('content.arrowsTooltip'),
        innerHtml: '\\(\\leftarrow\\Rightarrow\\)',
        dropdown: [
        {
            altTitle: trans('content.leftArrowTooltip'),
            innerHtml: '\\(\\leftarrow\\)',
            start: '\\leftarrow',
            end: '',
        },
        {
            altTitle: trans('content.rightArrowTooltip'),
            innerHtml: '\\(\\rightarrow\\)',
            start: '\\rightarrow',
            end: '',
        },
        {
            altTitle: trans('content.leftRightArrowTooltip'),
            innerHtml: '\\(\\leftrightarrow\\)',
            start: '\\leftrightarrow',
            end: '',
        },
        {
            altTitle: trans('content.longLeftArrowTooltip'),
            innerHtml: '\\(\\longleftarrow\\)',
            start: '\\longleftarrow',
            end: '',
        },
        {
            altTitle: trans('content.longRightArrowTooltip'),
            innerHtml: '\\(\\longrightarrow\\)',
            start: '\\longrightarrow',
            end: '',
        },
        {
            altTitle: trans('content.longLeftRightArrowTooltip'),
            innerHtml: '\\(\\longleftrightarrow\\)',
            start: '\\longleftrightarrow',
            end: '',
        },
        {
            altTitle: trans('content.leftDoubleArrowTooltip'),
            innerHtml: '\\(\\Leftarrow\\)',
            start: '\\Leftarrow',
            end: '',
        },
        {
            altTitle: trans('content.rightDoubleArrowTooltip'),
            innerHtml: '\\(\\Rightarrow\\)',
            start: '\\Rightarrow',
            end: '',
        },
        {
            altTitle: trans('content.leftRightDoubleArrowTooltip'),
            innerHtml: '\\(\\Leftrightarrow\\)',
            start: '\\Leftrightarrow',
            end: '',
        },
        {
            altTitle: trans('content.longLeftDoubleArrowTooltip'),
            innerHtml: '\\(\\Longleftarrow\\)',
            start: '\\Longleftarrow',
            end: '',
        },
        {
            altTitle: trans('content.longRightDoubleArrowTooltip'),
            innerHtml: '\\(\\Longrightarrow\\)',
            start: '\\Longrightarrow',
            end: '',
        },
        {
            altTitle: trans('content.longLeftRightDoubleArrowTooltip'),
            innerHtml: '\\(\\Longleftrightarrow\\)',
            start: '\\Longleftrightarrow',
            end: '',
        },
        {
            altTitle: trans('content.upArrowTooltip'),
            innerHtml: '\\(\\uparrow\\)',
            start: '\\uparrow',
            end: '',
        },
        {
            altTitle: trans('content.downArrowTooltip'),
            innerHtml: '\\(\\downarrow\\)',
            start: '\\downarrow',
            end: '',
        },
        {
            altTitle: trans('content.upDownArrowTooltip'),
            innerHtml: '\\(\\updownarrow\\)',
            start: '\\updownarrow',
            end: '',
        },
        {
            altTitle: trans('content.upArrowDoubleTooltip'),
            innerHtml: '\\(\\Uparrow\\)',
            start: '\\Uparrow',
            end: '',
        },
        {
            altTitle: trans('content.downArrowDoubleTooltip'),
            innerHtml: '\\(\\Downarrow\\)',
            start: '\\Downarrow',
            end: '',
        },
        {
            altTitle: trans('content.upDownArrowDoubleTooltip'),
            innerHtml: '\\(\\Updownarrow\\)',
            start: '\\Updownarrow',
            end: '',
        },
        {
            altTitle: trans('content.neArrowTooltip'),
            innerHtml: '\\(\\nearrow\\)',
            start: '\\nearrow',
            end: '',
        },
        {
            altTitle: trans('content.seArrowTooltip'),
            innerHtml: '\\(\\searrow\\)',
            start: '\\searrow',
            end: '',
        },
        {
            altTitle: trans('content.nwArrowTooltip'),
            innerHtml: '\\(\\nwarrow\\)',
            start: '\\nwarrow',
            end: '',
        },
        {
            altTitle: trans('content.swArrowTooltip'),
            innerHtml: '\\(\\swarrow\\)',
            start: '\\swarrow',
            end: '',
        },
        {
            altTitle: trans('content.leftUpHarpoonTooltip'),
            innerHtml: '\\(\\leftharpoonup\\)',
            start: '\\leftharpoonup',
            end: '',
        },
        {
            altTitle: trans('content.rightUpHarpoonTooltip'),
            innerHtml: '\\(\\rightharpoonup\\)',
            start: '\\rightharpoonup',
            end: '',
        },
        {
            altTitle: trans('content.leftDownHarpoonTooltip'),
            innerHtml: '\\(\\leftharpoondown\\)',
            start: '\\leftharpoondown',
            end: '',
        },
        {
            altTitle: trans('content.rightDownHarpoonTooltip'),
            innerHtml: '\\(\\rightharpoondown\\)',
            start: '\\rightharpoondown',
            end: '',
        },
        {
            altTitle: trans('content.leftRightHarpoonsTooltip'),
            innerHtml: '\\(\\leftrightharpoons\\)',
            start: '\\leftrightharpoons',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.setsTooltip'),
        innerHtml: '\\(\\in\\supseteq\\)',
        dropdown: [
        {
            altTitle: trans('content.setInTooltip'),
            innerHtml: '\\(\\in\\)',
            start: '\\in',
            end: '',
        },
        {
            altTitle: trans('content.setNinTooltip'),
            innerHtml: '\\(\\notin\\)',
            start: '\\notin',
            end: '',
        },
        {
            altTitle: trans('content.emptySetTooltip'),
            innerHtml: '\\(\\emptyset\\)',
            start: '\\emptyset',
            end: '',
        },
        {
            altTitle: trans('content.subsetTooltip'),
            innerHtml: '\\(\\subset\\)',
            start: '\\subset',
            end: '',
        },
        {
            altTitle: trans('content.subsetEqualTooltip'),
            innerHtml: '\\(\\subseteq\\)',
            start: '\\subseteq',
            end: '',
        },
        {
            altTitle: trans('content.supersetTooltip'),
            innerHtml: '\\(\\supset\\)',
            start: '\\supset',
            end: '',
        },
        {
            altTitle: trans('content.supersetEqualTooltip'),
            innerHtml: '\\(\\supseteq\\)',
            start: '\\supseteq',
            end: '',
        },
        {
            altTitle: trans('content.setMinusTooltip'),
            innerHtml: '\\(\\setminus\\)',
            start: '\\setminus',
            end: '',
        },
        {
            altTitle: trans('content.setCapTooltip'),
            innerHtml: '\\(\\cap\\)',
            start: '\\cap',
            end: '',
        },
        {
            altTitle: trans('content.setCupTooltip'),
            innerHtml: '\\(\\cup\\)',
            start: '\\cup',
            end: '',
        },
        {
            altTitle: trans('content.logicVeeTooltip'),
            innerHtml: '\\(\\vee\\)',
            start: '\\vee',
            end: '',
        },
        {
            altTitle: trans('content.logicWedgeTooltip'),
            innerHtml: '\\(\\wedge\\)',
            start: '\\wedge',
            end: '',
        },
        {
            altTitle: trans('content.logicNotTooltip'),
            innerHtml: '\\(\\lnot\\)',
            start: '\\lnot',
            end: '',
        },
        {
            altTitle: trans('content.existsTooltip'),
            innerHtml: '\\(\\exists\\)',
            start: '\\exists',
            end: '',
        },
        {
            altTitle: trans('content.forAllTooltip'),
            innerHtml: '\\(\\forall\\)',
            start: '\\forall',
            end: '',
        },
        {
            altTitle: trans('content.logicTautologyTooltip'),
            innerHtml: '\\(\\top\\)',
            start: '\\top',
            end: '',
        },
        {
            altTitle: trans('content.logicContradictionTooltip'),
            innerHtml: '\\(\\bot\\)',
            start: '\\bot',
            end: '',
        },
        {
            altTitle: trans('content.logicProvesTooltip'),
            innerHtml: '\\(\\vdash\\)',
            start: '\\vdash',
            end: '',
        },
        {
            altTitle: trans('content.logicModelsTooltip'),
            innerHtml: '\\(\\models\\)',
            start: '\\models',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.dotsTooltip'),
        innerHtml: '\\(\\cdots\\)',
        dropdown: [
        {
            altTitle: trans('content.ldotsTooltip'),
            innerHtml: '\\(\\ldots\\)',
            start: '\\ldots',
            end: '',
        },
        {
            altTitle: trans('content.cdotsTooltip'),
            innerHtml: '\\(\\cdots\\)',
            start: '\\cdots',
            end: '',
        },
        {
            altTitle: trans('content.vdotsTooltip'),
            innerHtml: '\\(\\vdots\\)',
            start: '\\vdots',
            end: '',
        },
        {
            altTitle: trans('content.ddotsTooltip'),
            innerHtml: '\\(\\ddots\\)',
            start: '\\ddots',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.functionsTooltip'),
        innerHtml: '\\(\\sin\\)',
        dropdown: [
        {
            altTitle: trans('content.lnTooltip'),
            innerHtml: '\\(\\ln\\)',
            start: '\\ln',
            end: '',
        },
        {
            altTitle: trans('content.logTooltip'),
            innerHtml: '\\(\\log\\)',
            start: '\\log',
            end: '',
        },
        {
            altTitle: trans('content.limitTooltip'),
            innerHtml: '\\(\\lim\\)',
            start: '\\lim',
            end: '',
        },
        {
            altTitle: trans('content.sinTooltip'),
            innerHtml: '\\(\\sin\\)',
            start: '\\sin',
            end: '',
        },
        {
            altTitle: trans('content.cosTooltip'),
            innerHtml: '\\(\\cos\\)',
            start: '\\cos',
            end: '',
        },
        {
            altTitle: trans('content.tanTooltip'),
            innerHtml: '\\(\\tan\\)',
            start: '\\tan',
            end: '',
        },
        {
            altTitle: trans('content.arcsinTooltip'),
            innerHtml: '\\(\\arcsin\\)',
            start: '\\arcsin',
            end: '',
        },
        {
            altTitle: trans('content.arccosTooltip'),
            innerHtml: '\\(\\arccos\\)',
            start: '\\arccos',
            end: '',
        },
        {
            altTitle: trans('content.arctanTooltip'),
            innerHtml: '\\(\\arctan\\)',
            start: '\\arctan',
            end: '',
        },
        {
            altTitle: trans('content.sinhTooltip'),
            innerHtml: '\\(\\sinh\\)',
            start: '\\sinh',
            end: '',
        },
        {
            altTitle: trans('content.coshTooltip'),
            innerHtml: '\\(\\cosh\\)',
            start: '\\cosh',
            end: '',
        },
        {
            altTitle: trans('content.tanhTooltip'),
            innerHtml: '\\(\\tanh\\)',
            start: '\\tanh',
            end: '',
        },
        {
            altTitle: trans('content.secTooltip'),
            innerHtml: '\\(\\sec\\)',
            start: '\\sec',
            end: '',
        },
        {
            altTitle: trans('content.cscTooltip'),
            innerHtml: '\\(\\csc\\)',
            start: '\\csc',
            end: '',
        },
        {
            altTitle: trans('content.cotTooltip'),
            innerHtml: '\\(\\cot\\)',
            start: '\\cot',
            end: '',
        },
        {
            altTitle: trans('content.cothTooltip'),
            innerHtml: '\\(\\coth\\)',
            start: '\\coth',
            end: '',
        },
        {
            altTitle: trans('content.maxTooltip'),
            innerHtml: '\\(\\max\\)',
            start: '\\max',
            end: '',
        },
        {
            altTitle: trans('content.minTooltip'),
            innerHtml: '\\(\\min\\)',
            start: '\\min',
            end: '',
        }, ],
    },
    {
        altTitle: trans('content.commonFormsTooltip'),
        innerHtml: '\\(\\frac{\\text{d}}{\\text{d}x} \\int_{a}^{b} f(x) \\text{d}x\\)',
        dropdown: [
        {
            altTitle: trans('content.differentialEquationTooltip'),
            innerHtml: '\\[\\frac{\\text{d}}{\\text{d}x}\\]',
            start: '\\frac{\\text{d}}{\\text{d}x}',
            end: '',
        },
        {
            altTitle: trans('content.partialDifferentialEquationTooltip'),
            innerHtml: '\\[\\frac{\\partial}{\\partial x}\\]',
            start: '\\frac{\\partial}{\\partial x}',
            end: '',
        },
        {
            altTitle: trans('content.integralTooltip'),
            innerHtml: '\\[\\int_{a}^{b} f(x) \\text{d}x\\]',
            start: '\\int_{a}^{b} f(x) \\text{d}x',
            end: '',
        },
        {
            altTitle: trans('content.limitTooltip'),
            innerHtml: '\\[\\lim_{x \\rightarrow a} f(x)\\]',
            start: '\\lim_{x \\rightarrow a} f(x)',
            end: '',
        },
        {
            altTitle: trans('content.matrixTooltip'),
            innerHtml: '\\begin{pmatrix}\na_{11}&\\ldots&a_{1m}\\\\\n\\vdots&\\ddots&\\vdots\\\\\na_{n1}&\\ldots&a_{nm}\n\\end{pmatrix}',
            start: '\\begin{pmatrix}\na_{11} & \\ldots & a_{1m}\\\\\n\\vdots & \\ddots & \\vdots\\\\\na_{n1} & \\ldots & a_{nm}\n\\end{pmatrix}',
            end: '',
        }, ],
    }, ];

    let toolbar = $('#js-formula-toolbar');
    toolbar.empty();
    toolbarData.forEach(group =>
    {
        let btnClass = 'btn-light';
        if (group.btnClass)
        {
            btnClass = group.btnClass;
        }
        let html = `<div class="btn-group"><div class="btn ${btnClass} btn-sm btn-custom-dropdown js_formula-dropdown" data-toggle="dropdown"
                            title="${group.altTitle}">${group.innerHtml}</div>
                        <ul class="dropdown-menu float-left custom-dropdown-list" role="menu">`;
        group.dropdown.forEach(button =>
        {
            html += `<li href="#" class="js_formula-insert-item formula-dropdown-item" title="${button.altTitle}" data-content-start="${button.start}"
                                data-content-end="${button.end}">${button.innerHtml}</li>`;
        });
        html += '</ul></div>';
        toolbar.append(html);
    });

    MathJax.typeset(['#js-formula-toolbar']);
}