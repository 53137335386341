import { confirmI18n, trans } from '../common';
import { Quitch } from '../quitch';
import { loadingMessage } from '../../system';

$(document).ready(function()
{
    initialiseAnnouncementList();
});

/**
 * Show announcement details
 */
$(document).on('click', '.announcement_topic', function()
{
    let announcement_id = $(this).attr('data-announcement-id');
    $('.announcements #add-view-area')
        .find('.load-message')
        .remove();
    $('.announcement_topic').removeClass('active');
    $('#announcementFormDetails').hide();
    $('#announcementViewDetails').hide();
    $('.announcements #add-view-area').append(loadingMessage('base.loadingAnnouncement'));
    $(this).addClass('active');

    $('#announcements_topic_list')
        .find('.edit-row')
        .removeClass('edit-row');
    $(this)
        .find('span')
        .addClass('edit-row');

    if (announcement_id === '')
    {
        return false;
    }

    $.ajax(
    {
        url: '/get-announcement-details/' + announcement_id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                let announcement = response.announcement;
                $('.announcement_title').html(`${announcement.title} <span class="badge badge-success"></span>`);
                if (response.viewCount > 0)
                {
                    $('.view-count').html(trans('base.views', { amount: response.viewCount }));
                }
                else
                {
                    $('.view-count').html(trans('base.viewsNone'));
                }
                if (response.sentCount > 0)
                {
                    $('.sent-count').html(trans('base.notificationsSent', { amount: response.sentCount }));
                }
                else
                {
                    $('.sent-count').html(trans('base.notificationsSentNone'));
                }

                $('#announcementGroup').val(announcement.announcementGroup);
                $('#announcementGroup').trigger('chosen:updated');
                switch (announcement.announcementGroup)
                {
                    case 'entire-class':
                        $('.announcement_group').html(trans('base.audience') + ' ' + trans('base.entireClass'));
                        break;
                    case 'at-risk':
                        $('.announcement_group').html(trans('base.audience') + ' ' + trans('base.atRisk'));
                        break;
                    case 'not-participated':
                        $('.announcement_group').html(trans('base.audience') + ' ' + trans('base.notParticipated'));
                        break;
                    default:
                        break;
                }
                $('.publish_onn').attr('style', 'display:inline');
                let dateTime = $(`div[data-announcement-id="${announcement_id}"] .published_date`).text();
                $('.publish_onn').html(`${dateTime}`);
                let daysTilExpired = getDaysToExpired(announcement.publishDate, announcement.expiresIn);
                if (daysTilExpired < 0)
                {
                    $('.publish_onn').toggleClass('expired');
                    $('.publish_onn').attr('title', trans('base.expired', { days: daysTilExpired * -1 }));
                }
                else
                {
                    $('.publish_onn').toggleClass('sent', announcement.sentDate != null);
                }
                $('.announcements-detls').html(linkify(announcement.message));

                $('#edit-area').hide();
                $('#add-view-area').show();
                $('.announcements #add-view-area')
                    .find('.load-message')
                    .remove();
                $('#announcementFormDetails').hide();
                $('#announcementViewDetails').show();
            }
        },
    });
});

/**
 * Setup edit announcement form
 */
$(document).on('click', '.edit-announcement', function()
{
    let announcement_id = $('#announcements_topic_list')
        .find('.active')
        .attr('data-announcement-id');
    if (announcement_id == '')
    {
        return false;
    }
    $.ajax(
    {
        url: '/get-announcement-details/' + announcement_id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                let value = response.announcement;
                $('.id-announcement').val(value.id);
                $('.announcement-title').val(value.title);
                if (response.viewCount > 0)
                {
                    $('.view-count').val('Views: ' + response.viewCount);
                }
                else
                {
                    $('.view-count').val('Views: None');
                }
                $('.announcement-expires-in').val(value.expiresIn);
                $('.announcement-pushon').val(value.publishDate.split(' ')[0]);
                $('.announcement-publish-when').val(value.publishDate.split(' ')[1]);
                $('.announcemment').val(value.message);

                $('.save-announcement').hide();
                $('.edit-class-anncmnt').show();

                $('.announcement_title').html(trans('announcements.editAnnouncement'));
                $('.js_data-counter').trigger('keyup');
                $('#announcementFormDetails').show();
                $('#announcementViewDetails').hide();
            }
        },
    });
});

/**
 * Setup add announcement form
 */
$(document).on('click', '.add-announcement', function()
{
    $('.update-announcement').hide();
    $('.save-announcement').show();
    $('.announcement_topic').removeClass('active');
    $('.announcement_title').html(trans('announcements.addAnnouncement'));
    $('.announcement_group').empty();
    $('.view-count').empty();
    $('#announcementData')[0].reset();
    $('.js_data-counter').trigger('keyup');
    $('.publish_onn').attr('style', 'display:none');
    $('.publish_onn').empty();
    $('#edit-area').hide();
    $('#announcementViewDetails').hide();
    $('#announcementFormDetails').show();
    $('#add-view-area').show();
});

/**
 * Submit announcement form
 */
$(document).on('click', '.save-announcement', function()
{
    $('#save-announcement-btn').attr('disabled', true);
    let announcementMessageError = $(`#announcementData  .announcement-message-error`);
    if ($(`#announcementData .announcemment`).hasClass('counter-limit-reached'))
    {
        announcementMessageError.show();
        announcementMessageError.css('color', 'red');
        $('#save-announcement-btn').attr('disabled', false);
        return false;
    }
    else
    {
        announcementMessageError.hide();
    }
    let announcementData = $('#announcementData').serialize();
    if (announcementData === '')
    {
        $('#save-announcement-btn').attr('disabled', false);
        return false;
    }

    $.ajax(
    {
        url: '/save-announcement-details',
        type: 'POST',
        dataType: 'json',
        data: announcementData,
        success: function(response)
        {
            $('#save-announcement-btn').attr('disabled', false);
            if (response.status === '200' || response.status === 200)
            {
                Quitch.showMessage('success', 'base.alertAnnouncementUpdated');
                $('.announcement_title').empty();
                getAnnouncementList(response.announcement.classId);
                $('#announcementData')[0].reset();
                $('#announcementFormDetails').hide();
            }
            else if (response.status === '403' || response.status === 403)
            {
                Quitch.showErrors(response.errors);
            }
            else
            {
                Quitch.showMessage('danger', response.message);
            }
        },
        error: function()
        {
            $('#save-announcement-btn').attr('disabled', false);
            Quitch.showMessage('danger', 'An error occurred attempting to save announcements');
        },
    });
});

/**
 * Submit edit announcement form
 */
$(document).on('click', '.update-announcement', function()
{
    $('#update-announcement-btn').attr('disabled', true);
    let announcementData = $('#announcementData').serializeArray();
    if (announcementData == '')
    {
        $('#update-announcement-btn').attr('disabled', false);
        return false;
    }
    $.ajax(
    {
        url: '/update-announcement-details',
        type: 'POST',
        dataType: 'json',
        data: announcementData,
        success: function(response)
        {
            $('#update-announcement-btn').attr('disabled', false);
            if (response.status === '200' || response.status === 200)
            {
                Quitch.showMessage('success', 'base.alertAnnouncementUpdated');
                $('.edit-row').empty();
                $('.edit-row').append(response.announcement.title);
                $('.announcement_title').empty();
                getAnnouncementList(response.announcement.classId);
                $('.save-announcement').hide();
                $('.edit-class-anncmnt').hide();
                $('#announcementFormDetails').hide();
                $('#announcementViewDetails').hide();
            }
            else if (response.status === '403' || response.status === 403)
            {
                Quitch.showErrors(response.errors);
            }
            else
            {
                Quitch.showMessage('danger', 'Announcement Not Updated');
            }
        },
    });
});

$(document).on('click', '.del-announcement-btn', function()
{
    if (confirmI18n('base.alertDeleteInfo'))
    {
        let id = $('#announcements_topic_list')
            .find('.active')
            .attr('data-announcement-id');
        let delAnnouncement = $('#announcements_topic_list').find('.active');
        if (id == '')
        {
            return false;
        }
        $.ajax(
        {
            url: '/delete-announcement/' + id,
            type: 'GET',
            dataType: 'json',
            success: function(response)
            {
                if (response.status === '200' || response.status === 200)
                {
                    Quitch.showMessage('success', 'base.alertAnnouncementDeleted');
                    $('#announcementFormDetails').hide();
                    $('#announcementViewDetails').hide();
                    $('.announcement_title').empty();
                    $('.publish_onn').attr('style', 'display:none');
                    $('.view-count').empty();
                    $('.publish_onn').empty();
                    delAnnouncement.remove();
                }
                else
                {
                    Quitch.showMessage('info', 'Announcement Not Found');
                }
            },
        });
    }
});

$(document).on('click', '#announcements-tab', function()
{
    if ($('#announcements_topic_list').children().length === 0)
    {
        let class_id = $('.js_class-manager').data('class-id');
        getAnnouncementList(class_id);
    }
});

function initialiseAnnouncementList()
{
    let windowHash = window.location.hash;
    let class_id = $('.js_class-manager').data('class-id');
    if (windowHash === '#announcements')
    {
        getAnnouncementList(class_id);
    }
}

function getAnnouncementList(class_id)
{
    $('#announcements_topic_list').html(loadingMessage('base.refreshAnnouncements'));
    $.ajax(
    {
        url: '/get-announcement-list/' + class_id,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.status === '200' || response.status === 200)
            {
                $('#announcements_topic_list').empty();
                let announcements = response.announcement;
                if (announcements.length > 0)
                {
                    $.each(announcements, function(index, announcement)
                    {
                        let publishDateHtml;
                        let daysTilExpired = getDaysToExpired(announcement.publishDate, announcement.expiresIn);
                        if (daysTilExpired < 0)
                        {
                            publishDateHtml = `<span class="published_date expired float-right">${announcement.localeDateTime}</span>`;
                        }
                        else if (announcement.sentDate !== null)
                        {
                            publishDateHtml = `<span class="published_date sent float-right">${announcement.localeDateTime}</span>`;
                        }
                        else
                        {
                            publishDateHtml = `<span class="published_date float-right">${announcement.localeDateTime}</span>`;
                        }
                        let announcement_list = `<div class="list-item-block announcement_topic" data-announcement-id="${announcement.id}">
                                            <h4 class="list-item-title">${announcement.title} ${publishDateHtml}</h4>
                                        </div>`;
                        $('#announcements_topic_list').append(announcement_list);
                    });
                }
                else
                {
                    $('#announcements_topic_list').append(`<div>${trans('announcements.noAnnouncements')}</div>`);
                }
            }
        },
    });
}

export function linkify(plainText)
{
    const httpLinkDetector = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    const wwwLinkDetector = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    const emailDetector = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;

    let linkified = plainText.replace(httpLinkDetector, x =>
    {
        try
        {
            const parseUrl = new URL(x);
            const redirect = new URL('/redirect', location);
            redirect.searchParams.append('url', parseUrl.href);
            //TODO: Change onclick to call a function instead of directly call stop Propagation
            return `<a class="announcements-links" target="_blank" onclick="event.stopPropagation()" href="${redirect.href}">${parseUrl}</a>`;
        }
        catch (e)
        {
            return x;
        }
    });
    linkified = linkified.replace(wwwLinkDetector, (x, p1, p2) =>
    {
        try
        {
            const parseUrl = new URL('http://' + p2);
            const redirect = new URL('/redirect', location);
            redirect.searchParams.append('url', parseUrl.href);
            return `${p1}<a class="announcements-links" target="_blank" onclick="event.stopPropagation()" href="${redirect.href}">${p2}</a>`;
        }
        catch (e)
        {
            return x;
        }
    });
    linkified = linkified.replace(emailDetector, x =>
    {
        try
        {
            const parseUrl = new URL('mailto:' + x);
            return `<a class="announcements-links" target="_blank" onclick="event.stopPropagation()" href="${parseUrl}">${x}</a>`;
        }
        catch (e)
        {
            return x;
        }
    });
    return linkified;
}

function getDaysToExpired(publishDate, expiresIn)
{
    if (expiresIn == 0)
    {
        return expiresIn;
    }
    let todayDate = new Date(Date.now());
    let dateExpired = new Date(Date.parse(publishDate));
    dateExpired.setDate(dateExpired.getDate() + expiresIn + 1);
    return Math.round((dateExpired - todayDate) / (1000 * 60 * 60 * 24));
}