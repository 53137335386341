import { updateAnswersFromQuestion, updateQuestionFromAnswers } from '../content-authoring-old/questions';
import { trimBreaks, trimWhitespaceAndBreaks } from './common';
import { loadPreview } from './preview';
/**
 * Add fake correct answers on keyup
 */
$(document).on('keyup', '.content-authoring-content.question[data-content-type=fill-in-the-blanks] .content-title .input-field', function(e)
{
    updateAnswersFromQuestion(this, true, null, false, $(this).parents('.content-authoring-content')[0]);
});

export function getOpenAndCloseBracketIndices(str, excludeEmpty = false)
{
    let isWaitingForCloseBracket = false;
    let expectedFormulaClose = null;
    let nestedBracket = 0;

    let openBracketIndices = [];
    let closeBracketIndices = [];
    let blanksInFormulas = [];
    for (let i = 0; i < str.length; ++i)
    {
        if (str[i] === '[' && !isWaitingForCloseBracket && (i < 1 || str[i - 1] !== '\\'))
        {
            openBracketIndices.push(i);
            blanksInFormulas.push(expectedFormulaClose !== null);
            isWaitingForCloseBracket = true;
        }
        else if (str[i] === '[' && (i < 1 || str[i - 1] !== '\\'))
        {
            ++nestedBracket;
        }
        if (str[i] === ']' && isWaitingForCloseBracket && (i < 1 || str[i - 1] !== '\\'))
        {
            if (expectedFormulaClose !== null && i + 1 < str.length && str[i + 1] === '{')
            {
                //this is a set of [] for part of a formula component e.g. \sqrt[3]{...}
                if (nestedBracket > 0)
                {
                    //inside another blank
                    --nestedBracket;
                    continue;
                }
                //not inside another blank, remove the start of this set from the list
                openBracketIndices.splice(-1);
                isWaitingForCloseBracket = false;
                continue;
            }
            closeBracketIndices.push(i);
            nestedBracket = 0;
            isWaitingForCloseBracket = false;
        }
        if (str[i] === '\\')
        {
            let substring = str.substring(i + 1);
            if (expectedFormulaClose === null)
            {
                if (substring.startsWith('['))
                {
                    expectedFormulaClose = ']';
                }
                else if (substring.startsWith('('))
                {
                    expectedFormulaClose = ')';
                }
                else
                {
                    let match = substring.match(/^begin\{[a-zA-Z0-9]+}/g);
                    if (match)
                    {
                        expectedFormulaClose = 'end' + match[0].substring(5);
                    }
                }
            }
            else if (substring.startsWith(expectedFormulaClose))
            {
                expectedFormulaClose = null;
            }
        }
    }

    if (isWaitingForCloseBracket)
    {
        openBracketIndices.splice(-1);
    }

    // Remove brackets that have no text between them
    if (excludeEmpty)
    {
        for (let i = 0; i < openBracketIndices.length; ++i)
        {
            if (openBracketIndices[i] + 1 === closeBracketIndices[i])
            {
                openBracketIndices.splice(i, 1);
                closeBracketIndices.splice(i, 1);
                blanksInFormulas.splice(i, 1);
                --i;
            }
        }
    }

    return [openBracketIndices, closeBracketIndices, blanksInFormulas];
}

export function ajaxSetDragAndDropQuestion(questionEle)
{
    const blanksPattern = /((?!\\).{1}|^)\[([^\\]|([^\]](.)*?[^\\]))\]/g;
    const inputField = questionEle.find('.content-title .input-field');
    let title = inputField.html().trim();
    const oldTitle = title;
    const optionItems = questionEle.find('.option-item');
    optionItems.addClass('pending-update');

    if (inputField.text().trim().length === 0)
    {
        title = '';
        inputField.html('').attr('data-current-val', '');
    }
    else
    {
        title = trimWhitespaceAndBreaks(inputField);
    }

    // Set the saved question title to have [......] instead of the actual answers
    title = title.replace('][', '] [').replace(blanksPattern, '$1[......]');

    if (title !== oldTitle)
    {
        loadPreview(questionEle);
    }

    const dataUpdateId = Math.floor(Math.random() * 1000000);
    questionEle.attr('data-update-id', dataUpdateId);

    // Save drag and drop question answers create in the UI
    let optionsToSet = [];
    let optionsModified = false;
    questionEle.find('.option-item.pending-update .input-field').each(function(i, item)
    {
        let optionTitle = $(item).html().trim();
        if ($(item).text().trim().length === 0 && $(item).html() !== '')
        {
            optionTitle = '';
            optionsModified = true;
        }
        else
        {
            const prevOptionTitle = optionTitle;
            optionTitle = trimWhitespaceAndBreaks($(item));

            if (optionTitle !== prevOptionTitle)
            {
                optionsModified = true;
            }
        }

        $(item).html(optionTitle).attr('data-current-val', optionTitle);

        optionsToSet.push({ 'value': optionTitle, 'correct': $(item).parent().hasClass('correct') });
    });

    if (optionsModified)
    {
        updateQuestionFromAnswers(inputField[0], questionEle.find('.option-item .title.input-field'), null);
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        assessmentId: questionEle.attr('data-content-id'),
        classId: $('[name=classId]').val(),
        question: title,
        options: optionsToSet,
        dataUpdateId: dataUpdateId,
    };

    $.ajax(
    {
        url: '/content/authoring/options/set',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response, status, xhr)
        {
            if (response.dataUpdateId === questionEle.attr('data-update-id')) // Only update if this is the latest request
            {
                optionItems.each(function(i, item)
                {
                    const optionId = response.optionData[i]['id'];
                    $(item).attr('data-option-id', optionId);
                    $(item).find('.js-toggle-correct').attr('id', 'optionToggle' + optionId);
                    $(item).find('.title').attr('data-current-val', response.optionData[i]['value']);
                    $(item).removeClass('pending-update');
                });

                inputField.attr('data-current-val', title);
            }
        },
        error: function(xhr, status, error) {},
    });
}