import { BalloonEditor as BalloonEditorBase } from '@ckeditor/ckeditor5-editor-balloon';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { Link } from '@ckeditor/ckeditor5-link';
import { List } from '@ckeditor/ckeditor5-list';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import MathType from '@wiris/mathtype-ckeditor5';

export default class BalloonEditor extends BalloonEditorBase {}

BalloonEditor.builtinPlugins = [
    Essentials,
    Bold,
    Underline,
    Italic,
    Link,
    List,
    Paragraph,
    MathType
];

BalloonEditor.defaultConfig = {
    fontFamily:
    {
        options: [
            'Work Sans, Roboto, Helvetica, sans-serif'
        ],
        supportAllValues: true
    },
    toolbar:
    {
        items: [
            'bold',
            'underline',
            'italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'link',
            '|',
            'MathType',
            'ChemType'
        ]
    },
    licenseKey: 'RVZFLzNVbmRNWmpLY2h4anNEZmJ3andrd1c3MW1XUHFvYlpLQ1RaamlnRGFuY2JhcnlVaVh2dVV6TWRQLU1qQXlNekV4TVRFPQ==',
    language: 'en'
};