import { Quitch } from '../../quitch';
import { telemetryTrackEvent } from '../../superuser/mixpanel';
import { updateClassPublishStatus } from './index';
import { trans, transChoice } from '../../common';
import { extractTextFromPdf } from '../pdf';


$(document).on('click', '.js-ai-create', function()
{
    if ($(this).hasClass('inactive-option'))
    {
        return;
    }

    $('.js-ai-create').prop('disabled', true);
    let classId = $('.js_class_details').data('class-id') || $('#js-class-id').val();
    $.ajax(
    {
        url: '/content/ai/modal',
        type: 'GET',
        dataType: 'json',
        data: { classId: classId },
        success: function(response)
        {
            $('.js-ai-create').prop('disabled', false);
            $('#ai-content-modal').remove();
            $(response.view).modal(
            {
                cache: false,
            }, 'show');
        },
        error: function(xhr, status, error)
        {
            $('.edit-content-button').prop('disabled', false);
        },
    });
});

function checkAiSubmitBtnDisabled()
{
    const prompt = $('#ai-prompt-input .input-field').text().trim();
    $('#ai-prompt-input').toggleClass('limit-reached', prompt.length > 45000);

    if (prompt.length > 0 && prompt.length <= 45000)
    {
        $('#ai-submit-btn').removeClass('disabled');
    }
    else
    {
        $('#ai-submit-btn').addClass('disabled');
        $('#ai-prompt-input .counter').text(prompt.length.toLocaleString() + '/45,000');
    }
}

$(document).on('shown.bs.modal', '#ai-content-modal', function()
{
    $('#ai-prompt-input .input-field').focus();
    $('#ai-submit-btn').addClass('disabled');

    let currentJobId = $('#ai-content-modal input[name="aiContentJobId"]').val();
    if (currentJobId.length > 0)
    {
        refreshQuestions([], currentJobId, 0, 10, true);
    }

});

$(document).on('click', '#close-ai-modal-btn', function()
{
    telemetryTrackEvent('ep-closed-ai-content-modal');
    let jobId = $('input[name="aiContentJobId"]').val();

    if (jobId)
    {
        Quitch.modal(
            {
                title: trans('content.areYouSureExit'),
                message: trans('content.importNotSaved'),
                type: 'confirm_custom',
                cssClass: 'modal-dialog-centered modal-dialog-sm',
                okText: 'base.exit',
                cancelText: 'base.cancel',
            },
            function(response)
            {
                if (response === true)
                {
                    $('#ai-content-modal').modal('hide');
                    cancelAiJob(jobId);
                }
            }
        );
    }
    else
    {
        $('#ai-content-modal').modal('hide');
    }
});

$(document).on('input keydown paste', '#ai-prompt-input .input-field', function(e)
{
    // check enter key pressed without shift key
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey)
    {
        e.preventDefault();
        e.stopPropagation();
        $('#ai-submit-btn').click();
    }
    else
    {
        $('#ai-prompt-tab .sub-text').show();
        $('.ai-error-container').empty();
    }

    checkAiSubmitBtnDisabled();
});

$(document).on('click', '#ai-prompt-input, #ai-prompt-input-container', function(e)
{
    if (e.target !== e.currentTarget)
    {
        return;
    }

    $(this).find('.input-field').focus();
});

$(document).on('click', '#ai-add-document-btn', function(e)
{
    $('#add-document-modal').modal('show');
    $('#add-doc-done-btn').attr('data-doc-dest', '#ai-doc-upload');
});

$(document).on('change', '#ai-doc-upload', function(e)
{
    try
    {
        $('.ai-error-container').empty();
        const uploadPath = $(this).val();

        if (uploadPath && uploadPath.match(/\.pdf$/i))
        {
            extractTextFromPdf($(this).siblings('.doc-asset-url').val()).then(function(text)
            {
                if (text)
                {
                    submitAiRequest(text.substring(0, 45000), uploadPath, $('[name=aiDocFilename]').val());
                }
                else
                {
                    console.error('Error extracting text from PDF: no text found');
                    showError('content.genericAiErrorTitle', 'content.genericAiErrorDesc');
                }
            }).catch(function(e)
            {
                console.error('Error extracting text from PDF: ', e);
                showError('content.docAiSecureErrorTitle', 'content.docAiSecureErrorDesc');
            });
        }
        else if (uploadPath)
        {
            submitAiRequest(null, uploadPath, $('[name=aiDocFilename]').val());
        }
    }
    catch (e)
    {
        console.error('Error extracting text from resource: ', e);
        showError('content.genericAiErrorTitle', 'content.genericAiErrorDesc');
    }
});

$(document).on('click', '#ai-submit-btn', function(e)
{
    let prompt = $('#ai-prompt-input .input-field').text().trim();
    if (prompt.length === 0 || $(this).hasClass('disabled'))
    {
        return;
    }

    submitAiRequest(prompt);
});

function submitAiRequest(prompt, docPath = null, docFilename = null)
{
    $('#ai-prompt-tab .tab-container').animate(
    {
        scrollTop: $('#ai-prompt-tab .tab-container').prop('scrollHeight')
    }, 500);

    let classId = $('.js_class_details').data('class-id') || $('#js-class-id').val();
    $('#ai-prompt-input').addClass('disabled');
    $('#ai-prompt-tab .sub-text').hide();
    updateContentLoader(1, 10);
    $('.ai-content-loader').show();

    $.ajax(
    {
        url: '/content/ai/questions/generate',
        type: 'POST',
        dataType: 'json',
        data: { _token: $('meta[name="csrf-token"]').attr('content'), prompt: prompt, docPath, docFilename, classId: classId },
        success: function(response)
        {
            if (response.jobId)
            {
                $('input[name="aiContentJobId"]').val(response.jobId);
                refreshQuestions([], response.jobId, 2000);
            }
            else
            {
                setTab('#ai-prompt-tab');
                $('#ai-prompt-tab .sub-text').hide();
                showError('content.genericAiErrorTitle', 'content.genericAiErrorDesc');
            }
        },
        error: function(xhr, status, error)
        {
            setTab('#ai-prompt-tab');
            $('#ai-prompt-tab .sub-text').hide();
            showError('content.genericAiErrorTitle', 'content.genericAiErrorDesc');
        },
    });
}

$(document).on('click', '#ai-content-back', function(e)
{
    let currentBreadcrumb = $('#ai-content-modal .breadcrumb-item.active');
    currentBreadcrumb.removeClass('active');
    currentBreadcrumb = currentBreadcrumb.prev();
    if (currentBreadcrumb.length > 0)
    {
        let currentTab = currentBreadcrumb.attr('data-tab-id');
        setTab(currentTab);
        $(currentTab).addClass('active');
    }
    else
    {
        $('#ai-content-modal').modal('hide');
    }
});

$(document).on('click', '#continue-organise-btn', function(e)
{
    if ($('input[name="aiContentJobId"]').val())
    {
        setTab('#ai-organise-tab');
    }
    else
    {
        setTab('#ai-prompt-tab');
        showError('content.genericAiErrorTitle', 'content.genericAiErrorDesc');
    }
});

function onAiTopicSelectAction()
{
    const topicId = $('#js-ai-topic-id').val();
    if (topicId === 'custom')
    {
        $('.js-ai-section-item').removeClass('active');
        $('#js-ai-section-id').val('').change();
        $('.js-ai-section-select .input-wrapper input').val('').prop('disabled', false);
        $('.js-ai-section-select').removeClass('disabled').show();
    }
    else if (topicId)
    {
        const sectionId = $(`.js-ai-topic-item[data-target="${topicId}"]`).data('section-id');
        const sectionItem = $(`.js-ai-section-item[data-target="${sectionId}"]`);
        sectionItem.addClass('active');
        $('.js-ai-section-select .input-wrapper input').val(sectionItem.text()).prop('disabled', true);
        $('#js-ai-section-id').val(sectionId).change();
        $('.js-ai-section-select').addClass('disabled').show();
    }
    else
    {
        $('.js-ai-section-select').hide();
    }
}

$(document).on('blur', '.js-ai-topic-select .input-wrapper input', function(e)
{
    onAiTopicSelectAction();
});

$(document).on('change', '#js-ai-topic-id', function(e)
{
    onAiTopicSelectAction();
});

$(document).on('keypress', '.js-ai-topic-select .input-wrapper input', function(e)
{
    if (e.which === 13) // Enter key
    {
        if ($('#js-ai-topic-id').val())
        {
            $('.js-ai-section-select .input-wrapper input').focus();
        }
    }
});

$(document).on('keypress', '.js-ai-section-select .input-wrapper input', function(e)
{
    if (e.which === 13) // Enter key
    {
        if ($('.js-ai-section-select .input-wrapper input').val())
        {
            $('#ai-complete-import-btn').click();
        }
    }
});

$(document).on('change', '#js-ai-section-id', function(e)
{
    $('#ai-complete-import-btn').prop('disabled', !$(this).val());
});

$(document).on('click', '#ai-complete-import-btn', function(e)
{
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        topicId: $('#js-ai-topic-id').val(),
        topicName: $('.js-ai-topic-select input[name="topic-name"]').val(),
        sectionId: $('#js-ai-section-id').val(),
        sectionName: $('.js-ai-section-select input[name="section-name"]').val(),
        jobId: $('input[name="aiContentJobId"]').val()
    };

    $.ajax(
    {
        url: '/content/ai/questions/save',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('input[name="aiContentJobId"]').val('');
            $('#ai-content-modal').modal('hide');
            Quitch.updateUrl({ section: response.topicId, feedback: 'createWithAi' }, 'ep-organised-ai-content-success', { 'status': 'success' });
            updateClassPublishStatus('modified');
        },
        error: function(xhr, status, error)
        {
            console.error(xhr, status, error);
            $('#ai-content-modal').modal('hide');
            Quitch.showSnackbar(trans('content.errorAddingQuestion'));
            telemetryTrackEvent('ep-organised-ai-content-failed', { 'status': 'failed', 'debug': xhr.responseJSON.message, 'error': error });
        },
    });
});

$(document).on('click', '.js-ai-question-remove', function(e)
{
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        stagedQuestionId: $(this).parents('.ai-result-card').attr('data-staged-content-id')
    };

    $.ajax(
    {
        url: '/content/ai/question/remove',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $(`.ai-result-card[data-staged-content-id="${values.stagedQuestionId}"]`).remove();
            toggleOrganiseBtn();
            telemetryTrackEvent('ep-removed-ai-question-success', { 'status': 'success' });
            Quitch.showSnackbar(trans('content.removedQuestion'));
        },
    });
});

$(document).on('click', '.js-ai-answer-remove', function(e)
{
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        stagedAnswerId: $(this).parents('.answer-item').attr('data-staged-answer-id')
    };

    $.ajax(
    {
        url: '/content/ai/answer/remove',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            let answerEle = $(`.answer-item[data-staged-answer-id="${values.stagedAnswerId}"]`);
            let aiCardEle = answerEle.parents('.ai-result-card');
            answerEle.remove();
            validateAnswers(aiCardEle);
            toggleOrganiseBtn();
            telemetryTrackEvent('ep-removed-ai-answer-success', { 'status': 'success' });
            Quitch.showSnackbar(trans('content.removedAnswer'));
        },
    });
});

$(document).on('focusin', '.ai-result-card .answer-container .title', function(e)
{
    $(this).parents('.answer-item').addClass('active');
});

$(document).on('focusout', '.ai-result-card .answer-container .title', function(e)
{
    $(this).parents('.answer-item').removeClass('active');

    if ($(this).attr('data-current-val') === $(this).text())
    {
        return;
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        stagedAnswerId: $(this).parents('.answer-item').attr('data-staged-answer-id'),
        title: $(this).text()
    };

    let valid = validateAnswers($(this).parents('.ai-result-card'));
    let answerEle = $(this);
    toggleOrganiseBtn();

    if (valid)
    {
        $.ajax(
        {
            url: '/content/ai/answer/edit',
            type: 'POST',
            dataType: 'json',
            data: values,
            success: function(response)
            {
                answerEle.attr('data-current-val', values['title']);
                telemetryTrackEvent('ep-changed-ai-answer-text-success', { 'status': 'success' });
                Quitch.showSnackbar(trans('content.updatedAnswer'));
            },
        });
    }
});

$(document).on('change', '.js-ai-answer-correct-toggle', function(e)
{
    let questionType = $(this).parents('.ai-result-card').attr('data-question-type');
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        stagedQuestionId: $(this).parents('.ai-result-card').attr('data-staged-content-id'),
        stagedAnswerId: $(this).parents('.answer-item').attr('data-staged-answer-id'),
        questionType: questionType,
        correct: $(this).is(':checked') ? 1 : 0
    };

    $(this).parents('.answer-container').toggleClass('correct', $(this).is(':checked'));

    if (questionType === 'true-false')
    {
        // toggle other answer
        $(this).parents('.answer-item').siblings('.answer-item')
            .find('.answer-container').toggleClass('correct', !$(this).is(':checked'))
            .find('.js-ai-answer-correct-toggle')
            .prop('checked', !$(this).is(':checked'));
    }
    else if (questionType === 'multi-choice')
    {
        // toggle other answers off
        $(this).parents('.answer-item').siblings('.answer-item')
            .find('.answer-container').removeClass('correct')
            .find('.js-ai-answer-correct-toggle')
            .prop('checked', false);
    }

    validateAnswers($(this).parents('.ai-result-card'));
    toggleOrganiseBtn();

    $.ajax(
    {
        url: '/content/ai/answer/edit',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            telemetryTrackEvent('ep-changed-ai-answer-correct-success', { 'status': 'success' });
            Quitch.showSnackbar(trans('content.updatedAnswer'));
        },
    });
});

$(document).on('input focusout', '.js-ai-question-text-input', function(e)
{
    if ($(this).attr('data-current-val') === $(this).text())
    {
        return;
    }

    let inputEle = $(this).parent();
    inputEle.removeClass('error');
    inputEle.nextAll('.js-text-input-errors:first').html('');
    let inputName = $(this).attr('name');

    let val = $(this).text().trim();
    let inputError = null;
    let inputErrorClass = 'error-msg';

    switch (inputName)
    {
        case 'title':
            if (val.length < 1)
            {
                inputError = trans('content.errorQuestionRequired');
            }

            if (val.length > 500)
            {
                inputError = trans('content.errorQuestionCharacterLimit');
            }
            break;
        case 'hint':
            if (val.length > 500)
            {
                inputError = trans('content.errorHintCharacterLimit');
            }
            break;
        case 'explanation':
            if (val.length > 500)
            {
                inputErrorClass += ' char-limit-exceeded';
                inputError = val.length + ' / 500';
            }
            break;
    }

    if (inputError)
    {
        inputEle.addClass('error');
        inputEle.nextAll('.js-text-input-errors:first').html(`<p class="${inputErrorClass}">${inputError}</p>`);
    }

    toggleOrganiseBtn();

    if (!inputError && e.type === 'focusout')
    {
        let values = {
            _token: $('meta[name="csrf-token"]').attr('content'),
            stagedQuestionId: $(this).parents('.ai-result-card').attr('data-staged-content-id'),
            [inputName]: val
        };

        $.ajax(
        {
            url: '/content/ai/question/edit',
            type: 'POST',
            dataType: 'json',
            data: values,
            success: function(response)
            {
                inputEle.find('.js-ai-question-text-input')
                    .attr('data-current-val', values[inputName])
                    .attr('data-placeholder', '');
                if ((inputName === 'hint' || inputName === 'explanation') && val.length === 0)
                {
                    inputEle.nextAll('.js-text-input-errors:first').remove();
                    inputEle.remove();
                }
                telemetryTrackEvent('ep-updated-ai-question-input-success', { 'status': 'success', 'input': inputName });
                Quitch.showSnackbar(trans('content.updatedQuestion'));
            },
        });
    }
});

$(document).on('click', '.ai-result-card .answer-container', function(e)
{
    if (e.target !== e.currentTarget)
    {
        return;
    }

    $(this).find('.title').focus();
});

$(document).on('click', '.js-ai-question-text-input~.js-delete-btn', function(e)
{
    $(this).parent().hide();
    $(this).siblings('.js-ai-question-text-input').text('').blur();
});

$(document).on('click', '.add-answer-btn', function(e)
{
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        stagedQuestionId: $(this).parents('.ai-result-card').attr('data-staged-content-id'),
        title: trans('content.newAnswer'),
        correct: 0
    };

    let answersEle = $(this).parent().find('ul.answers');

    $.ajax(
    {
        url: '/content/ai/answer/add',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            answersEle.append(
                `<li class="answer-item" data-staged-answer-id="${response.stagedAnswerId}">
                        <div class="answer-container">
                            <span class="title" contenteditable="true" data-placeholder="${trans('content.newAnswer')}" data-current-val="${values['title']}">${values['title']}</span>
                            <span class="answer-control">
                                <span class="toggle-button toggle-slide">
                                    <input id="answerControl${response.stagedAnswerId}" class="js-ai-answer-correct-toggle" name="answerCheck" type="checkbox">
                                    <label for="answerControl${response.stagedAnswerId}"></label>
                                </span>
                                <span class="js-ai-answer-remove delete-btn material-icons-round" title="${trans('content.deleteBtn')}" data-toggle="tooltip" data-placement="bottom">close</span>
                            </span>
                        </div>
                    </li>`
            );

            $(`.answer-item[data-staged-answer-id="${response.stagedAnswerId}"] .title`).text('').focus();
            telemetryTrackEvent('ep-added-ai-answer-success', { 'status': 'success' });
            Quitch.showSnackbar(trans('content.addedAnswer'));
        },
    });
});

function validateAnswers(aiCard)
{
    let answerErrors = aiCard.find('.js-answer-errors');
    answerErrors.html('');
    let valid = true;
    let correctCount = 0;
    let lengthErr = false;
    let emptyErr = false;
    let answersText = [];

    aiCard.find('.answer-item').each(function()
    {
        let answer = $(this).find('.title').text();
        let correct = $(this).find('.js-ai-answer-correct-toggle').is(':checked');

        if (answer.length > 300)
        {
            lengthErr = true;
        }

        if (answer.trim().length === 0)
        {
            emptyErr = true;
        }

        if (correct)
        {
            correctCount++;
        }

        answersText.push(answer.trim());
    });

    if (answersText.length > [...new Set(answersText)].length)
    {
        answerErrors.append(`<p class="error-msg">${trans('content.errorDuplicateAnswers')}</p>`);
        valid = false;
    }

    if (correctCount === 0)
    {
        answerErrors.append(`<p class="error-msg">${trans('content.errorSelectOneCorrect')}</p>`);
    }

    if (lengthErr)
    {
        answerErrors.append(`<p class="error-msg">${trans('content.errorAnswerCharacterLimit')}</p>`);
        valid = false;
    }

    if (emptyErr)
    {
        answerErrors.append(`<p class="error-msg">${trans('content.errorAnswerEmpty')}</p>`);
        valid = false;
    }

    return valid;
}

function toggleOrganiseBtn()
{
    $('#continue-organise-btn').attr('disabled', $('.ai-result-card .error-msg').length > 0);
}

function setTab(tabSelector)
{
    $('.ai-error-container').empty();
    let prevTab = $('#ai-content-modal .breadcrumb-item.active').attr('data-tab-id');
    $('#ai-content-modal .breadcrumb-item').removeClass('active');
    $(`#ai-content-modal .breadcrumb-item[data-tab-id="${tabSelector}"]`).addClass('active');
    $('#ai-content-modal .tabs .tab').removeClass('active');

    if (tabSelector === '#ai-prompt-tab')
    {
        let jobId = $('input[name="aiContentJobId"]').val();
        $('input[name="aiContentJobId"]').val('');
        if (jobId)
        {
            cancelAiJob(jobId);
        }

        $('#ai-prompt-input').removeClass('disabled');
        $('#ai-prompt-tab .sub-text').show();
        $('.ai-content-loader').hide();
    }

    $(tabSelector).addClass('active');
    telemetryTrackEvent('ep-changed-tab-ai-content-modal', { 'prevTab': prevTab, 'currentTab': tabSelector });
}

function updateContentLoader(index, total)
{
    $('.ai-content-loader .title').text(trans('content.aiLoaderTitle', { 'index': index, 'total': total }));
}

function refreshQuestions(stagedIds, jobId, retryDelay, maxQuestions = 10, fastMode = false)
{
    $.ajax(
    {
        url: '/jobs/get-status/' + jobId,
        type: 'GET',
        dataType: 'json',
        success: function(response)
        {
            if (response.job.meta && response.job.meta.staged > stagedIds.length)
            {
                getQuestionsAjax(jobId, stagedIds).then(response =>
                {
                    if (!$('#ai-results-tab').hasClass('active'))
                    {
                        $('#ai-results').empty();
                        $('#continue-organise-btn').hide();
                        setTab('#ai-results-tab');
                    }

                    if (response.stagedIds.length > 0 && response.views.length > 0)
                    {
                        let index = 0;
                        let intervalId = setInterval(function()
                        {
                            if (index === response.views.length)
                            {
                                clearInterval(intervalId);
                                refreshQuestions(stagedIds, jobId, retryDelay, maxQuestions, fastMode);
                            }
                            else
                            {
                                // scroll down after appending question
                                $('#ai-results').append(response.views[index])
                                    .parent('.tab-container').animate(
                                    {
                                        scrollTop: $('#ai-results').prop('scrollHeight')
                                    }, fastMode ? 500 : 1000);

                                stagedIds.push(response.stagedIds[index]);
                                index++;

                                if (stagedIds.length < maxQuestions)
                                {
                                    updateContentLoader(stagedIds.length + 1, maxQuestions);
                                }
                                else
                                {
                                    $('.ai-content-loader').hide();
                                }
                            }
                        }, fastMode ? 750 : 1500);
                    }
                }).catch(err =>
                {
                    console.error(err);
                    telemetryTrackEvent('ep-get-ai-content-error', { 'error': err, 'questionsCount': stagedIds ? stagedIds.length : 0 });
                });
            }
            else if (response.job.status === 'running')
            {
                window.setTimeout(function()
                {
                    refreshQuestions(stagedIds, jobId, retryDelay, maxQuestions, fastMode);
                }, retryDelay);
            }
            else if (response.job.status === 'created')
            {
                $('#ai-results-tab .ai-content-loader').hide();
                $('#continue-organise-btn').show();
                $('.tab-container').animate(
                {
                    scrollTop: $('#ai-results').prop('scrollHeight')
                }, 100);
                telemetryTrackEvent('ep-generate-ai-content-created', { 'status': 'created', 'questionsCount': stagedIds.length });
            }
            else
            {
                if (stagedIds.length === 0)
                {
                    // Go back to prompt if not questions generated so far
                    setTab('#ai-prompt-tab');
                    $('#ai-prompt-tab .sub-text').show();
                    telemetryTrackEvent('ep-generate-ai-content-failed', { 'status': 'failed', 'questionsCount': stagedIds.length });
                }
                else
                {
                    $('#ai-results-tab .ai-content-loader').hide();
                    $('#continue-organise-btn').show();
                    telemetryTrackEvent('ep-generate-ai-content-partial', { 'status': 'partial', 'questionsCount': stagedIds.length });
                }

                Quitch.showCallToAction('.ai-error-container', 'ai-content-error',
                {
                    type: 'error',
                    showIcon: true,
                    title: response.job.error.params.title ? response.job.error.params.title : 'Failed',
                    description: response.job.error.params.description ? response.job.error.params.description : 'content.genericAiErrorDesc'
                });
            }
        },
        error: function(xhr, status, error)
        {
            setTab('#ai-prompt-tab');
            $('#ai-prompt-tab .sub-text').hide();
            Quitch.showCallToAction('.ai-error-container', 'ai-content-error',
            {
                type: 'error',
                showIcon: true,
                title: 'content.genericAiErrorTitle',
                description: 'content.genericAiErrorDesc'
            });
            telemetryTrackEvent('ep-generate-ai-content', { 'status': 'failed', 'questionsCount': stagedIds.length });
        },
    });
}

function getQuestionsAjax(jobId, stagedIds)
{
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['jobId'] = jobId;
    values['stagedIds'] = stagedIds;

    return $.ajax(
    {
        url: '/content/ai/questions',
        type: 'GET',
        dataType: 'json',
        data: values
    });
}

function cancelAiJob(jobId)
{
    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        jobId: jobId,
    };
    $.ajax(
    {
        url: '/content/ai/questions/cancel',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            telemetryTrackEvent('ep-organised-ai-content-canceled', { 'status': 'canceled' });
        },
        error: function(xhr, status, error) {},
    });
}

function showError(title, description)
{
    Quitch.showCallToAction('.ai-error-container', 'ai-content-error',
    {
        type: 'error',
        showIcon: true,
        title: title,
        description: description
    });
}