import { capitalise, trans } from '../../common';
import { loadPreview, loadResourcePreview } from './preview';
import { trimWhitespaceAndBreaks, updateTopicIncompleteCounter, validateAndUpdateQuestionIncompleteFlag, validateAndUpdateResourceIncompleteFlag } from './common';

$(document).on('click', '.content-details-header, .content-details-footer', function(e)
{
    if ($('.content-authoring-wrapper').hasClass('expanded-sidebar'))
    {
        $('.content-authoring-wrapper').removeClass('expanded-sidebar');
        // Close opened link settings if input is empty or invalid
        const linkSetting = $('.content-details-body .optional-setting.link');
        if (!linkSetting.hasClass('collapsed'))
        {
            if (linkSetting.find('input').val() === '' || linkSetting.find('input.error').length)
            {
                linkSetting.find('.add-remove-btn').click();
            }
        }
    }
    else
    {
        const selectedContent = $('.content-authoring-content.selected');
        if (selectedContent.attr('data-schedule-status') === 'draft')
        {
            initialiseContentDetails(selectedContent);
        }
    }
});

$(document).on('click', '.optional-setting:not(.image,.document,.link,.video) .add-remove-btn', function(e)
{
    const optionalSetting = $(this).parents('.optional-setting');
    const setting = optionalSetting.attr('data-setting');
    optionalSetting.find('.body').slideToggle(300, function()
    {
        // remove inline display style after animation is complete
        $(this).removeAttr('style');
        const addRemoveBtn = optionalSetting.find('.add-remove-btn');

        if (optionalSetting.hasClass('collapsed'))
        {
            optionalSetting.removeClass('collapsed');
            addRemoveBtn.attr('title', trans('content.remove'));
            optionalSetting.find('.input-field').text('').focus();
        }
        else
        {
            optionalSetting.addClass('collapsed');
            addRemoveBtn.attr('title', trans('content.add'));
            optionalSetting.find('.input-field').text('').focusout();
        }
    });
});

$(document).on('click', '.optional-setting.image .add-remove-btn', function(e)
{
    const optionalSetting = $(this).parents('.optional-setting');
    const contentType = $('.content-authoring-sidebar .content-details').attr('data-content-type');
    if (optionalSetting.hasClass('collapsed') || contentType === 'resource')
    {
        $('#add-image-done-btn').attr('data-img-dest', `#${contentType}-image-path`).click(function(e)
        {
            // Focusing as Safari doesn't do it automatically, preventing the document.activeElement from being set to the done button
            $(this).focus();
        });

        $('#add-image-modal').one('hide.bs.modal', function(e)
        {
            $('#add-image-done-btn').unbind('click');
            // check if the done button was clicked to close the modal
            if ($(document.activeElement).attr('id') === 'add-image-done-btn' && contentType === 'question')
            {
                toggleImageOption(optionalSetting);
            }
        }).modal('show');
    }
    else
    {
        toggleImageOption(optionalSetting);
    }
});

$(document).on('click', '.optional-setting.link .add-remove-btn', function(e)
{
    const optionalSetting = $(this).parents('.optional-setting');
    optionalSetting.find('.body').slideToggle(300, function()
    {
        // remove inline display style after animation is complete
        $(this).removeAttr('style');
        const addRemoveBtn = optionalSetting.find('.add-remove-btn');
        if (optionalSetting.hasClass('collapsed'))
        {
            optionalSetting.removeClass('collapsed');
            addRemoveBtn.attr('title', trans('content.remove'));
            optionalSetting.find('[name=url]').val('').keyup().focus();
        }
        else
        {
            optionalSetting.addClass('collapsed');
            addRemoveBtn.attr('title', trans('content.add'));
            optionalSetting.find('[name=urlText]').text('');
            optionalSetting.find('[name=url]').val('');

            updateResourceSettings($('.resource-details [name=resourceId]').val(), { url: '', urlText: '' });
            $('.content-authoring-content.selected .js-add-link').show().siblings('.dropdown-divider').show();
        }
    });
});

$(document).on('change', '.resource-details [name=url]', function(e)
{
    const urlTextInput = $('.url-text-input');
    if ($(this).hasClass('valid'))
    {
        updateResourceSettings($('.resource-details [name=resourceId]').val(), { url: $(this).val() });
        $('.content-authoring-content.selected .js-add-link').hide().siblings('.dropdown-divider').hide();

        if (urlTextInput.is(':hidden'))
        {
            urlTextInput.slideToggle(300, function()
            {
                // remove inline display style after animation is complete
                $(this).removeAttr('style');
            });
        }
    }
    else if ($(this).val() === '')
    {
        updateResourceSettings($('.resource-details [name=resourceId]').val(), { url: $(this).val() });
        urlTextInput.hide();
        $('.content-authoring-content.selected .js-add-link').show().siblings('.dropdown-divider').show();
    }
    else if ($(this).hasClass('error'))
    {
        urlTextInput.hide();
        $('.content-authoring-content.selected .js-add-link').show().siblings('.dropdown-divider').show();
    }
});

$(document).on('change', '#resource-image-path', function(e)
{
    updateResourceSettings($('.resource-details [name=resourceId]').val(),
    {
        type: 'image',
        filePath: $('#resource-image-path').val(),
        originalFileName: $('#resource-image-filename').val(),
    });
});

$(document).on('click', '.optional-setting.document .add-remove-btn', function(e)
{
    $('#add-doc-done-btn').attr('data-doc-dest', '#resource-doc-path');
    $('#add-document-modal').addClass('pdf-only').modal('show');
});

$(document).on('change', '#resource-doc-path', function(e)
{
    updateResourceSettings($('.resource-details [name=resourceId]').val(),
    {
        type: 'document',
        filePath: $('#resource-doc-path').val(),
        originalFileName: $('#resource-doc-filename').val(),
    });
});

$(document).on('click', '.optional-setting.video .add-remove-btn', function(e)
{
    $('#add-video-done-btn').attr('data-video-dest', '#resource-video-url');
    $('#add-video-modal [name=video-link]').val('').keyup();
    $('#add-video-modal').modal('show');
});

$(document).on('change', '#resource-video-url', function(e)
{
    updateResourceSettings($('.resource-details [name=resourceId]').val(),
    {
        type: 'video',
        filePath: $(this).val(),
    });
});

$(document).on('change', '.js-question-settings-dropdown-input', function(e)
{
    updateQuestionSettings($('.question-details [name=assessmentId]').val(),
    {
        [$(this).attr('name')]: $(this).val()
    });
});

$(document).on('change', '.js-content-settings-toggle-input', function(e)
{
    $(this).parents('.input-group').find('.toggle-status').text(trans($(this).prop('checked') ? 'content.on' : 'content.off'));
    let value = $(this).prop('checked') ? 1 : 0;
    const contentType = $('.content-authoring-sidebar .content-details').attr('data-content-type');
    let contentId = $('.content-details [name=resourceId], .content-details [name=assessmentId]').val();

    if ($(this).attr('name') === 'imagePosition')
    {
        value = $(this).prop('checked') ? 'cover' : 'inline';
        $('[data-content-id=' + contentId + '] [name=imagePosition]').val(value);
        loadPreview();
    }

    if (contentType === 'question')
    {
        updateQuestionSettings(contentId,
        {
            [$(this).attr('name')]: value
        });

        if ($(this).attr('name') === 'shuffleAnswers')
        {
            $('[data-content-id=' + contentId + '] .content-title').data('shuffle', value);
            loadPreview();
        }
    }
    else
    {
        updateResourceSettings($('.resource-details [name=resourceId]').val(),
        {
            [$(this).attr('name')]: value
        });
    }
});

$(document).on('focusout', '.content-details .input-field', function(e)
{
    const contentType = $('.content-authoring-sidebar .content-details').attr('data-content-type');
    let inputText = $(this).html().trim();

    if ($(this).attr('data-current-val') === inputText)
    {
        return;
    }
    else
    {
        if ($(this).text().trim().length === 0)
        {
            inputText = '';
            $(this).html('');
        }
        else
        {
            inputText = trimWhitespaceAndBreaks($(this));
        }
        $(this).attr('data-current-val', inputText);
    }

    if (contentType === 'question')
    {
        updateQuestionSettings($('.question-details [name=assessmentId]').val(),
        {
            [$(this).attr('name')]: inputText
        });
    }
    else
    {
        updateResourceSettings($('.resource-details [name=resourceId]').val(),
        {
            [$(this).attr('name')]: inputText
        });
    }
});

$(document).on('click', '.content-details-quick-actions .dropdown-item', function(e)
{
    const setting = $(this).attr('data-setting');
    if ($('.content-authoring-wrapper').hasClass('expanded-sidebar'))
    {
        const input = $(this).parents('.content-authoring-content').find(`input[name=${$(this).attr('data-input-map')}]`).val();
        if (!input && $(`.optional-setting[data-setting=${setting}] .body`).is(':visible'))
        {
            // Setting empty and already opened, focus input
            $(`.optional-setting[data-setting=${setting}]`).find('.js-input-validator input, .input-field').first().focus();
        }
        else
        {
            $(`.optional-setting[data-setting=${setting}] .add-remove-btn`).click();
        }
    }
    else
    {
        $('.content-details').attr('data-open-context', setting);
        initialiseContentDetails($('.content-authoring-content.selected'), setting);
    }
});

$(document).on('keyup', '.setting-text-input', function(e)
{
    const charLimit = $(this).attr('data-limit');
    const charCount = $(this).text().length;
    if (charCount > charLimit)
    {
        $(this).addClass('error');
        if (!$(this).next('.char-limit-err').length)
        {
            $(this).after(`<div class="char-limit-err">${charCount}/${charLimit}</div>`);
        }
        else
        {
            $(this).next('.char-limit-err').text(`${charCount}/${charLimit}`);
        }
    }
    else
    {
        $(this).removeClass('error');
        $(this).next('.char-limit-err').remove();

    }
});

export function initialiseContentDetails(contentEle, context = null)
{
    if (contentEle.hasClass('question'))
    {
        initialiseQuestion(contentEle, context);
    }
    else if (contentEle.hasClass('resource'))
    {
        initialiseResource(contentEle, context);
    }
}

/**
 * Initialises the question details sidebar
 * @param {jQuery} questionEle - The question element that was clicked
 * @param {string} context - The context in which the question was opened
 */
function initialiseQuestion(questionEle, context)
{
    $('.content-authoring-sidebar .content-details-header .title').text(trans('content.questionDetails'));
    $('.content-authoring-sidebar .content-details').attr('data-content-type', 'question');
    $('.content-authoring-sidebar .content-details-body').html('<div class="spinner-container"><span class="spinner"></span></div>');
    $('.content-authoring-wrapper').addClass('expanded-sidebar');

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        assessmentId: questionEle.attr('data-content-id'),
        classId: $('[name=classId]').val()
    };

    $.ajax(
    {
        url: '/content/authoring/question/details',
        type: 'GET',
        dataType: 'json',
        data: values,
        success: function(data)
        {
            $('.content-authoring-sidebar .content-details-body').html(data.view);
            if (context !== null)
            {
                $(`.optional-setting[data-setting=${context}] .add-remove-btn`).click();
            }
            // Trigger character limit validation
            $('.content-authoring-sidebar .setting-text-input').keyup();
        }
    });
}

function updateQuickActionBtn(contentEle, inputName, force = null)
{
    const dropdownItem = contentEle.find(`.content-details-quick-actions .dropdown-item[data-input-map=${inputName}]`);
    const setting = capitalise(dropdownItem.attr('data-setting'));
    const value = contentEle.find(`input[name=${inputName}]`).val();
    const textToggle = force === null ? !!value : !!force;
    dropdownItem.text(trans(textToggle ? 'content.remove' + setting : 'content.add' + setting));
}

/**
 * Updates the question settings
 * @param {number} assessmentId - The question ID
 * @param {object} settings - The settings to update
 */
function updateQuestionSettings(assessmentId, settings)
{
    if (assessmentId === undefined)
    {
        return;
    }

    const questionEle = $(`.content-authoring-content[data-content-id=${assessmentId}]`);
    for (const [key, value] of Object.entries(settings))
    {
        updateQuickActionBtn(questionEle, key, !!value);
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        assessmentId: assessmentId,
        classId: $('[name=classId]').val()
    };

    $.extend(values, settings);

    $.ajax(
    {
        url: '/content/authoring/question/edit',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(data)
        {
            $('.question-details .js-editor-name').text(trans('content.lastEditedBy', { name: data.question.lastEditedBy }));
            $('.question-details .js-updated-at').text(data.question.lastEditedAt);

            for (const [key, value] of Object.entries(settings))
            {
                questionEle.find(`[name=${key}]`).val(value).change();
                if (key === 'imgPath')
                {
                    questionEle.find('[name=imageUrl]').val(data.question.imgPath);
                }
            }

            validateAndUpdateQuestionIncompleteFlag(questionEle);
            updateTopicIncompleteCounter(questionEle.parents('.content-authoring-topic'));
            loadPreview();
        }
    });
}

/**
 * Initialises the resource details sidebar
 * @param {jQuery} resourceEle - The resource element that was clicked
 * @param {string} context - The context in which the resource was opened
 */
function initialiseResource(resourceEle, context)
{
    $('.content-authoring-sidebar .content-details-header .title').text(trans('content.resourceDetails'));
    $('.content-authoring-sidebar .content-details').attr('data-content-type', 'resource');
    $('.content-authoring-sidebar .content-details-body').html('<div class="spinner-container"><span class="spinner"></span></div>');
    $('.content-authoring-wrapper').addClass('expanded-sidebar');

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        resourceId: resourceEle.attr('data-content-id'),
        classId: $('[name=classId]').val()
    };

    $.ajax(
    {
        url: '/content/authoring/resource/details',
        type: 'GET',
        dataType: 'json',
        data: values,
        success: function(data)
        {
            $('.content-authoring-sidebar .content-details-body').html(data.view);
            if (context !== null)
            {
                $(`.optional-setting[data-setting=${context}] .add-remove-btn`).click();
            }
        }
    });
}

/**
 * Updates the resource settings
 * @param {number} resourceId - The resource ID
 * @param {object} settings - The settings to update
 */
function updateResourceSettings(resourceId, settings)
{
    if (resourceId === undefined)
    {
        return;
    }

    const resourceEle = $(`.content-authoring-content[data-content-id=${resourceId}]`);
    if ('url' in settings)
    {
        updateQuickActionBtn(resourceEle, 'url', !!settings['url']);
    }

    let values = {
        _token: $('meta[name="csrf-token"]').attr('content'),
        resourceId: resourceId,
        classId: $('[name=classId]').val(),
        topicId: $('.content-authoring-content.selected').parents('.content-authoring-topic').attr('data-id')
    };

    $.extend(values, settings);

    $.ajax(
    {
        url: '/content/resource/save',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(data)
        {
            for (const [key, value] of Object.entries(settings))
            {
                resourceEle.find(`[name=${key}]`).val(value).change();
                if (key === 'filePath')
                {
                    resourceEle.find('[name=assetUrl]').val(data.resource.assetUrl);
                }
            }

            validateAndUpdateResourceIncompleteFlag(resourceEle);
            updateTopicIncompleteCounter(resourceEle.parents('.content-authoring-topic'));
            loadResourcePreview(resourceEle);

            $('.resource-details .js-editor-name').text(trans('content.lastEditedBy', { name: data.resource.lastEditedBy }));
            $('.resource-details .js-updated-at').text(data.resource.lastEditedAt);
        }
    });
}

/**
 * Toggles the image option for a question
 * @param {jQuery} optionalSetting - The optional setting element that contains the image options
 */
function toggleImageOption(optionalSetting)
{
    optionalSetting.find('.body').slideToggle(300, function()
    {
        // remove inline display style after animation is complete
        $(this).removeAttr('style');
        const addRemoveBtn = optionalSetting.find('.add-remove-btn');
        if (optionalSetting.hasClass('collapsed'))
        {
            optionalSetting.removeClass('collapsed');
            addRemoveBtn.attr('title', trans('content.remove'));

            updateQuestionSettings($('.question-details [name=assessmentId]').val(),
            {
                imgPath: $('#question-image-path').val(),
                imgFilename: $('#question-image-filename').val(),
            });
        }
        else
        {
            optionalSetting.addClass('collapsed');
            addRemoveBtn.attr('title', trans('content.add'));
            optionalSetting.find('[name=imagePosition]').prop('checked', false);
            optionalSetting.find('.toggle-status').text(trans('content.off'));
            optionalSetting.find('[name=imageAltText]').text('');

            updateQuestionSettings($('.question-details [name=assessmentId]').val(),
            {
                imgPath: null,
                imgFilename: null,
                imagePosition: 'inline',
                imageAltText: null,
            });
        }
    });
}