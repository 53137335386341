import { getEmbeddedVideoFromUrl, trans } from '../common';

export function initialiseInputs()
{
    initialiseInputCharacterLimits();
}

/**
 * Input character limit
 */
$(document).on('keyup', '.inputCharacterLimit', function()
{
    let inputVal = $(this).val();
    let divLimit = false;
    //if its defined use rel selector if not use parent
    if (typeof $(this).data('rel') !== 'undefined')
    {
        let el = $(this).data('rel');
        if ($(el).length)
        {
            divLimit = $(el);
        }
    }
    if (divLimit === false)
    {
        divLimit = $(this)
            .parent()
            .find('.characterLimit');
    }

    let limit = divLimit.attr('data-limit');

    if (inputVal.length > limit)
    {
        divLimit.html(0);
        this.value = inputVal.substr(0, limit);
        return;
    }
    else
    {
        divLimit.html(limit - inputVal.length + 1);
    }

    let remLimit = divLimit.html();
    if (inputVal.length == limit)
    {
        divLimit.html(0);
        return;
    }
    if (inputVal.length === 0)
    {
        divLimit.html(limit);
        return;
    }

    let leftCount = remLimit - 1;
    if (leftCount <= 0)
    {
        return;
    }

    divLimit.html(leftCount);
});

$(document).on('keyup', '.js_data-counter', function()
{
    dataCounterChange(this);
});

$(document).on('textInput cut paste', '.js_data-counter', function(e, a, b)
{
    // This is called before the cut or paste changes the text of the input, so add a small delay to checking the value.
    window.setTimeout(dataCounterChange, 20, [this]);
});

function dataCounterChange(self)
{
    let divLimit;
    divLimit = $(self)
        .closest('.form-group')
        .find('span[data-counter]');
    if (divLimit.length === 0)
    {
        divLimit = $(self)
            .parent()
            .find('span[data-counter]');
    }

    const limit = divLimit.attr('data-counter');
    const inputVal = $(self).val().length > 0 ? $(self).val().trim() : $(self).text().trim();

    if (inputVal.length > limit)
    {
        divLimit.html(inputVal.length + '/' + limit).addClass('limit-reached');
        $(self).addClass('counter-limit-reached');
    }
    else
    {
        $(self).removeClass('counter-limit-reached');
        divLimit.html(inputVal.length + '/' + limit).removeClass('limit-reached');
    }
}

/**
 * Trigger input validation on clicking off input
 */
$(document).on('blur', '.js-input-validator:not(.text-input-with-button) input', function()
{
    let inputEl = $(this).parent();
    let validationEndpoint = inputEl.attr('data-endpoint');
    validateInputRequest(validationEndpoint, inputEl);
});

$(document).on('click', '.js-input-validator-btn', function()
{
    let inputEl = $(this).parents('.js-input-validator');
    if ($(this).hasClass('remove'))
    {
        inputEl.find('input').val('');
        updateInputStatus(inputEl, 'initial', true);
    }
    else
    {
        let validationEndpoint = inputEl.attr('data-endpoint');
        validateInputRequest(validationEndpoint, inputEl);
    }
});

function validateInputRequest(validationEndpoint, inputEl)
{
    const inputVal = inputEl.find('input').val();
    if (validationEndpoint && inputVal)
    {
        updateInputStatus(inputEl, 'loading');
        let token = $('meta[name="csrf-token"]').attr('content');
        let values = {
            _token: token,
            url: inputVal
        };
        $.ajax(
        {
            url: validationEndpoint,
            type: 'POST',
            dataType: 'json',
            data: values,
            success: function(response)
            {
                if (response.valid)
                {
                    updateInputStatus(inputEl, 'valid', true);
                }
                else
                {
                    updateInputStatus(inputEl, 'error', true);
                }
            },
            error: function()
            {
                updateInputStatus(inputEl, 'error', true);
            },
        });
    }
    else
    {
        updateInputStatus(inputEl, 'initial');
    }
}

$(document).on('keyup paste', '.js-input-validator input', function()
{
    updateInputStatus($(this).parent(), 'initial');
});

$(document).on('keydown', '.input-field.blur-on-enter', function(e)
{
    // check enter key pressed without shift key
    if (e.keyCode === 13 && !e.shiftKey)
    {
        e.preventDefault();
        $(this).blur();
    }
});

$(document).on('click', '.text-input', function(e)
{
    $(this).find('.input-field').focus();
});

$(document).on('focusin', '.text-input .input-field', function(e)
{
    $(this).parent().addClass('active');
});

$(document).on('focusout', '.text-input .input-field', function(e)
{
    $(this).parent().removeClass('active');
});

$(document).on('click', '.text-input .js-clear-btn', function(e)
{
    $(this).parent().find('.input-field').text('').focus();
});

/**
 * Content editable input allows pasting html text, override and only allow plain text
 */
$(document).on('paste', '.input-field[contenteditable=true]', function(e)
{
    e.preventDefault();
    let text = e.originalEvent.clipboardData.getData('text/plain');

    // Insert text at the current position of caret
    const range = document.getSelection().getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // small delay to allow the ui and text to be updated before scrolling to the caret
    window.setTimeout(scrollSelectionIntoView, 20);

    // Trigger keyup event to update character count
    $(this).keyup();
});

/**
 * Scroll the current selection into view by inserting a temporary anchor element and scrolling to it
 * Source: https://stackoverflow.com/a/67152280
 */
function scrollSelectionIntoView()
{
    // Get current selection
    const selection = window.getSelection().getRangeAt(0);

    if (!selection)
    {
        return;
    }

    // Sometimes if the editable element is getting removed from the dom you may get a HierarchyRequest error in safari
    if (selection.commonAncestorContainer === document)
    {
        return;
    }

    // Create an empty br that will be used as an anchor for scrolling
    const tempAnchorEl = document.createElement('br');

    // Put the br right after the caret position
    selection.insertNode(tempAnchorEl);

    if (!isElementVisibleInViewport(tempAnchorEl))
    {
        // Scroll to the br
        tempAnchorEl.scrollIntoView(
        {
            behavior: 'auto',
            block: 'nearest',
            inline: 'nearest'
        });
    }

    tempAnchorEl.remove();
}

export function isElementVisibleInViewport(el, partiallyVisible = false)
{
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth)) : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
}

export function updateInputStatus(inputEl, state, change = false)
{
    const inputField = inputEl.find('input');
    const inputButton = inputEl.hasClass('text-input-with-button') ? inputEl.find('.js-input-validator-btn') : null;
    const inputType = inputEl.attr('data-type') ? inputEl.attr('data-type') : 'text';

    inputField.removeClass('valid error loading');
    inputEl.find('.input-group-text').html('');
    inputEl.next('.error-msg').remove();

    if (inputType === 'video')
    {
        inputEl.next('iframe').remove();
    }

    switch (state)
    {
        case 'valid':
            inputEl.find('.input-group-text').html(`<i class="fa-solid fa-circle-check"></i>`);
            inputEl.removeClass('loading error');
            inputEl.addClass('input-group valid');
            inputEl.find('.input-group-append').show();
            if (inputType === 'video')
            {
                let embeddedUrl = getEmbeddedVideoFromUrl(inputField.val());
                if (embeddedUrl)
                {
                    inputEl.after(`<iframe class="video-preview-iframe" src="${embeddedUrl}"></iframe>`);
                }
            }
            if (inputButton)
            {
                inputButton.removeClass('add btn-primary').addClass('remove btn-secondary').text(trans('content.remove'));
            }
            break;
        case 'error':
            inputEl.find('.input-group-text').html(`<i class="fa-solid fa-circle-exclamation"></i>`);
            inputEl.removeClass('loading valid');
            inputEl.addClass('input-group error');
            inputEl.find('.input-group-append').show();
            if (inputButton)
            {
                inputButton.removeClass('add btn-primary').addClass('remove btn-secondary').text(trans('content.remove'));
            }
            if (inputType === 'video')
            {
                inputEl.after(`<p class="error-msg body-small">${trans('content.invalidVideoUrl')}</p>`);
            }

            break;
        case 'loading':
            inputEl.find('.input-group-text').html(`<i class="fa-solid fa-circle-notch fa-pulse"></i>`);
            inputEl.removeClass('valid error');
            inputEl.addClass('input-group loading');
            inputEl.find('.input-group-append').show();
            break;
        default:
            if (inputButton)
            {
                inputEl.removeClass('valid error loading');
                inputButton.removeClass('remove btn-secondary').addClass('add btn-primary').text(trans('content.add'));
            }
            else
            {
                inputEl.removeClass('input-group valid error loading');
                inputEl.find('.input-group-append').hide();
            }
    }

    if (change)
    {
        inputField.addClass(state).change();
    }
}

function initialiseInputCharacterLimits()
{
    if ($('.inputCharacterLimit').length)
    {
        $('.inputCharacterLimit').trigger('keyup');
    }
    if ($('.js_data-counter').length)
    {
        $('.js_data-counter').trigger('keyup');
    }
}