import { getTimeZone, trans, validateEmail } from './common';
import { telemetryTrackAndPerformUrlChange, telemetryTrackEvent } from './superuser/mixpanel';

$(document).ready(function()
{
    // trigger validate on page load as email might be prefilled
    $('.js-login-email-input').keyup();

    if ($('#create-workspace-form').length > 0)
    {
        $('#create-workspace-form input[name=timeZone]').val(getTimeZone());
    }
});

$(document).on('change keyup paste blur input', '.js-login-email-input', function(e)
{
    const email = $('.js-login-email-input').val();
    if (email.trim() && validateEmail(email))
    {
        let verifyButton = $('.js-login-email-verify');
        if (e.key === 'Enter' || e.keyCode === 13)
        {
            // enter pressed, check if we can submit to verify email
            if (!verifyButton.hasClass('hide'))
            {
                verifyButton.click();
            }
        }
    }
});

$(document).on('click', '.js-login-email-verify', function(e)
{
    e.preventDefault();

    $('.js-email-validation-error').html('');
    $('.email').removeClass('has-error');

    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['email'] = $('.email').val();
    const url = $(this)[0].formAction;
    $.ajax(
    {
        url: url,
        type: 'POST',
        data: values,
        success: function(response)
        {
            if (response.redirectTo)
            {
                telemetryTrackAndPerformUrlChange(response.redirectTo, 'ep-login-email-verify-success');
            }
            else
            {
                $('.login-email-edit').removeClass('hide');
                $('.email').removeClass('has-error');
                $('.js-email-validation-error').html('');
                $('.password').removeClass('hide');
                $('#password').focus();
                $('.login-verify').removeClass('hide');
                $('.js-login-email-verify').addClass('hide');
                $('.js-set-password-input').keyup();

                telemetryTrackEvent('ep-login-email-verify-success');
            }
        },
        error: function(response)
        {
            $('.login-verify').addClass('hide');
            $('.email').addClass('has-error');

            telemetryTrackEvent('ep-login-email-verify-error');
            if (response.responseJSON)
            {
                $('.js-email-validation-error').html(response.responseJSON.message);
            }
            else
            {
                $('.js-email-validation-error').html(response.responseText);
            }
        },
    });
});


$(document).on('submit', '#login-form', function(e)
{
    e.preventDefault();
    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['email'] = $('#login-form .email').val();
    values['password'] = $('#login-form #password').val();
    const url = $(this).attr('action');

    $('.password').removeClass('has-error');
    $('.js-password-validation-error').html('');

    $.ajax(
    {
        url: url,
        type: 'POST',
        data: values,
        success: function(response)
        {
            $('.password').removeClass('has-error');
            $('.js-password-validation-error').html('');
            if (response.redirectTo)
            {
                telemetryTrackAndPerformUrlChange(response.redirectTo, 'ep-login-form-submit-success');
            }
        },
        error: function(response)
        {
            $('.password').addClass('has-error');
            $('.js-password-validation-error').html(response.responseJSON.message);
            if (response.redirectTo)
            {
                telemetryTrackAndPerformUrlChange(response.redirectTo, 'ep-login-form-submit-error');
            }
        },
    });

});

$(document).on('click', '.js-show-password', function(e)
{
    e.preventDefault();
    let button = $(e.currentTarget);
    let input = $(button.data('password-input'));

    if (input.attr('type') === 'password')
    {
        //show
        input.attr('type', 'text');
        button.text(trans('login.showBtnHide'));
    }
    else
    {
        //hide
        input.attr('type', 'password');
        button.text(trans('login.showBtnShow'));
    }
});

$(document).on('keyup paste', '.js-set-password-input', function(e)
{
    $('.password').removeClass('has-error');
    $('.js-password-validation-error').html('');

    let loginBtn = $('.login-verify');
    if (loginBtn.hasClass('hide'))
    {
        return;
    }

    if ($(this).val().length >= 8)
    {
        $('.js-set-password-button').removeAttr('disabled');
    }
    else
    {
        $('.js-set-password-button').attr('disabled', true);
    }
});

$(document).on('keyup paste', '.js-login-email-input', function(e)
{
    $('.js-email-validation-error').html('');
    $('.email').removeClass('has-error');
});

// register flow - input change
$(document).on('keyup change paste', '.js-terms-conditions, .js-password-input, .js-register-email-input, .js-first-name-input, .js-last-name-input', function(e)
{
    const checkbox = $('.js-terms-conditions');
    const email = $('.js-register-email-input');
    const firstName = $('.js-first-name-input');
    const lastName = $('.js-last-name-input');
    const password = $('.js-password-input');

    if (checkbox[0].checked && validateEmail(email.val()))
    {
        const verifyButton = $('.js-register-email-verify');
        verifyButton.removeAttr('disabled');
        if (e.key === 'Enter' || e.keyCode === 13)
        {
            // enter pressed, check if we can submit to verify email
            if (!verifyButton.attr('disabled') && !verifyButton.hasClass('hide'))
            {
                verifyButton.click();
            }
            else
            {
                const createAccountButton = $('.js-create-account');
                if (!createAccountButton.attr('disabled') && !createAccountButton.hasClass('hide'))
                {
                    createAccountButton.click();
                }
            }
        }
    }
    else
    {
        $('.js-register-email-verify').attr('disabled', 'disabled');
    }

    if (checkbox[0].checked && validateEmail(email.val()) && firstName.val().length > 0 && lastName.val().length > 0 && password.val().length >= 8)
    {
        $('.js-create-account').removeAttr('disabled');
    }
    else
    {
        $('.js-create-account').attr('disabled', 'disabled');
    }

    if (email.val().length > 0 && firstName.val().length > 0 && lastName.val().length > 0 && !$('.signup-name').hasClass('hide'))
    {
        $('.js-password-form-group').removeClass('hide');
    }
});

/**
 * on email input changed revert back to the screen where you can edit email
 */
$(document).on('input', '.js-register-email-input', function()
{
    //hide other parts of the form and show the correct submit button
    $('.js-password-form-group').addClass('hide');
    $('.signup-name').addClass('hide');
    $('.js-create-account').addClass('hide');
    $('.js-register-email-verify').removeClass('hide');

    //clear the password field and disable the button so we can't accidentally submit the wrong form.
    $('.js-password-input').val('');
    $('.js-create-account').attr('disabled', 'disabled');

    //restore work email text
    let instruction = $('.js-instruction');
    instruction.html(trans('register.recommendWorkEmail'));
    instruction.removeClass('help-block');
    instruction.addClass('opacity-60');
    instruction.parent().removeClass('has-error');
});

$(document).on('click', '.js-register-email-verify', function(e)
{
    e.preventDefault();

    if ($(this).hasClass('hide') || $(this).attr('disabled'))
    {
        return;
    }

    let values = {};
    values['_token'] = $('meta[name="csrf-token"]').attr('content');
    values['email'] = $('.js-register-email-input').val();
    $.ajax(
    {
        url: '/register/verify',
        type: 'POST',
        dataType: 'json',
        data: values,
        success: function(response)
        {
            $('.js-first-name-input').val('');
            $('.js-last-name-input').val('');
            $('.signup-name').removeClass('hide');
            $('.js-create-account').removeClass('hide');
            $('.js-register-email-verify').addClass('hide');
            $('.js-register-email-input').attr('readonly', true);
            $('.register-email-edit').removeClass('hide');

            let instruction = $('.js-instruction');
            instruction.addClass('hide');
            instruction.parent().removeClass('has-error');
        },
        error: function(xhr, status, error)
        {
            let instruction = $('.js-instruction');
            instruction.html(xhr.responseJSON.message);
            instruction.addClass('help-block');
            instruction.removeClass('opacity-60');
            instruction.removeClass('hide');
            instruction.parent().addClass('has-error');
        }
    });
});

$(document).on('click', '.forgot-password-link', function(e)
{
    const email = $('.js-login-email-input').val();
    if (validateEmail(email))
    {
        let link = $(e.currentTarget);
        let url = link.attr('href').split('?')[0] + '?email=' + encodeURIComponent(email);
        link.attr('href', url);
    }
});

$(document).on('click', '.js-resend-verifyEmail', function(e)
{
    e.preventDefault();
    let emailId = $('.js-email-info').text();
    $.ajax(
    {
        url: `/password/resend/${emailId}`,
        type: 'GET',
        dataType: 'json',
        success: function()
        {
            $('.js-validation-error').html('');
        },
        error: function(response)
        {
            $('.js-validation-error').html(response.responseJSON.message);
        }
    });
});

$(document).on('click', '.login-email-edit', function(e)
{
    $('.login-email-edit').addClass('hide');
    $('.password').addClass('hide');
    $('.login-verify').addClass('hide');
    $('.js-login-email-verify').removeClass('hide');
});


$(document).on('click', '.register-email-edit', function(e)
{
    $('.register-email-edit').addClass('hide');
    const email = $('.js-register-email-input');
    email.removeAttr('readonly');
    email.trigger('input');
});